import { toast } from "react-toastify";
import { Controller } from "./Controller";

class RunbooksController extends Controller {
  constructor() {
    super("runbooks", { report: {}, current: {}, list: [], scheduled: [], running: [] });
    this.registerRoute({ path: "/security-automation", strict: false, exact: true }, this.syncAllRunbooks);
    this.registerRoute({ path: "/security-automation/:uuid", strict: false, exact: true }, this._fetchRoute);
  }

  _fetchRoute(params) {
    this.syncAllRunbooks();
    if (params.uuid) {
      this.get(params.uuid);
    }
  }

  async cancelScheduledTask(data: any) {
    const action = "CancelScheduledTask";
    this.asyncAction("SYNC_RUNBOOKS", async () => {
      await this.ajax(
        "/tasks/cancelScheduledTask",
        "POST",
        data,
        { action } // action is important to be triggered in case of async MFA
      ).then(r => {
        if (r && r.cancelled) {
          // MFA not expired, immediate execution
          Controller.dispatch({ type: action });
        }
      });
    });
  }

  onCancelScheduledTask(state, action) {
    toast.success(`Cancelling scheduled task successful!`, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 10000
    });
    return state;
  }

  afterCancelScheduledTask(state, action) {
    this.refreshScheduledTasks();
    return state;
  }

  syncAllRunbooks() {
    this.refreshRunbooks();
    this.refreshScheduledTasks();
    this.refreshRunningTasks();
  }

  refreshRunbooks() {
    this.asyncAction(
      "SYNC_RUNBOOKS",
      async () => {
        await Controller.get("users").waitInit();
        return { list: await this.ajax("/dashboard/runbooks") };
      },
      () => {
        this.setInitialized();
      }
    );
  }

  refreshScheduledTasks() {
    this.asyncAction("SYNC_SCHEDULED_TASKS", async () => {
      await Controller.get("users").waitInit();
      let {
        crontab,
        scheduled: { tasks = {} }
      } = await this.ajax("/dashboard/scheduled-tasks");
      let scheduled = [];
      for (let i in tasks) {
        scheduled.push(tasks[i]);
      }
      return { scheduled, crontab };
    });
  }

  refreshRunningTasks() {
    this.asyncAction("SYNC_RUNNING_TASKS", async () => {
      await Controller.get("users").waitInit();
      let running = (await this.ajax("/dashboard/running-tasks")) || [];
      running = running.map(o => {
        return {
          ...o,
          lastUpdate: new Date(o.lastUpdate).getTime(),
          duration: new Date(o.lastUpdate).getTime() - o._launchTime
        };
      });
      return { running };
    });
  }

  get(uuid) {
    this.asyncAction("GET_RUNBOOK", async () => {
      await this.waitInit();
      let currentRunbook = {};
      let runbooks = this.getLocalState().list;
      currentRunbook = runbooks.filter(runbook => runbook.uuid === uuid);
      return { current: currentRunbook[0] };
    });
  }
}
export { RunbooksController };

import { Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { Controller } from "../controllers";
import LoadingPanel from "../common/LoadingPanel";
import PanelActions from "../common/PanelActions";
import PermissionsPanel from "./PermissionsPanel";

const styles = theme => ({
  root: {
    minHeight: 380,
    padding: 20,
    marginTop: 20
  }
});

class GroupDetails extends React.Component {
  state = {
    drafts: {
      _dirty: false,
      permissions: {
        inherited: []
      }
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (!props.current) {
      return null;
    }
    if (state.user !== props.current.uuid) {
      return {
        user: props.current.uuid,
        drafts: {
          permissions: Object.assign({}, props.current.permissions),
          _dirty: false
        }
      };
    }
    return null;
  }

  onPermissionsChange = (permission, value) => {
    this.setState(prevState => {
      let drafts = { ...prevState.drafts };
      let permissions = Object.assign({}, drafts.permissions);
      permissions[permission] = value;
      drafts.permissions = permissions;
      drafts._dirty = true;
      return { drafts };
    });
  };

  onCancel = () => {
    this.setState((prevState, props) => {
      let drafts = { ...prevState.drafts };
      drafts.permissions = Object.assign({}, props.current.permissions);
      drafts._dirty = false;
      return { drafts };
    });
  };

  onSave = () => {
    let result = {};
    // Only keep permissions that are local
    for (let i in this.state.drafts.permissions) {
      if (i === "inherited") {
        continue;
      }
      if (this.state.drafts.permissions[i] && this.state.drafts.permissions.inherited.indexOf(i) < 0) {
        result[i] = true;
      }
    }
    Controller.get("users").updateUser({
      localPermissions: result
    });
  };

  render() {
    const { current, asyncGet, classes, asyncPut } = this.props;
    const { drafts } = this.state;
    return (
      <Paper className={classes.root}>
        <LoadingPanel async={asyncGet}>
          <h3>Group {current.uuid}</h3>
          <PermissionsPanel onChange={this.onPermissionsChange} current={drafts.permissions} />
          <PanelActions onCancel={this.onCancel} onSave={this.onSave} dirty={drafts._dirty} async={asyncPut} />
        </LoadingPanel>
      </Paper>
    );
  }
}

export default withStyles(styles)(
  connect((state, ownProps) => {
    return {
      asyncPut: state.users._async.UPDATE_USER || {},
      asyncGet: state.users._async.GET_USER || {},
      current: state.users.current || {}
    };
  })(GroupDetails)
);

import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import AccountIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Controller } from "../controllers";
import WhatsNew from "./WhatsNew";
import { withRouter } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  Avatar,
  Divider,
  Fab,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  flex: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  button: {
    backgroundColor: "transparent",
    height: 52,
    "&:hover": {
      backgroundColor: "#9ea7d9",
      color: "white"
    }
  },
  avatar: {
    height: 48,
    width: 48
  }
});

const MenuAppBar = ({ classes, user, className, onClick, history }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [buttonStyle, setButtonStyle] = useState("round");

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    Controller.get("users").logout();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setButtonStyle("round");
  };

  const handleMyAccount = () => {
    handleClose();
    history.push("/me");
  };

  const hoverOn = () => {
    setButtonStyle("extended");
  };

  const hoverOff = () => {
    if (anchorEl) return;
    setButtonStyle("round");
  };

  const open = Boolean(anchorEl);

  let gravatar;
  if (user) {
    gravatar = `https://www.gravatar.com/avatar/${user.gravatar}`;
  }

  return (
    <AppBar position="absolute" className={className}>
      <Toolbar>
        <IconButton onClick={onClick} className={classes.menuButton} color="inherit" aria-label="Menu">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" className={classes.flex}>
          <a href="/" style={{ color: "white" }}>
            Nuxeo Security Operations Center
            </a>
        </Typography>
        <WhatsNew />
        {user && (
          <div>
            <Fab
              onMouseEnter={hoverOn}
              onMouseLeave={hoverOff}
              onClick={handleMenu}
              aria-owns={open ? "menu-appbar" : null}
              aria-haspopup="true"
              variant={buttonStyle}
              aria-label="{user.name}"
              className={classes.button}
            >
              {buttonStyle === "extended" && (
                <div style={{ fontWeight: "bold", paddingRight: 10 }}>{user.name}</div>
              )}
              <Avatar alt="{user.name}" src={gravatar} />
            </Fab>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleMyAccount}>
                <ListItemIcon className={classes.icon}>
                  <AccountIcon />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.primary }} inset primary="My account" />
              </MenuItem>
              <Divider />{" "}
              <MenuItem onClick={handleLogout}>
                <ListItemIcon className={classes.icon}>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.primary }} inset primary="Logout" />
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}


MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object
};

export default withRouter(withStyles(styles)(MenuAppBar));

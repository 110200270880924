import { Button, Paper, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import dateFormat from "dateformat";
import { saveAs } from "file-saver";
import * as jsonpath from "jsonpath";
import memoize from "memoize-one";
import React from "react";
import { connect } from "react-redux";
import { DateTimeInput } from "semantic-ui-calendar-react";
import { Accordion, Header, Icon, Input, Select } from "semantic-ui-react";
import "./CloudInventory.css";
import {
  CloudFrontList,
  EBSList,
  EC2InstancesList,
  EFSList,
  OthersList,
  RDSInstancesList,
  Route53List,
  S3List,
  SGsList
} from "./CloudInventoryLists";
import { Controller } from "../controllers";
import LoadingPanel from "../common/LoadingPanel";

const styles = theme => ({
  root: {
    minHeight: 380,
    padding: 20,
    marginTop: 20
  },
  flexHorizontal: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    flexWrap: "wrap",
    padding: 20
  },
  text: {
    padding: 10,
    color: "grey"
  },
  spaceBetweenContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  SearchBox: {
    paddingTop: 30,
    paddingBottom: 30
  },
  errorLabel: {
    padding: 30
  }
});

class CloudInventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inventory: {},
      filteredInventory: [],
      activeIndex: -1,
      column: null,
      direction: null,
      async: {
        syncing: true
      },
      accounts: [],
      open: false,
      selectedValue: {},
      dateTime: ""
    };
    Controller.get("cloudInventory").loadInventories();

    const {
      match: { params }
    } = props;
    if (params && params.searchFilter) {
      Controller.get("cloudInventory").setFilter("Value", params.searchFilter);
    }
  }

  manageAccountFilter = (evt, value) => {
    let selectedAccount = value.value;
    Controller.get("cloudInventory").setFilter("Account", selectedAccount);
  };

  manageRegionFilter = (evt, value) => {
    let selectedRegion = value.value;
    Controller.get("cloudInventory").setFilter("Region", selectedRegion);
  };

  manageComplianceFilter = (evt, value) => {
    let selectedPolicy = value.value;
    Controller.get("cloudInventory").setFilter("Policy", selectedPolicy);
  };

  completeCompliance(result, resource) {
    for (let i in resource.compliant) {
      result[i] = result[i] || { total: 0, exceptions: 0 };
      result[i].total++;
      if (!resource.compliant[i]) {
        result[i].exceptions++;
        resource.nonCompliant = true;
        resource.style = {
          color: "red"
        };
      }
    }
  }

  filter = memoize((inventory, account, region, policy, filterText = "") => {
    let result = {
      S3s: [],
      EC2Instances: [],
      RDSs: [],
      EBS: [],
      EFS: [],
      SGs: [],
      Others: [],
      Route53: [],
      CloudFront: [],
      Stats: {
        EC2Types: {},
        RDSTypes: {},
        ElasticCacheTypes: {},
        ElasticSearchTypes: {}
      },
      Compliance: {
        Tagging: {
          total: 0,
          exceptions: 0
        },
        Encryption: {
          total: 0,
          exceptions: 0
        },
        EncryptedTraffic: {
          total: 0,
          exceptions: 0
        }
      }
    };
    Object.keys(inventory).forEach(accountId => {
      if (!account || accountId === account) {
        let regions = Object.keys(inventory[accountId]);
        if (region) {
          regions = [region];
        }
        if (regions.indexOf("global") >= 0) {
          inventory[accountId].global.Route53.zones.forEach(zone => {
            if (this.itemMatch(zone, filterText, "Route53", policy)) {
              this.completeCompliance(result.Compliance, zone);
              result.Route53.push(zone);
            }
          });
          inventory[accountId].global.CloudFront.distributions.forEach(zone => {
            if (this.itemMatch(zone, filterText, "CloudFront", policy)) {
              this.completeCompliance(result.Compliance, zone);
              result.CloudFront.push(zone);
            }
          });
        }
        regions.forEach(selected => {
          ["S3s", "SGs", "Others", "EFS", "RDSs", "EC2Instances", "EBS"].forEach(key => {
            if (!inventory[accountId][selected][key]) {
              return;
            }
            result[key] = result[key] || [];
            inventory[accountId][selected][key].forEach(item => {
              if (this.itemMatch(item, filterText, key, policy)) {
                this.completeCompliance(result.Compliance, item);

                if (key === "EC2Instances") {
                  result.Stats.EC2Types[item.InstanceType] = (result.Stats.EC2Types[item.InstanceType] || 0) + 1;
                } else if (key === "RDSs") {
                  result.Stats.RDSTypes[item.DBInstanceClass] = (result.Stats.RDSTypes[item.DBInstanceClass] || 0) + 1;
                }
                result[key].push(item);
              }
            });
          });
        });
      }
    });
    return result;
  });

  itemMatch = (obj, filterText, key, policy) => {
    if (policy && policy !== "") {
      if (!obj.compliant || obj.compliant[policy] !== false) {
        return false;
      }
    }
    if (filterText === "") {
      return true;
    }
    let attributes;
    if (key === "Route53") {
      attributes = ["$.Name", "$.Id", "$.Entries[*].Name", "$.Entries[*].ResourceRecords[*].Value"];
    } else if (key === "CloudFront") {
      attributes = ["$.DomainName", "$.Id", "$.Aliases.Items[*]"];
    } else if (key === "S3s" || key === "Others") {
      attributes = ["$.name", "$.arn"];
    } else if (key === "EC2Instances") {
      attributes = [
        "$.ImageId",
        "$.InstanceId",
        "$.InstanceType",
        "$.KeyName",
        "$.PrivateDnsName",
        "$.PrivateIpAddress",
        "$.PublicDnsName",
        "$.PublicIpAddress",
        "$.VpcId",
        "$.SubnetId",
        "$.Volumes",
        "$.Name",
        "$.IamInstanceProfile.Arn",
        "$.NetworkInterfaces[*].Groups[*].GroupName",
        "$.NetworkInterfaces[*].Groups[*].GroupId",
        "$.NetworkInterfaces[*].MacAddress"
      ];
    } else if (key === "RDSs") {
      attributes = [
        "$.DBInstanceClass",
        "$.DBInstanceIdentifier",
        "$.Engine",
        "$.SGs",
        "$.Subnets",
        "$.DBInstanceArn",
        "$.DBSubnetGroup.VpcId",
        "$.DBSubnetGroup.DBSubnetGroupName",
        "$.Endpoint.Address"
      ];
    } else if (key === "SGs") {
      attributes = [
        "$.GroupName",
        "$.IpPermissions[*].UserIdGroupPairs[*].GroupId",
        "$.IpPermissions[*].IpRanges[*].CidrIp",
        "$.IpPermissionsEgress[*].IpRanges[*].CidrIp",
        "$.IpPermissionsEgress[*].UserIdGroupPairs[*].GroupId",
        "$.VpcId"
      ];
    }
    if (attributes) {
      if (!obj.__jsonpath) {
        obj.__jsonpath = attributes.map(path => jsonpath.query(obj, path));
      }
      return (
        obj.__jsonpath.filter(item => {
          return item.filter(res => res.indexOf(filterText) >= 0).length > 0;
        }).length > 0
      );
    }
    for (let k in obj) {
      if (typeof obj[k] === "string") {
        if (obj[k].indexOf(filterText) >= 0) {
          return true;
        }
      }
    }
    return false;
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  handleDetails = resource => {
    this.props.history.push(`/cloud-inventory/${resource._uuid}`);
  };

  handleClose = value => {
    this.setState({ selectedValue: value, open: false });
  };

  handleDownload = inventory => {
    var blob = new Blob([JSON.stringify(inventory, undefined, 2)], { type: "application/json;charset=utf-8" });
    saveAs(blob, "nuxeo-cloud-inventory.json");
  };

  handleSearchChange = (e, { value }) => {
    Controller.get("cloudInventory").setFilter("Value", value);
  };

  onDateTimeInputChange = (event, { name, value }) => {
    if (value === "") {
      return;
    }
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
    if (!value.trim().endsWith("UTC")) {
      value += "UTC";
    }
    let newDate = new Date(value);
    Controller.get("cloudInventory").loadInventories(
      true,
      dateFormat(new Date(newDate.getTime() - (newDate.getTime() % (4 * 60 * 60 * 1000))), "UTC:yyyymmdd-HH0000")
    );
  };

  render() {
    const { activeIndex } = this.state;
    const {
      classes,
      async,
      inventory,
      awsAccounts,
      inventoryDate,
      selectedAccount,
      selectedRegion,
      selectedPolicy,
      selectedValue,
      inventoryLoadingError
    } = this.props;

    var regionsList = [
      { text: "eu-west-1", value: "eu-west-1" },
      { text: "eu-west-2", value: "eu-west-2" },
      { text: "eu-west-3", value: "eu-west-3" },
      { text: "ca-central-1", value: "ca-central-1" },
      { text: "us-east-1", value: "us-east-1" },
      { text: "us-east-2", value: "us-east-2" },
      { text: "us-west-1", value: "us-west-1" },
      { text: "us-west-2", value: "us-west-2" },
      { text: "ap-northeast-1", value: "ap-northeast-1" },
      { text: "ap-northeast-2", value: "ap-northeast-2" },
      { text: "ap-southeast-1", value: "ap-southeast-1" },
      { text: "ap-southeast-2", value: "ap-southeast-2" },
      { text: "eu-central-1", value: "eu-central-1" }
    ];
    var complianceList = [
      { text: "Resources not encrypted at REST", value: "Encryption" },
      {
        text: "Resources allowing unsecure traffic",
        value: "EncryptedTraffic"
      },
      { text: "Resources not tagged correctly", value: "Tagging" }
    ];
    let accounts = Object.keys(awsAccounts).map(item => {
      return {
        text: awsAccounts[item].Alias || "Unknown",
        value: awsAccounts[item].Id
      };
    });
    let filteredInventory = this.filter(inventory, selectedAccount, selectedRegion, selectedPolicy, selectedValue);

    let Tagging = `${filteredInventory.Compliance.Tagging.exceptions}/${filteredInventory.Compliance.Tagging.total}`;
    let Encryption = `${filteredInventory.Compliance.Encryption.exceptions}/${filteredInventory.Compliance.Encryption.total}`;
    let EncryptedTraffic = "";
    if (filteredInventory.Compliance.EncryptedTraffic) {
      EncryptedTraffic = `${filteredInventory.Compliance.EncryptedTraffic.exceptions}/${filteredInventory.Compliance.EncryptedTraffic.total}`;
    }
    return (
      <Paper className={classes.root}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Cloud Inventory</Typography>
          <Button
            onClick={() => this.handleDownload(filteredInventory)}
            style={{ visibility: async.syncing ? "hidden" : "visible" }}
          >
            Download JSON
          </Button>
        </div>
        {inventoryLoadingError ? (
          <div className={classes.errorLabel}>
            <Header as="h4">Inventory loading error, it may be incomplete</Header>
          </div>
        ) : null}
        <LoadingPanel async={async}>
          <div className={classes.spaceBetweenContainer}>
            <form className={classes.flexHorizontal}>
              <p className={classes.text}>Inventory date</p>
              <DateTimeInput
                name="dateTime"
                placeholder="Date Time"
                value={this.state.dateTime || inventoryDate}
                onChange={this.onDateTimeInputChange}
                startMode="day"
                maxDate={new Date()}
                minDate={new Date(2019, 1, 26)}
                initialDate={new Date()}
                disableMinute
                dateTimeFormat="YYYY-MM-DD HH:00 z"
              />
            </form>
            <Input
              className={classes.SearchBox}
              icon
              onChange={this.handleSearchChange}
              value={selectedValue}
              placeholder="Search for..."
            >
              <input />
              <Icon name="search" />
            </Input>
          </div>
          <div className={classes.spaceBetweenContainer}>
            <div className={classes.flexHorizontal}>
              <p className={classes.text}>Account Filter</p>
              <Select
                clearable
                options={accounts}
                selection
                onChange={this.manageAccountFilter}
                placeholder="Select an account..."
                defaultValue={selectedAccount}
              />
              <p className={classes.text}>Region Filter</p>
              <Select
                clearable
                options={regionsList}
                selection
                onChange={this.manageRegionFilter}
                placeholder="Select a region..."
                defaultValue={selectedRegion}
              />
              <p className={classes.text}>Compliance Filter</p>
              <Select
                clearable
                options={complianceList}
                selection
                onChange={this.manageComplianceFilter}
                placeholder="Select a policy..."
                defaultValue={selectedPolicy}
              />
            </div>
          </div>
          <Header as="h4">Resources non compliant with policies</Header>
          <TextField label="Tagging" value={Tagging} variant="outlined" style={{ margin: 15 }} />
          <TextField label="Encryption" value={Encryption} variant="outlined" style={{ margin: 15 }} />
          <TextField label="Unsecure Traffic" value={EncryptedTraffic} variant="outlined" style={{ margin: 15 }} />
          <Accordion fluid styled>
            <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
              <Icon name="dropdown" />
              EC2s ({filteredInventory.EC2Instances.length})
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              {EC2InstancesList(filteredInventory.EC2Instances, this.handleDetails)}
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
              <Icon name="dropdown" />
              S3s ({filteredInventory.S3s.length})
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              {S3List(filteredInventory.S3s, this.handleDetails)}
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleClick}>
              <Icon name="dropdown" />
              RDS ({filteredInventory.RDSs.length})
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              {RDSInstancesList(filteredInventory.RDSs, this.handleDetails)}
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 3} index={3} onClick={this.handleClick}>
              <Icon name="dropdown" />
              EBS ({filteredInventory.EBS.length})
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
              {EBSList(filteredInventory.EBS, this.handleDetails)}
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 4} index={4} onClick={this.handleClick}>
              <Icon name="dropdown" />
              EFS ({filteredInventory.EFS.length})
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 4}>
              {EFSList(filteredInventory.EFS, this.handleDetails)}
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 5} index={5} onClick={this.handleClick}>
              <Icon name="dropdown" />
              SGs ({filteredInventory.SGs.length})
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 5}>
              {SGsList(filteredInventory.SGs, this.handleDetails)}
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 6} index={6} onClick={this.handleClick}>
              <Icon name="dropdown" />
              Others ({filteredInventory.Others.length})
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 6}>
              {OthersList(filteredInventory.Others, this.handleDetails)}
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 7} index={7} onClick={this.handleClick}>
              <Icon name="dropdown" />
              Route53 ({filteredInventory.Route53.length})
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 7}>
              {Route53List(filteredInventory.Route53, this.handleDetails)}
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 8} index={8} onClick={this.handleClick}>
              <Icon name="dropdown" />
              CloudFront ({filteredInventory.CloudFront.length})
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 8}>
              {CloudFrontList(filteredInventory.CloudFront, this.handleDetails)}
            </Accordion.Content>
          </Accordion>
        </LoadingPanel>
      </Paper>
    );
  }
}

export default withStyles(styles)(
  connect((state, ownProps) => {
    return {
      awsAccounts: state.dashboard.organization || {},
      async: state.cloudInventory._async.SYNC_INVENTORY || {},
      inventory: state.cloudInventory.inventory || {},
      inventoryDate: state.cloudInventory.inventoryDate || "",
      inventoryLoadingError: state.cloudInventory.error || "",
      selectedAccount: state.cloudInventory.selectedAccount || "",
      selectedRegion: state.cloudInventory.selectedRegion || "",
      selectedPolicy: state.cloudInventory.selectedPolicy || "",
      selectedValue: state.cloudInventory.selectedValue || ""
    };
  })(CloudInventory)
);

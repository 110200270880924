import { withStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactJson from "react-json-view";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Header } from "semantic-ui-react";
import Breadcrumb from "../common/Breadcrumb";
import { Controller } from "../controllers";
import LoadingPanel from "../common/LoadingPanel";
import ObjectList from "../common/ObjectList";
import TagsTable from "../TagsTable";

import { Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";

const styles = Theme => ({
  root: {
    minHeight: 380,
    padding: 20,
    marginTop: 20
  },
  textField: {
    padding: 10,
    margin: 10
  },
  container: {
    padding: 20
  }
});

class CloudResourcePanel extends React.Component {
  render() {
    const { children, resource } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "baseline"
        }}
      >
        <TextField label="Region" value={resource.awsRegion} variant="outlined" style={{ margin: 15 }} />
        <TextField label="Account" value={resource.account} variant="outlined" style={{ margin: 15 }} />
        {children}
      </div>
    );
  }
}
class CloudResourceDetails extends React.Component {
  constructor(props) {
    super(props);
    Controller.get("cloudInventory").loadInventories();
    this.state = {
      rawJSON: false
    };
  }

  handleRouteClick = (type, resource) => {
    this.props.history.push(`/cloud-inventory/` + Controller.get("cloudInventory").generateResourceId(type, resource));
  };

  sgsTable = resource => {
    return (
      <div style={{ padding: 15 }}>
        <Header as="h4">Security Groups</Header>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Group Name</TableCell>
              <TableCell>Groupd Id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resource.SecurityGroups.map((group, i) => {
              return (
                <TableRow key={i} onDoubleClick={event => this.handleRouteClick("SGs", group)}>
                  <TableCell component="th" scope="row">
                    {group.GroupName}
                  </TableCell>
                  <TableCell onClick={event => this.handleRouteClick("SGs", group)}>{group.GroupId}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  };

  blocksDeviceTable = resource => {
    return (
      <div style={{ padding: 15 }}>
        <Header as="h4">Block Devices</Header>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Ebs: VolumeId</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resource.BlockDeviceMappings.map((block, i) => {
              return (
                <TableRow onDoubleClick={event => this.handleRouteClick("EBS", block.Ebs)} key={i}>
                  <TableCell component="th" scope="row">
                    {block.DeviceName}
                  </TableCell>
                  <TableCell onClick={event => this.handleRouteClick("EBS", block.Ebs)}>{block.Ebs.VolumeId}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  };

  tagsTable = resource => {
    return <TagsTable resource={resource} />;
  };
  mapDetailsToType = resource => {
    if (!resource) {
      return;
    }
    switch (resource._type) {
      case "EC2Instances":
        return (
          <div>
            <CloudResourcePanel resource={resource}>
              <TextField label="Resource Name" value={resource.Name} variant="outlined" style={{ margin: 15 }} />
              <TextField label="Image Id" value={resource.ImageId} variant="outlined" style={{ margin: 15 }} />
              <TextField label="Instance Id" value={resource.InstanceId} variant="outlined" style={{ margin: 15 }} />
              <TextField label="Key Name" value={resource.KeyName} variant="outlined" style={{ margin: 15 }} />
              <TextField label="Launch Time" value={resource.LaunchTime} variant="outlined" style={{ margin: 15 }} />
              <TextField label="Up Time" value={resource.upTime} variant="outlined" style={{ margin: 15 }} />
              <TextField
                label="Private Ip Adress"
                value={resource.PrivateIpAddress}
                variant="outlined"
                style={{ margin: 15 }}
              />
              <TextField label="Vpc Id" value={resource.VpcId} variant="outlined" style={{ margin: 15 }} />
              <TextField label="Subnet Id" value={resource.SubnetId} variant="outlined" style={{ margin: 15 }} />
            </CloudResourcePanel>
            {this.blocksDeviceTable(resource)}
            {this.sgsTable(resource)}
            {this.tagsTable(resource)}
          </div>
        );
      case "S3s":
        return (
          <div>
            <CloudResourcePanel resource={resource}>
              <TextField label="Bucket Name" value={resource.name} variant="outlined" style={{ margin: 15 }} />
              <TextField label="Encryption" value={resource.encryption} variant="outlined" style={{ margin: 15 }} />
              <CopyToClipboard
                text={resource.arn}
                onCopy={() => {
                  toast.info("Arn value has been copied into your copy buffer !", {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                }}
              >
                <TextField label="Arn" value={resource.arn} variant="outlined" style={{ margin: 15 }} />
              </CopyToClipboard>
            </CloudResourcePanel>
          </div>
        );
      case "EFS":
        return (
          <div>
            <CloudResourcePanel resource={resource}>
              <TextField label="Resource Name" value={resource.name} variant="outlined" style={{ margin: 15 }} />
              <TextField label="Encrypted" value={resource.Encrypted} variant="outlined" style={{ margin: 15 }} />
              <CopyToClipboard
                text={resource.arn}
                onCopy={() => {
                  toast.info("Arn value has been copied into your copy buffer !", {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                }}
              >
                <TextField label="Arn" value={resource.arn} variant="outlined" style={{ margin: 15 }} />
              </CopyToClipboard>
            </CloudResourcePanel>
            {this.tagsTable(resource)}
          </div>
        );
      case "EBS":
        return (
          <div>
            <CloudResourcePanel resource={resource}>
              <TextField label="Instance Name" value={resource.name} variant="outlined" style={{ margin: 15 }} />
              <TextField label="Encrypted" value={resource.Encrypted} variant="outlined" style={{ margin: 15 }} />
              <TextField label="Size" value={resource.Size} variant="outlined" style={{ margin: 15 }} />
              <TextField label="State" value={resource.State} variant="outlined" style={{ margin: 15 }} />
              <TextField label="Volume Id" value={resource.VolumeId} variant="outlined" style={{ margin: 15 }} />
              {resource.Iops ? (
                <TextField label="Iops" value={resource.Iops} variant="outlined" style={{ margin: 15 }} />
              ) : null}
              <TextField
                label="Availability Zone"
                value={resource.AvailabilityZone}
                variant="outlined"
                style={{ margin: 15 }}
              />
              <TextField label="Encryption" value={resource.encryption} variant="outlined" style={{ margin: 15 }} />
              <TextField label="Volume Type" value={resource.VolumeType} variant="outlined" style={{ margin: 15 }} />
              <CopyToClipboard
                text={resource.arn}
                onCopy={() => {
                  toast.info("Arn value has been copied into your copy buffer !", {
                    position: toast.POSITION.BOTTOM_CENTER
                  });
                }}
              >
                <TextField label="Arn" value={resource.arn} variant="outlined" style={{ margin: 15 }} />
              </CopyToClipboard>
              {this.tagsTable(resource)}
            </CloudResourcePanel>
          </div>
        );
      case "SGs":
        return (
          <div>
            <CloudResourcePanel resource={resource}>
              <TextField
                label="Security Group Name"
                value={resource.GroupName}
                variant="outlined"
                style={{ margin: 15 }}
              />
              <div style={{ padding: 15 }}>
                <Header as="h4">Rules</Header>
                <Table style={{ padding: 10 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>FromPort</TableCell>
                      <TableCell>ToPort</TableCell>
                      <TableCell>IpProtocol</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resource.Rules.EGRESS.map((egressRule, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row">
                            {egressRule.Type}
                          </TableCell>
                          <TableCell>{egressRule.Description}</TableCell>
                          <TableCell>{egressRule.FromPort}</TableCell>
                          <TableCell>{egressRule.ToPort}</TableCell>
                          <TableCell>{egressRule.IpProtocol}</TableCell>
                          <TableCell>{egressRule.Status}</TableCell>
                        </TableRow>
                      );
                    })}
                    {resource.Rules.INGRESS.map((ingressRule, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row">
                            {ingressRule.Type}
                          </TableCell>
                          <TableCell>{ingressRule.Description}</TableCell>
                          <TableCell>{ingressRule.FromPort}</TableCell>
                          <TableCell>{ingressRule.ToPort}</TableCell>
                          <TableCell>{ingressRule.IpProtocol}</TableCell>
                          <TableCell>{ingressRule.Status}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              {this.tagsTable(resource)}
            </CloudResourcePanel>
          </div>
        );
      case "Route53":
        return (
          <div>
            <CloudResourcePanel resource={resource}>
              <TextField label="Domain" value={resource.Name} variant="outlined" style={{ margin: 15 }} />
            </CloudResourcePanel>
            <ObjectList
              title="Route53"
              noPaper
              headers={[
                {
                  id: "Name",
                  numeric: false,
                  disablePadding: false,
                  label: "Name"
                },
                {
                  id: "Type",
                  numeric: false,
                  disablePadding: false,
                  label: "Type"
                },
                {
                  id: "TTL",
                  numeric: false,
                  disablePadding: false,
                  label: "TTL"
                },
                {
                  id: "Entries",
                  numeric: false,
                  disablePadding: false,
                  getCellWidget: n => {
                    let resourceRecordsValueList = [];
                    n.ResourceRecords.forEach(ResourceRecord => {
                      resourceRecordsValueList.push(Object.values(ResourceRecord));
                    });
                    return resourceRecordsValueList.map((entry, i) => {
                      return <div key={i}>{entry}</div>;
                    });
                  },
                  label: "Resource Records"
                }
              ]}
              data={resource.Entries}
            />
          </div>
        );
      case "RDSs":
        return (
          <div>
            <CloudResourcePanel resource={resource}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "baseline"
                }}
              >
                <TextField label="Engine" value={resource.Engine} variant="outlined" style={{ margin: 15 }} />
                <TextField
                  label="DBInstanceStatus"
                  value={resource.DBInstanceStatus}
                  variant="outlined"
                  style={{ margin: 15 }}
                />
                <TextField
                  label="InstanceCreateTime"
                  value={resource.InstanceCreateTime}
                  variant="outlined"
                  style={{ margin: 15 }}
                />
                <TextField
                  label="VpcId"
                  value={resource.DBSubnetGroup.VpcId}
                  variant="outlined"
                  style={{ margin: 15 }}
                />
                <TextField
                  label="EngineVersion"
                  value={resource.EngineVersion}
                  variant="outlined"
                  style={{ margin: 15 }}
                />
                <TextField label="MultiAZ" value={resource.MultiAZ} variant="outlined" style={{ margin: 15 }} />
                <TextField
                  label="StorageEncrypted"
                  value={resource.StorageEncrypted}
                  variant="outlined"
                  style={{ margin: 15 }}
                />
                {resource.StorageEncrypted ? (
                  <TextField label="KmsKeyId" value={resource.KmsKeyId} variant="outlined" style={{ margin: 15 }} />
                ) : null}
                <CopyToClipboard
                  text={`${resource.Endpoint.Address}:${resource.Endpoint.Port}`}
                  onCopy={() => {
                    toast.info("Endpoint address has been copied into your copy buffer !", {
                      position: toast.POSITION.BOTTOM_CENTER
                    });
                  }}
                >
                  <TextField
                    label="Endpoint Address"
                    value={`${resource.Endpoint.Address}:${resource.Endpoint.Port}`}
                    variant="outlined"
                    style={{ margin: 15 }}
                  />
                </CopyToClipboard>
              </div>
              {
                <div style={{ padding: 15 }}>
                  <Header as="h4">Vpc Security Groups</Header>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>VpcSecurityGroupId</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resource.VpcSecurityGroups.map((group, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell component="th" scope="row">
                              {group.VpcSecurityGroupId}
                            </TableCell>
                            <TableCell>{group.Status}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              }
            </CloudResourcePanel>
          </div>
        );
      case "CloudFront":
        return (
          <div>
            <CloudResourcePanel resource={resource}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "baseline"
                }}
              >
                <TextField label="DomainName" value={resource.DomainName} variant="outlined" style={{ margin: 15 }} />
                <TextField label="PriceClass" value={resource.PriceClass} variant="outlined" style={{ margin: 15 }} />
                <CopyToClipboard
                  text={resource.ARN}
                  onCopy={() => {
                    toast.info("Arn value has been copied into your copy buffer !", {
                      position: toast.POSITION.BOTTOM_CENTER
                    });
                  }}
                >
                  <TextField label="Arn" value={resource.ARN} variant="outlined" style={{ margin: 15 }} />
                </CopyToClipboard>
                <TextField
                  label="Minimum Protocol Version"
                  value={resource.ViewerCertificate.MinimumProtocolVersion}
                  variant="outlined"
                  style={{ margin: 15 }}
                />
              </div>
              {resource.Origins.Quantity > 0 ? (
                <div style={{ padding: 15 }}>
                  <Header as="h4">Origins</Header>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>OriginPath</TableCell>
                        <TableCell>DomainName</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resource.Origins.Items.map((item, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell component="th" scope="row">
                              {item.Id}
                            </TableCell>
                            <TableCell>{item.OriginPath}</TableCell>
                            <TableCell>{item.DomainName}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              ) : null}
              {resource.Aliases.Quantity > 0 ? (
                <div style={{ padding: 15 }}>
                  <Header as="h4">Aliases</Header>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Aliase</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resource.Aliases.Items.map((Aliase, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell>{Aliase}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              ) : null}
              {resource.Restrictions.GeoRestriction.Quantity > 0 ? (
                <div style={{ padding: 15 }}>
                  <Header as="h4">Restrictions</Header>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>GeoRestriction</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resource.Restrictions.GeoRestriction.Items.map((restriction, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell>{restriction}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              ) : null}
            </CloudResourcePanel>
          </div>
        );

      default:
        return null;
    }
  };

  onRawJSON = () => {
    this.setState(prevState => {
      return { rawJSON: !prevState.rawJSON };
    });
  };
  render() {
    const { classes, async, match } = this.props;
    let { rawJSON } = this.state;
    let resource = Controller.get("cloudInventory").getResource(match.params.id || "");
    let title = "Unknown resource";
    if (resource) {
      title = resource._type + " " + (resource.Name || resource._uuid.substr(resource._type.length + 1));
    }
    let component;
    if (!rawJSON) {
      component = this.mapDetailsToType(resource);
    }
    if ((!component || rawJSON) && resource) {
      component = <ReactJson src={resource} />;
      rawJSON = true;
    }
    return (
      <div>
        <Paper className={classes.root}>
          <Header as="h4">Resource Details</Header>
          <LoadingPanel async={async}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Breadcrumb items={[{ title: "Cloud Inventory", path: "/cloud-inventory" }, { title }]} />
              <ToggleButton value={false} variant="outlined" selected={rawJSON} onClick={this.onRawJSON}>
                Raw JSON
              </ToggleButton>
            </div>
            <div className={classes.container}>{component}</div>
          </LoadingPanel>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(
  connect((state, ownProps) => {
    return {
      async: state.cloudInventory._async.SYNC_INVENTORY || {}
    };
  })(CloudResourceDetails)
);

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CloudInventory from "./CloudInventory";
import CloudResourceDetails from "./CloudResourceDetails";

import { Switch, Route } from "react-router-dom";

const styles = theme => ({
  root: {
    height: 380
  }
});

const CloudInventoryComponent = props => {
  return (
    <div>
      <Route exact path="/cloud-inventory" component={CloudInventory} />
      <Route exact path="/cloud-inventory/_search/:searchFilter" component={CloudInventory} />
      <Switch>
        <Route path="/cloud-inventory/:id(.+)" render={props => <CloudResourceDetails {...props} />} />
      </Switch>
    </div>
  );
}

export default withStyles(styles)(CloudInventoryComponent);

import { TimeSeries } from "pondjs";
import React from "react";
import { AreaChart, ChartContainer, ChartRow, Charts, Legend, Resizable, styler, YAxis } from "react-timeseries-charts";
import _ from "underscore";

import { Checkbox, FormControlLabel } from "@material-ui/core";

class CloudProjectCosts extends React.Component {
  constructor(props) {
    super(props);
    let unstackedRawData = props.data;
    let rawData = [];
    for (let service in unstackedRawData) {
      let formatServiceData = {
        key: service,
        values: unstackedRawData[service]
      };
      rawData = [...rawData, formatServiceData];
    }
    const numPoints = rawData[0] && rawData[0].values ? rawData[0].values.length : 0;
    const columnNames = rawData.map(d => {
      return d.key;
    });
    const name = "series";
    const points = [];
    let aggregation = 86400000;
    let aggregate = 0;
    for (let i = 0; i < numPoints; i++) {
      const t = rawData[0].values[i][0];
      if (Math.floor(t / aggregation) === aggregate) {
        const point = points[points.length - 1];
        let j = 1;
        _.each(rawData, d => {
          point[j] = point[j] + d.values[i][1];
          j++;
        });
      } else {
        const point = [t];
        _.each(rawData, d => {
          point.push(d.values[i][1]);
        });
        points.push(point);
        aggregate = Math.floor(t / aggregation);
      }
    }
    const columns = ["time", ...columnNames];
    const series = new TimeSeries({ name, columns, points });
    const timeRange = series.range();

    this.state = {
      unstackedRawData,
      series: series || [],
      timeRange: timeRange || [],
      columnNames,
      columns: JSON.parse(JSON.stringify(columnNames)),
      scheme: "Paired",
      hidden: {}
    };
  }

  nbVisible() {
    let nbHidden = 0;
    for (let i in this.state.hidden) {
      if (this.state.hidden[i]) nbHidden++;
    }
    return this.state.columns.length - nbHidden;
  }

  filterColumn(column) {
    this.setState(prev => {
      let hidden = { ...prev.hidden };
      hidden[column] = !prev.hidden[column];
      let unstackedRawData = this.state.unstackedRawData;
      let rawData = [];
      for (let service in unstackedRawData) {
        let formatServiceData = {
          key: service,
          values: unstackedRawData[service]
        };
        rawData = [...rawData, formatServiceData];
      }

      const numPoints = rawData[0] && rawData[0].values ? rawData[0].values.length : 0;
      let columnNamesFiltered = this.state.columns.filter(o => !this.state.hidden[o]);
      const columns = ["time", ...columnNamesFiltered];
      const points = [];
      for (let i = 0; i < numPoints; i++) {
        const t = rawData[0].values[i][0];
        const point = [t];
        let k = 0;
        _.each(rawData, d => {
          if (!this.state.hidden[this.state.columns[k]]) {
            point.push(d.values[i][1]);
          }
          k++;
        });
        points.push(point);
      }
      const name = "series";
      const series = new TimeSeries({ name, columns, points });
      const timeRange = series.range();
      return {
        series,
        timeRange,
        columnNames: columnNamesFiltered,
        hidden
      };
    });
  }
  handleTimeRangeChange = timeRange => {
    this.setState({ timeRange: timeRange });
  };

  renderChart = (series, timeRange, columnNames, style) => {
    if (!timeRange || (!timeRange.length && !Object.keys(timeRange).length)) {
      return;
    }
    const axisType = "linear";
    const interpolationType = "curveLinear";
    const cols = { up: columnNames, down: [] };
    let croppedSeries = series.crop(timeRange);
    let maxArray = [];
    for (let i in columnNames) {
      maxArray.push(croppedSeries.max(columnNames[i]));
    }
    let min = 0;
    let max = maxArray.reduce((accumulator, currentValue) => accumulator + currentValue);
    return (
      <ChartContainer
        timeRange={timeRange}
        onBackgroundClick={() => this.setState({ selection: null })}
        enablePanZoom={true}
        onTimeRangeChanged={this.handleTimeRangeChange}
      >
        <ChartRow height="350">
          <YAxis
            id="value"
            min={min}
            max={max}
            width="60"
            type={axisType}
            showGrid={true}
            label="Cost in $"
            format="$,.2f"
          />
          <Charts>
            <AreaChart
              axis="value"
              style={style}
              series={croppedSeries}
              columns={cols}
              fillOpacity={0.4}
              interpolation={interpolationType}
              highlight={this.state.highlight}
              onHighlightChange={highlight => this.setState({ highlight })}
              selection={this.state.selection}
              onSelectionChange={selection => this.setState({ selection })}
            />
          </Charts>
        </ChartRow>
      </ChartContainer>
    );
  };

  render() {
    const { series, columnNames, timeRange, scheme } = this.state;
    const legendCategories = columnNames.map(d => ({ key: d, label: d }));
    const style = styler(columnNames, scheme);
    return (
      <div>
        <div className="row">
          <div className="col-md-9" style={{ padding: "20px" }}>
            <Legend categories={legendCategories} style={style} type="swatch" />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            {timeRange && (timeRange.length || Object.keys(timeRange).length) && (
              <Resizable>{this.renderChart(series, timeRange, columnNames, style)}</Resizable>
            )}
            <div>
              {this.state.columns.map(o => (
                <FormControlLabel
                  key={o}
                  control={
                    <Checkbox
                      checked={!this.state.hidden[o]}
                      value={o}
                      onClick={() => {
                        if (!this.state.hidden[o] && this.nbVisible() === 1) {
                          return false;
                        }
                        this.filterColumn(o);
                      }}
                    />
                  }
                  label={o}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CloudProjectCosts;

import React from "react";
import ErrorPanel from "./ErrorPanel";
import { CircularProgress, Typography } from "@material-ui/core";

const LoadingPanel = ({ async, label, children }) => {
  label = label || "Loading";
  if (async && async.syncing) {
    return (
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          minHeight: "300px",
          width: "100%",
          flexDirection: "column"
        }}
      >
        <CircularProgress size={32} />
        <Typography style={{ marginTop: "20px", color: "#999" }}>{label}</Typography>
      </div>
    );
  }
  if (async && async.error) {
    return <ErrorPanel error={async.error} />;
  }
  return <div>{children}</div>;
}

export default LoadingPanel;

import React from "react";
import { Button, ExpansionPanelActions, CircularProgress } from "@material-ui/core";

const PanelActions = ({ async, onCancel, onSave, dirty, labels, create, disabled, error }) => {
  if (create) {
    dirty = true;
    labels = labels || ["Creating", "Create"];
  } else {
    labels = labels || ["Saving", "Save"];
  }
  async = async || { syncing: false };
  error = error || "";
  if (async.error) {
    if (async.error.code === 500) {
      error = "Internal Server Error";
    } else if (async.error.code === 403) {
      error = "You don't have permissions";
    } else if (async.error.code === 404) {
      error = "Object not found";
    } else if (async.error.code === 401) {
      error = "Requires valid session";
    } else if (async.error.code === 409) {
      error = "Object already exists";
    }
  }
  const label = async.syncing ? labels[0] : labels[1];
  return (
    <div hidden={!dirty}>
      <ExpansionPanelActions>
        <div hidden={error === ""} style={{ color: "red" }}>
          {error}
        </div>
        <Button size="small" onClick={onCancel} disabled={async.syncing}>
          Cancel
          </Button>
        <Button
          id="actionpanel-validation"
          size="small"
          color="primary"
          onClick={onSave}
          disabled={async.syncing || disabled}
        >
          <div hidden={!async.syncing}>
            <CircularProgress size={20} style={{ marginRight: "10px" }} />
          </div>
          {label}
        </Button>
      </ExpansionPanelActions>
    </div>
  );
}

export default PanelActions;

import React from "react";
import { Switch, Route } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ComplianceReportsList from "./ComplianceReportsList";
import ComplianceReportsDetails from "./ComplianceReportsDetails";
import { useSelector } from "react-redux";
import LoadingPanel from "../common/LoadingPanel";


const styles = theme => ({
    root: {
        height: 380,
        padding: 20,
        marginTop: 20
    }
});

const ComplianceReports = ({ classes }) => {
    const complianceReports = useSelector(state => state.compliance.complianceReports);
    const async = useSelector(state => state.compliance._async.SYNC_COMPLIANCE);

    return (
        <div className={classes.root} id="complianceReports">
            <LoadingPanel async={async}>
                <Switch>
                    <Route exact path="/compliance-reports" render={() => <ComplianceReportsList complianceReportsData={complianceReports} />} />
                    <Route exact path="/compliance-reports/:id" render={(props) => <ComplianceReportsDetails complianceReportsData={complianceReports} {...props} />} />
                </Switch>
            </LoadingPanel>
        </div>
    )
}

export default withStyles(styles)(
    (ComplianceReports)
);
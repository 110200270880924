import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { Controller } from "../controllers";
import { useSelector } from "react-redux";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from "@material-ui/core";

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    width: "80%"
  },
  progress: {
    margin: 20
  }
});

function ConfirmationDialogRaw(props) {
  const { type: valueType, totp: valueTOTP, user, onClose, factors, body, requiredMFA, finalAction, ...other } = props;
  const [type, setType] = React.useState(valueType);
  const [totp, setTOTP] = React.useState(valueTOTP);
  const [mfaPushWaiting, setPushWaiting] = React.useState(false);
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!requiredMFA) {
      setType("");
      setPushWaiting(false);
    }
  }, [valueType, requiredMFA]);

  function handleEntering() {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  }

  function handleCancel() {
    onClose();
    setPushWaiting(false);
    setType("");
  }

  function handlePush() {
    Controller.get("mfa").push(body, type, finalAction);
    setPushWaiting(true);
  }

  function handleTOTP() {
    Controller.get("mfa").totp(body, type, finalAction, totp);
  }

  function handleChangeType(event, newType) {
    setType(newType);
  }

  function handleChangeTOTP(event) {
    setTOTP(event.target.value);
  }

  // Returns the "nature" of a factor
  function getNature(factorId) {
    if (factors && factors[factorId] && factors[factorId].factorType) {
      return factors[factorId].factorType;
    } else return "";
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      onEntering={handleEntering}
      aria-labelledby="mfa-title"
      open={requiredMFA}
      {...other}
    >
      <DialogTitle id="mfa-title">Please confirm with your Okta MFA</DialogTitle>
      <DialogContent>
        <RadioGroup ref={radioGroupRef} aria-label="mfa" name="mfa" value={type} onChange={handleChangeType}>
          {(factors || []).map((f, k) => {
            return (
              <FormControlLabel
                value={"" + k}
                key={"MFA" + k}
                control={<Radio />}
                disabled={mfaPushWaiting}
                label={
                  <>
                    <img
                      src={"/logos/" + f.provider.toLowerCase() + ".png"}
                      width="40px"
                      height="auto"
                      title={f.provider}
                      alt={f.provider}
                      style={{ verticalAlign: "middle", marginRight: "5px" }}
                    />
                    {f.factorType + (f.profile && f.profile.name ? " on " + f.profile.name : "")}
                  </>
                }
              />
            );
          })}
        </RadioGroup>
        {getNature(type) === "TOTP" && (
          <div>
            <TextField
              autoFocus
              margin="dense"
              id="totp"
              label="TOTP"
              type="number"
              required={true}
              onChange={handleChangeTOTP}
              fullWidth
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {!mfaPushWaiting && (
          <div>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            {getNature(type) === "PUSH" && (
              <Button onClick={handlePush} color="primary">
                Send now
              </Button>
            )}
            {getNature(type) === "TOTP" && (
              <Button onClick={handleTOTP} color="primary">
                I confirm
              </Button>
            )}
          </div>
        )}
        {mfaPushWaiting && (
          <div>
            <div align="center">
              <DialogContentText>
                Please valid your push on your MFA device. <br />
                The system is waiting for your Push confirmation. <br />
                This action will NOT be initiated until MFA confirmation.
              </DialogContentText>
              <CircularProgress bottom={0} left={0} className={styles.progress} />
            </div>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}

const MFA = ({ user }) => {

  const finalAction = useSelector(state => state.mfa.action);
  const factors = useSelector(state => state.mfa.factors);
  const body = useSelector(state => state.mfa.body);
  const requiredMFA = useSelector(state => state.mfa.requiredMFA);

  const onClose = () => {
    Controller.get("mfa").hide();
  }

  return (
    <ConfirmationDialogRaw
      classes={{
        paper: styles.paper
      }}
      id="ringtone-menu"
      keepMounted
      onClose={onClose}
      requiredMFA={requiredMFA}
      finalAction={finalAction}
      factors={factors}
      body={body}
      user={user}
    />
  );
}

export default withStyles(styles)(MFA);

import React from "react";
import * as moment from "moment";
import { useSelector } from "react-redux";
import { Controller } from "./controllers";
import LoadingPanel from "./common/LoadingPanel";
import ObjectList from "./common/ObjectList";
import { IconButton } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

const CostsTable = () => {
  const handleDownload = daterange => {
    Controller.get("dashboard").downloadCostsReport(daterange);
  };
  let headers = [
    {
      id: "File",
      label: "File",
      getCellWidget: n => {
        return <div>{moment(n.File.substr(0, 8)).format("MMMM YYYY")}</div>;
      }
    },
    {
      id: "Size",
      label: "Current size",
      getCellWidget: n => {
        if (!n.Size) return "";
        var i = Math.floor(Math.log(n.Size) / Math.log(1024)),
          sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        return (n.Size / Math.pow(1024, i)).toFixed(2) * 1 + " " + sizes[i];
      }
    },
    {
      id: "LastModified",
      label: "Last check",
      getCellWidget: n => {
        return <div>{new Date(n.LastModified).toISOString()}</div>;
      }
    },
    {
      id: "dl",
      label: "Download",
      getCellWidget: n => {
        return (
          <IconButton onClick={() => handleDownload(n.File)}>
            <GetAppIcon />
          </IconButton>
        );
      }
    }
  ];
  return (
    <ObjectList
      title="Costs reports"
      headers={headers}
      storeData="$.dashboard.costsReports"
      defaultOrderBy="LastModified"
      defaultOrder="asc"
    />
  );
}

const Costs = () => {
  const async = useSelector(state => state.dashboard._async.SYNC_COSTS || {});
  const costsReports = useSelector(state => state.dashboard.costsReports || []);
  return (
    <LoadingPanel async={async}>
      {(costsReports && <CostsTable />) || <div>No cost exports found.</div>}
    </LoadingPanel>
  );
}

export default Costs;

import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";

const AddNewButton = ({ classes, history, location, label, disabled }) => {
    return (
        <div className={classes.addButton}>
            <Button
                onClick={() => {
                    history.push(`${location.pathname}/new`);
                }}
                variant="outlined"
                color="primary"
                disabled={location.pathname === `${location.pathname}/new` || disabled}
                id={`new-${location.pathname.replace('/', '')}-button`}
            >
                <AddIcon />
                {label}
            </Button>
        </div>
    )
};

export default AddNewButton;
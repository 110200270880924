import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Controller } from "./controllers";
import LoadingPanel from "./common/LoadingPanel";
import { Paper, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DateIcon from "@material-ui/icons/DateRange";
import DeployIcon from "@material-ui/icons/Launch";
import { Form, Input, TextArea } from "semantic-ui-react";

const styles = {
  root: {
    height: 400,
    padding: 20,
    marginTop: 20
  },
  addButton: {
    marginTop: "20px",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  },
  formContainer: {
    padding: "30px"
  }
};

const DeployBeta = ({ classes }) => {
  const [releaseMD, setReleaseMD] = useState("");
  const async = useSelector(state => state.dashboard._async.DEPLOY_RELEASE || {});
  let warning;
  let deployable = false;
  if (!isDeployable()) {
    warning = <div style={{ color: "red" }}>Required to be on https://beta.dashboard.offices.nuxeo.com</div>;
  } else {
    deployable = true;
  }

  const handleChange = (e, { value }) =>
    setReleaseMD(value);

  const deployNewRelease = () => {
    if (releaseMD || releaseMD === "") {
      return;
    }
    Controller.get("dashboard").deployNewRelease(releaseMD);
  };

  function isDeployable() {
    return window.location.host === "beta.dashboard.offices.nuxeo.com";
  }

  return (
    <div>
      <Paper className={classes.root}>
        Deploy Beta {warning}
        <Form className={classes.formContainer}>
          <Form.Field required>
            <label>
              <DateIcon />
                Release date
              </label>
            <Input readOnly placeholder="YYYY/MM/DD" value={new Date()} />
          </Form.Field>
          <Form.Field
            required
            id="form-textarea-control"
            control={TextArea}
            label="Release Note"
            placeholder="Release Note"
            onChange={handleChange}
          />
        </Form>
        <div className={classes.addButton}>
          <LoadingPanel async={async}>
            <Button
              disabled={!deployable}
              onClick={() => {
                deployNewRelease();
              }}
              variant="outlined"
              color="primary"
            >
              <DeployIcon className={classes.deployIcon} />
                DEPLOY BETA
              </Button>
          </LoadingPanel>
        </div>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(DeployBeta);

import { Divider, Button, MenuItem, Paper, TextField, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Header } from "semantic-ui-react";
import Breadcrumb from "../common/Breadcrumb";
import { Controller } from "../controllers";
import LoadingPanel from "../common/LoadingPanel";
import ObjectList from "../common/ObjectList";
import PanelActions from "../common/PanelActions";
import ProxyAliases from "./ProxyAliases";
import ProxyRedirects from "./ProxyRedirects";
import { deepCopy } from "../common/DeepCopy";

const styles = theme => ({
  root: {
    padding: 20,
    marginTop: 20
  },
  textField: {
    width: 400,
    margin: theme.spacing(1)
  },
  heading: {
    margin: 10,
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.primary,
    textTransform: "uppercase"
  },
  paddedContainer: {
    padding: 20
  },
  configDetails: {
    display: "flex",
    flexDirection: "column"
  },
  flexSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

const DisplayProxyDetails = ({ classes, proxy, onChange }) => {
  switch (proxy.type) {
    case "S3":
      return (
        <div>
          <TextField
            label="Bucket"
            value={proxy.bucket || ""}
            variant="outlined"
            className={classes.textField}
            onChange={evt => onChange(evt, "bucket")}
            id="proxy-bucket"
          />
        </div>
      );
    case "Redirect":
      return (
        <div>
          <TextField
            label="URL"
            value={proxy.url || ""}
            variant="outlined"
            className={classes.textField}
            onChange={evt => onChange(evt, "url")}
          />
        </div>
      );
    case "Host":
      return (
        <div className={classes.paddedContainer}>
          <ObjectList
            title=""
            noPaper
            headers={[
              {
                id: "",
                numeric: false,
                disablePadding: false,
                label: "host-servers",
                getCellWidget: n => {
                  return <p>{n}</p>;
                }
              }
            ]}
            data={proxy.servers}
          />
        </div>
      );
    case "EC2Instance":
      return <div>EC2Instance</div>;
    default:
      return <div className={classes.paddedContainer}>Please set a Proxy type</div>;
  }
};
const Aliases = ({ currentProxy, onChange }) => {
  return <ProxyAliases currentProxy={currentProxy} onChange={onChange} />;
};
const Redirect = ({ currentProxy, onChange }) => {
  return <ProxyRedirects currentProxy={currentProxy} onChange={onChange} />;
};

export const DomainPattern = "^(?!://)([a-zA-Z0-9-_]+.)*[a-zA-Z0-9][a-zA-Z0-9-_]+.[a-zA-Z]{2,11}?$";

const ranges = [
  {
    value: "S3",
    label: "S3 Hosting",
    subcategoryLabel: "Update SSLProxy in Bucket Policy"
  },
  {
    value: "Redirect",
    label: "Website Redirection",
    subcategoryLabel: "Define the URL to redirect To"
  }
];

const ProxySSLDetails = ({ classes, history }) => {
  const async_get = useSelector(state => state.proxy._async.GET_PROXY);
  const async_update = useSelector(state => state.proxy._async.UPDATE_PROXY || {});
  const async_delete = useSelector(state => state.proxy._async.DELETE_PROXY || {});
  const [dirty, setDirty] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState("Delete");
  const [errorText, setErrorText] = useState("");
  const model = useSelector(state => state.proxy.current);
  const [current, setCurrent] = useState(deepCopy(model));

  useEffect(() => {
    setCurrent(deepCopy(model))
  }, [model]);

  const onProxyDetailsChange = (evt, field) => {
    /* -------not allowing to modify existing domain-------- */
    if (field === "domain" && current.uuid !== "") {
      return;
    }
    if (field === "domain") {
      if (evt.target.value.match(DomainPattern)) {
        setErrorText("");
      } else {
        setErrorText("Invalid domain format");
      }
    }
    /*--------------------------------------------------------*/
    let modifiedCurrent = deepCopy(current);
    modifiedCurrent[field] = evt.target.value;
    setDirty(true);
    setCurrent(modifiedCurrent);
  };

  const onAliasesOrReplacesChange = field => {
    return value => {
      let modifiedCurrent = deepCopy(current);
      modifiedCurrent[field] = value.value;
      setDirty(true);
      setCurrent(modifiedCurrent);
    };
  };

  const onSave = () => {
    if (current.uuid === "") {
      Controller.get("proxy").addProxy(current, () => {
        setDirty(false);
        // saving new proxy routes to main proxies list
        history.push("/proxies-ssl");
      });
    } else {
      Controller.get("proxy").updateProxy(current, () => {
        // updating existing proxy with async_update in PanelActions
        setDirty(false);
      });
    }
  };

  const onCancel = () => {
    setCurrent(deepCopy(model));
    setDirty(false);
  };

  const deleteProxy = proxy => {
    if (deleteWarning === "Delete") {
      setDeleteWarning("Confirm Delete !");
    } else {
      setDeleteWarning("Delete");
      Controller.get("proxy").deleteProxy(proxy, () => {
        history.push("/proxies-ssl");
      });
    }
  };

  let incomplete = !current.type || !current.domain;
  if (current.type === "S3") {
    incomplete = !current.bucket;
  }

  let title = current.uuid || "";

  return (
    <Paper className={classes.root}>
      <div className={classes.flexSpaceBetween} id="proxy-detail-container">
        <Header as="h4">Proxy Details</Header>
        <Button
          variant="outlined"
          color="secondary"
          id={deleteWarning === "Delete" ? "delete-proxy-button" : "confirm-proxyDelete-button"}
          onClick={() => deleteProxy(current)}
        >
          {!async_delete.syncing ?
            deleteWarning :
            <div hidden={!async_delete.syncing}>
              <CircularProgress size={20} style={{ marginRight: "10px" }} />
            </div>
          }
        </Button>
      </div>
      <LoadingPanel async={async_get || async_update || async_delete}>
        <Breadcrumb items={[{ title: "PROXIES SSL", path: "/proxies-ssl" }, { title }]} />
        <TextField
          label="Domain"
          value={current.domain || ""}
          variant="outlined"
          className={classes.textField}
          onChange={evt => onProxyDetailsChange(evt, "domain")}
          error={errorText.length === 0 ? false : true}
          helperText={errorText}
          id="proxy-domain"
        />
        <TextField
          select
          label="Type"
          helperText="Please select a Type"
          required
          onChange={evt => onProxyDetailsChange(evt, "type")}
          className={classes.textField}
          value={current.type || ""}
          id="proxy-type"
        >
          {ranges.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="BasePath"
          onChange={evt => onProxyDetailsChange(evt, "basePath")}
          value={current.basePath || ""}
          variant="outlined"
          className={classes.textField}
          id="proxy-basePath"
        />
        <DisplayProxyDetails
          proxy={current}
          classes={classes}
          onChange={(evt, field) => onProxyDetailsChange(evt, field)}
        />
        <Divider />
        <Aliases currentProxy={current} onChange={onAliasesOrReplacesChange("aliases")} />
        <Divider />
        <Redirect currentProxy={current} onChange={onAliasesOrReplacesChange("replaces")} />
        <PanelActions
          onCancel={(proxies, current) => onCancel(proxies, current)}
          onSave={onSave}
          dirty={dirty}
          disabled={incomplete}
          async={async_update}
        />
      </LoadingPanel>
    </Paper>
  );
}


export default withStyles(styles)(ProxySSLDetails)

import * as pako from "pako";
import { Controller } from "./Controller";
import * as semver from "semver";
import { UserController } from "./UserController";
import { datadogLogs } from "@datadog/browser-logs";

class DashboardController extends Controller {
  _mouseMove: boolean = false;

  constructor() {
    super("dashboard", { amis: {}, UIConfiguration: [], sessionTimeout: true });
    if (!this.isDev()) {
      datadogLogs.init({
        clientToken: "pubaac882a20e76e625560cee9a97bb6a41",
        // @ts-ignore
        datacenter: "us",
        isCollectingError: true,
        sampleRate: 100
      });
    }
    window.addEventListener("mousemove", evt => {
      this._mouseMove = true;
    });
    window.setInterval(() => {
      if (this._mouseMove) {
        Controller.get<UserController>("users").fetch("REFRESH_SESSION");
      }
      this._mouseMove = false;
    }, 10 * 60 * 1000);
    this.registerRoute({ path: "/docs", strict: false, exact: true }, this.syncDocs);
    this.registerRoute({ path: "/costs", strict: false, exact: true }, this.syncCosts);
  }

  syncAMIs() {
    this.asyncAction("SYNC_AMIS", async () => {
      let amis = await this.ajax("/dashboard/amis");
      return { amis };
    });
  }

  getAccountName(id) {
    if (!this.getLocalState().organization[id]) {
      return "Unknown";
    }
    return this.getLocalState().organization[id].Alias || "Unknown";
  }

  syncOrganization() {
    this.asyncAction(
      "SYNC_ORGANIZATION",
      async () => {
        let organization = await this.ajax("/aws/organization");
        return { organization };
      },
      () => {
        this.setInitialized();
        this.syncAMIs();
      }
    );
  }

  getOrganization() {
    return this.getLocalState().organization;
  }

  downloadDoc(doc) {
    window.open(Controller.endpoint + `/docs/${doc.s3key}`);
  }

  downloadReport(report) {
    console.log("should implement report download", report);
  }

  async downloadCostsReport(daterange) {
    let { Location } = await this.ajax(`/costs/report/${daterange}`);
    window.open(Location);
  }

  syncDocs() {
    this.asyncAction("SYNC_DOCS", async () => {
      let info: any = await this.ajax("/docs");
      let data = await fetch(info.Location, { method: "GET" });
      return { docs: JSON.parse(pako.ungzip(await data.arrayBuffer(), { to: "string" })) };
    });
  }

  syncCosts() {
    this.asyncAction("SYNC_COSTS", async () => {
      return { costsReports: await this.ajax("/costs/reports") };
    });
  }

  parseChangeLog(changelog: string, before: string = undefined) {
    // Ignore wrong version
    if (before && !before.match(/\d+\.\d+\.\d+/g)) {
      before = undefined;
    }
    let dashboardReleases = [];
    // Parse
    let infos = changelog.split("## ");
    infos.forEach(i => {
      let { groups } =
        /^(?<tag>v[^\n]*)\n\nDate: (?<date>\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z)\n\n(?<md>[\w\W]+)/.exec(i) || {};
      if (!groups || groups.md.trim().length === 0) {
        return;
      }
      if (before && semver.gt(groups.tag, before)) {
        return;
      }
      // @ts-ignore
      groups.date = new Date(groups.date);
      dashboardReleases.push(groups);
    });
    return dashboardReleases;
  }

  loadChangeLog(project: string) {
    this.asyncAction("SYNC_CHANGELOG", async () => {
      let url: any = await this.ajax(`/changelogs/${project}`);
      let changelog: string = await (await fetch(url.redirect)).text();
      let version: string = undefined;
      if (project === "dashboard") {
        let res = await fetch("/version.txt");
        version = await res.text();
      } else if (project === "api") {
        let res = await this.ajax("/version.txt");
        version = await res.text();
      }
      return {
        currentChangeLog: this.parseChangeLog(changelog, version)
      };
    });
  }

  syncReleases() {
    this.asyncAction("SYNC_RELEASES", async () => {
      await this.waitInit();
      let releases: any = await this.ajax("/dashboard/releases-notes");
      let res = await fetch("/version.txt");
      let version = await res.text();
      let changelogs = {};
      for (let i in releases.changelogs) {
        changelogs[i] = "";
        if (i === "dashboard") {
          changelogs[i] = releases.dashboard;
          // Process every versions
        }
      }

      let rlz = this.parseChangeLog(releases.dashboard, version);
      return {
        releases: releases.dashboard,
        version,
        changelogs,
        dashboardReleases: rlz,
        currentChangeLog: rlz
      };
    });
  }

  deployNewRelease(md) {
    this.asyncAction("DEPLOY_RELEASE", async () => {
      let releases: any = await this.ajax("/deploy", "POST", { md });
      return { releases: releases.dashboard };
    });
  }

  syncPermissions() {
    this.asyncAction("SYNC_PERMISSIONS", async () => {
      return { permissions: await this.ajax("/permissions") };
    });
  }

  syncUIConfiguration() {
    this.asyncAction("SYNC_UI_CONFIGURATION", async () => {
      return { UIConfiguration: await this.ajax("/dashboard/uiconfig") };
    });
  }

  syncFactors() {
    this.asyncAction("SYNC_FACTORS", async () => {
      return { factors: await this.ajax("/factors") };
    });
  }

  onSESSION_TIMEOUT(state) {
    return {
      ...state,
      sessionTimeout: true
    };
  }

  afterSESSION_TIMEOUT() {
    Controller.get<UserController>("users").logout();
  }

  afterLOGIN_SUCCESS() {
    this.syncOrganization();
    this.syncReleases();
    this.syncPermissions();
    this.syncUIConfiguration();
    this.syncFactors();
  }

  onROUTE_CHANGED(state, action) {
    if (action.location.pathname.startsWith("/kibana")) {
      // @ts-ignore
      window.location = state.UIConfiguration.kibanaUrl;
    }
    return { ...state };
  }

  isDev(): boolean {
    return window.location.host === "localhost";
  }
}

export { DashboardController };

import { Button, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import React from "react";
import ReactJson from "react-json-view";
import { useSelector } from "react-redux";
import { Card } from "semantic-ui-react";
import Breadcrumb from "../common/Breadcrumb";
import LoadingPanel from "../common/LoadingPanel";
import { blue } from "@material-ui/core/colors";

const styles = theme => ({
  card: {
    display: "flex",
    margin: 15
  },
  cardDetails: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    display: "flex",
    flexDirection: "column"
  },
  cover: {
    width: 88,
    padding: 10,
    maxHeight: 88,
    transition: "0.5s filter linear"
  },
  rightButton: {
    position: "relative",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  },
  colorSwitchBase: {
    "&$colorChecked": {
      color: blue[500],
      "& + $colorBar": {
        backgroundColor: blue[500]
      }
    }
  },
  colorChecked: {},
  colorBar: {},
  root: {
    minHeight: 380,
    padding: 20,
    marginTop: 20
  },
  addButton: {
    position: "relative",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    padding: 0
  },
  libelles: {
    padding: 20
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  description: {
    width: "100%",
    paddingTop: 10,
    paddingBotoom: 10
  },
  addEnv: {
    margin: 20,
    width: "300px"
  },
  details: {
    alignItems: "flex-start"
  },
  column: {
    flexBasis: "33.33%",
    alignItems: "flex-start"
  },
  flexBox: {
    flexDirection: "row",
    padding: "10px"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  }
});

const TokensDetailsPanelNoStyle = ({ classes, async, children }) => {
  return (
    <div>
      <LoadingPanel async={async}>
        <Paper className={classes.root}>{children}</Paper>
      </LoadingPanel>
    </div>
  );
}

const TokensDetailsPanel = withStyles(styles)(TokensDetailsPanelNoStyle);

const TokensDetails = ({ history, match }) => {

  const async = useSelector(state => state.tokens._async.SYNC_TOKEN_SGS || {});
  const SGs = useSelector(state => state.tokens.SGs);
  const title = match ? match.params.id : "";

  return (
    <TokensDetailsPanel async={async}>
      <Breadcrumb items={[{ title: "Tokens", path: "/tokens" }, { title }]} />
      <h2>
        {SGs.length} rules found associated with the token <code>{title}</code> :
        </h2>
      <Card.Group>
        {(SGs || []).map((sg, k) => {
          return (
            <Card key={k}>
              <Card.Content>
                <Card.Header>
                  <Button
                    onClick={() => {
                      history.push(`/cloud-inventory/_search/${sg.GroupId}`)
                    }}
                    variant="outlined"
                    style={{ marginLeft: "0.5em" }}
                  >
                    <ZoomInIcon />
                    {sg.GroupId}
                  </Button>
                </Card.Header>
                <Card.Description>
                  {sg.account.Alias} / {sg.region}
                </Card.Description>
                <Card.Meta>{sg.Status}</Card.Meta>
              </Card.Content>
              <Card.Content>
                {
                  // eslint-disable-next-line
                  Object.keys(sg).map(k => {
                    if (
                      [
                        "region",
                        "account",
                        "Token",
                        "GroupId",
                        "StatusInfos",
                        "Status",
                        "StatusErrors",
                        "TargetValue",
                        "TargetSource"
                      ].indexOf(k) === -1
                    ) {
                      return (
                        <div>
                          <code key={k} style={{ fontSize: 9 }}>
                            {k}: <span style={{ color: "blue" }}>{JSON.stringify(sg[k])}</span>
                          </code>
                        </div>
                      );
                    }
                  })
                }
                <ReactJson src={sg} collapsed={true} style={{ fontSize: 9 }} />
              </Card.Content>
            </Card>
          );
        })}
        {!SGs.length}
      </Card.Group>
    </TokensDetailsPanel>
  );
}

export default TokensDetails;

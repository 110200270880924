import React from "react";
import ObjectList from "../common/ObjectList";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import IngressIcon from "@material-ui/icons/CloudDownload";
import EgressIcon from "@material-ui/icons/CloudUpload";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import RevokeIcon from "@material-ui/icons/PanTool";
import ApproveIcon from "@material-ui/icons/ThumbUp";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import AddIcon from "@material-ui/icons/Add";
import { Controller } from "../controllers";
import DeleteIcon from "@material-ui/icons/Delete";


const SgRule = ({ title, dataPath, onReview, route }) => {
    let headers = [
        {
            id: "TokenId",
            numeric: false,
            disablePadding: false,
            getCellWidget: n => {
                return <div>{n.TokenId || "-"}</div>;
            },
            label: "Token"
        },
        {
            id: "ts",
            getCellWidget: n => {
                return <div>{new Date(n.ts).toLocaleString()}</div>;
            },
            numeric: true,
            disablePadding: false,
            label: "Date"
        },
        {
            id: "GroupName",
            numeric: false,
            getCellWidget: n => {
                return <div style={{ fontWeight: "bold" }}>{n.GroupName}</div>;
            },
            disablePadding: false,
            label: "Group name"
        },
        {
            id: "Target",
            numeric: false,
            getCellWidget: n => {
                return <div style={{ fontWeight: "bold" }}>{n.Target}</div>;
            },
            disablePadding: false,
            label: "Target"
        },
        {
            id: "Description",
            numeric: false,
            disablePadding: false,
            label: "Description"
        },
        {
            id: "Type",
            numeric: false,
            disablePadding: false,
            label: "*gress",
            getCellWidget: n => {
                return (
                    <Tooltip title={n.Type || "Unknown type"}>
                        {n.Type === "EGRESS" ? <EgressIcon /> : <IngressIcon color="secondary" />}
                    </Tooltip>
                );
            }
        },
        {
            id: "IpProtocol",
            numeric: false,
            disablePadding: false,
            label: "Protocol"
        },
        {
            id: "ToPort",
            numeric: false,
            disablePadding: false,
            label: "Port"
        },
        {
            id: "login",
            numeric: false,
            disablePadding: false,
            label: "Claimant"
        },
        {
            id: "Status",
            numeric: false,
            disablePadding: false,
            getCellWidget: n => {
                let s = n.Status;
                if (onReview) {
                    s = "pending";
                }
                return (
                    <Tooltip title={s || "Unknown result"}>
                        {s === "pending" ? (
                            <HourglassEmptyIcon color="primary" />
                        ) : s === "APPROVED" ? (
                            <ApproveIcon color="primary" />
                        ) : s === "REVOKED" ? (
                            <RevokeIcon color="secondary" />
                        ) : s === "DELETED" ? (
                            <DeleteIcon color="secondary" />
                        ) : (
                                            <ContactSupportIcon color="disabled" />
                                        )}
                    </Tooltip>
                );
            },
            label: "Status"
        }
    ];

    if (onReview) {
        headers.unshift({
            id: "RequestType",
            numeric: false,
            disablePadding: false,
            label: "Request type",
            getCellWidget: n => {
                return (
                    <Tooltip title={n.RequestType || "Unknown"}>
                        {n.RequestType === "delete" ? (
                            <DeleteIcon color="secondary" />
                        ) : n.RequestType === "create" ? (
                            <AddIcon color="primary" />
                        ) : n.RequestType === "update" ? (
                            <AddIcon color="secondary" />
                        ) : (
                                        <ContactSupportIcon color="disabled" />
                                    )}
                    </Tooltip>
                );
            }
        });
        if (Controller.get("users").hasPermission("tokensAdmin")) {
            headers.push({
                id: "Actions",
                numeric: false,
                disablePadding: false,
                getCellWidget: n => {
                    return (
                        <div key="actions">
                            {Controller.get("users").hasPermission("tokensAdmin") && n.RequestType === "delete" && (
                                <Tooltip title="Delete">
                                    <Button
                                        onClick={() => {
                                            onReview("delete", n);
                                        }}
                                        variant="outlined"
                                        color="secondary"
                                    >
                                        <DeleteIcon color="secondary" />
                                    </Button>
                                </Tooltip>
                            )}
                            {Controller.get("users").hasPermission("tokensAdmin") && n.RequestType !== "delete" && (
                                <Tooltip title="Revoke">
                                    <Button
                                        onClick={() => {
                                            onReview("revoke", n);
                                        }}
                                        variant="outlined"
                                        color="secondary"
                                    >
                                        <RevokeIcon color="secondary" />
                                    </Button>
                                </Tooltip>
                            )}
                            {Controller.get("users").hasPermission("tokensAdmin") && n.RequestType !== "delete" && (
                                <Tooltip title="Approve">
                                    <Button
                                        onClick={() => {
                                            onReview("approve", n);
                                        }}
                                        variant="outlined"
                                        color="primary"
                                        style={{ marginLeft: "0.5em" }}
                                    >
                                        <ApproveIcon color="primary" />
                                    </Button>
                                </Tooltip>
                            )}
                            {n.TokenId && (
                                <Tooltip title="Zoom details">
                                    <Button
                                        onClick={() => {
                                            route(`/cloud-inventory/_search/${n.TokenId}`); // TODO: replace tokenid per (security)groupid
                                        }}
                                        variant="outlined"
                                        style={{ marginLeft: "0.5em" }}
                                    >
                                        <ZoomInIcon />
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    );
                },
                label: "Actions"
            });
        }
    } else {
        headers.push({
            id: "ReviewLogin",
            disablePadding: false,
            label: "Reviewer",
            numeric: false
        });
        headers.push({
            id: "ReviewDate",
            disablePadding: false,
            label: "Date review",
            getCellWidget: n => {
                return <div>{new Date(n.ReviewDate).toLocaleString()}</div>;
            },
            numeric: true
        });
        headers.push({
            id: "Actions",
            numeric: false,
            disablePadding: false,
            getCellWidget: n => {
                return (
                    n.TokenId && (
                        <div key="actions">
                            <Tooltip title="Zoom details">
                                <IconButton
                                    onClick={() => {
                                        route(`/tokens/${n.TokenId}`);
                                    }}
                                    variant="outlined"
                                >
                                    <ZoomInIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                );
            },
            label: "Actions"
        });
    }

    return (
        <ObjectList
            noPaper
            title={title}
            headers={headers}
            storeData={dataPath}
            defaultOrderBy="Date"
            defaultOrder="asc"
        />
    );
}

export default SgRule;
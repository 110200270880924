import { Button } from "@material-ui/core";
import * as filesize from "filesize";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { DateInput } from "semantic-ui-calendar-react";
import { Accordion, Icon, Segment, Statistic } from "semantic-ui-react";
import { Controller } from "../controllers";
import LoadingPanel from "../common/LoadingPanel";
import ObjectList from "../common/ObjectList";

const ESStatusPanel = () => {

  const [activeIndex, setActiveIndex] = useState(0);
  const [rebuilding, setRebuilding] = useState(false);
  const [dateSince, setDateSince] = useState(new Date(Date.now() - 7 * 86400 * 1000).toISOString().split("T")[0]);
  const [dateUntil, setDateUntil] = useState(new Date().toISOString().split("T")[0]);
  const status = useSelector(state => state.health.es);
  const async = useSelector(state => state.health._async.SYNC_ES_HEALTH || {});

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const onRebuild = e => {
    setRebuilding(true);
    let data = { dateSince, dateUntil };
    Controller.get("health").beginRebuild(data);
  };

  function onChange(field) {
    return (event, { value: v } = {}) => {
      if (event === undefined && !v) return;
      let value = v;
      if (!value && event) {
        if (event.target) value = event.target.value;
        else value = event;
      }
      let getVal = v => v.name || v.value || v;
      if (field === "dateSince") {
        setDateSince(getVal(value));
      };
      if (field === "dateUntil") {
        setDateUntil(getVal(value));
      }
    };
  }

  let url = `${Controller.endpoint}/datadog/es`;

  let getColorForPercent = percent => {
    return percent < 80 ? "red" : percent < 90 ? "yellow" : "green";
  };
  let getHumanNumber = num => {
    return filesize(num, { base: 10 }).replace(/[b|B]/g, "");
  };
  let getHumanSize = size => {
    return filesize(size);
  };

  let shardColor = getColorForPercent(status.cluster.health.active_shards_percent_as_number);

  return (
    <LoadingPanel async={async}>
      <div style={{ paddingTop: "30px" }}>
        <Accordion fluid styled>
          <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClick} id="ess-cluster-dropdown">
            <Icon name="dropdown" />
              Cluster
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 0} id="ess-cluster-content">
            <Segment textAlign="center" padded color={status.cluster.health.status}>
              {status.cluster.health && status.cluster.stats && (
                <Statistic.Group widths={3}>
                  <Statistic color={status.cluster.health.status}>
                    <Statistic.Value>{getHumanNumber(status.cluster.stats.indices.docs.count)}</Statistic.Value>
                    <Statistic.Label>Documents</Statistic.Label>
                  </Statistic>
                  <Statistic color={status.cluster.health.status}>
                    <Statistic.Value>{getHumanNumber(status.cluster.stats.indices.docs.deleted)}</Statistic.Value>
                    <Statistic.Label>Deleted Docs</Statistic.Label>
                  </Statistic>
                  <Statistic color={status.cluster.health.status}>
                    <Statistic.Value>{getHumanSize(status.cluster.stats.indices.store.size_in_bytes)}</Statistic.Value>
                    <Statistic.Label>Size</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              )}
            </Segment>

            <Segment textAlign="center" padded color={shardColor}>
              {status.cluster.health && (
                <Statistic.Group widths={4}>
                  <Statistic color={shardColor}>
                    <Statistic.Value>{status.cluster.health.active_shards}</Statistic.Value>
                    <Statistic.Label>Total</Statistic.Label>
                  </Statistic>
                  <Statistic color={shardColor}>
                    <Statistic.Value>{status.cluster.health.active_primary_shards}</Statistic.Value>
                    <Statistic.Label>Primary</Statistic.Label>
                  </Statistic>
                  <Statistic color={shardColor}>
                    <Statistic.Value>{status.cluster.health.relocating_shards}</Statistic.Value>
                    <Statistic.Label>Relocating</Statistic.Label>
                  </Statistic>
                  <Statistic color={shardColor}>
                    <Statistic.Value>{status.cluster.health.active_shards - status.cluster.health.active_primary_shards}</Statistic.Value>
                    <Statistic.Label>Replicas</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              )}
            </Segment>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 1} index={1} onClick={handleClick} id="ess-indexes-dropdown">
            <Icon name="dropdown" />
              Indexes
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 1} id="ess-indexes-content">
            <ObjectList
              title="Indexes"
              headers={[
                {
                  id: "status",
                  numeric: false,
                  disablePadding: false,
                  label: "Status"
                },
                {
                  id: "name",
                  numeric: false,
                  disablePadding: false,
                  label: "Name"
                },
                {
                  id: "docs",
                  numeric: true,
                  disablePadding: false,
                  label: "Documents",
                  getCellWidget: n => {
                    return <div>{getHumanNumber(n.docs)}</div>;
                  }
                },

                {
                  id: "deleted",
                  numeric: true,
                  disablePadding: false,
                  label: "Deleted",
                  getCellWidget: n => {
                    return <div>{getHumanNumber(n.deleted)}</div>;
                  }
                },
                {
                  id: "bytes",
                  numeric: true,
                  disablePadding: false,
                  label: "Size",
                  getCellWidget: n => {
                    return <div>{getHumanSize(n.bytes)}</div>;
                  }
                }
              ]}
              storeData="$.health.es.indices"
              defaultOrderBy="name"
              defaultOrder="desc"
            />
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 2} index={2} onClick={handleClick} id="ess-stockCharts-dropdown">
            <Icon name="dropdown" />
              Log charts
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 2} id="ess-stockCharts-content">
            <iframe
              src={url}
              style={{
                width: "100%",
                height: "1500px",
                border: "0px"
              }}
              title="Datadog Dashboard"
            ></iframe>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 3} index={3} onClick={handleClick} id="ess-rebuild-dropdown">
            <Icon name="dropdown" />
              Rebuild
            </Accordion.Title>
          <Accordion.Content active={activeIndex === 3} id="ess-rebuild-content">
            <Segment
              padded
              color={status.cluster.health.status}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              Since date (! Before {new Date(Date.now() - 86400000 * 365).toLocaleString()} is in Glacier) :
                <DateInput
                required
                name="dateSince"
                value={dateSince}
                placeholder="From"
                onChange={onChange("dateSince")}
                maxDate={new Date(dateUntil)}
                startMode="day"
                initialDate={new Date()}
                dateFormat="YYYY-MM-DD"
              />
                Until date:
                <DateInput
                required
                name="dateUntil"
                value={dateUntil}
                placeholder="To"
                onChange={onChange("dateUntil")}
                minDate={new Date(dateSince)}
                startMode="day"
                initialDate={new Date()}
                dateFormat="YYYY-MM-DD"
              />
              <Button color="primary" onClick={onRebuild} disabled={rebuilding}>
                Rebuild this daterange
                </Button>
            </Segment>
          </Accordion.Content>
        </Accordion>
      </div>
    </LoadingPanel>
  );
}

export default ESStatusPanel;

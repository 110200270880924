import { Controller } from "./Controller";

class ComplianceController extends Controller {
    constructor() {
        super("compliance", { complianceReports: [], currentReport: {} });
        this.registerRoute({ path: "/compliance-reports/:id", strict: false, exact: true }, this._fetchRoute);
        this.registerRoute({ path: "/compliance-reports", strict: false, exact: true }, this.syncCompliance);
    }

    syncCompliance() {
        this.asyncAction(
            "SYNC_COMPLIANCE",
            async () => {
                let complianceReports = await this.ajax("/dashboard/compliance-reports");
                let complianceReportsData = [];
                Object.keys(complianceReports).forEach(complianceReport => {
                    if (!complianceReport.startsWith("_") && complianceReport !== "uuid") {
                        let data = complianceReports[complianceReport];
                        let newItem = { uuid: complianceReport, ...data }
                        complianceReportsData.push(newItem);
                    }
                });
                return { complianceReports: complianceReportsData };
            }
        );
    }

    _fetchRoute(params) {
        this.syncCompliance();
        if (params.id) {
            this.get(params.id);
        }
    }

    get(uuid) {
        this.asyncAction("GET_COMPLIANCE_REPORT", async (dispatch, getState) => {
            await Controller.get("users").waitInit();
            let current = this.getLocalState().complianceReports.filter(
                complianceReport => complianceReport.uuid === uuid
            );
            return { currentReport: current[0] };
        })
    }
}


export { ComplianceController };
import React from "react";
import { Header } from "semantic-ui-react";
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

const TagsTable = ({ resource }) => {
  return (
    <div style={{ padding: 15 }}>
      <Header as="h4">Tags</Header>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tag key</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resource.Tags.map((tag, i) => {
            return (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {tag.Key}
                </TableCell>
                <TableCell>{tag.Value}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default TagsTable;

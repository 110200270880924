import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    padding: 20,
    marginTop: 20
  },
  flexColumnContainer: {
    height: 130,
    width: 150,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 5,
    margin: 5,
    transition: "opacity 30ms linear 50ms, transform 70ms ease-in-out 50ms",
    opacity: 0.6,
    "&:hover": {
      transform: "scale(1.05)",
      opacity: 0.9,
      cursor: "grab",
      transition: "opacity 30ms linear 70ms, transform 70ms ease-in 70ms"
    }
  }
});

const SecurityTableElement = ({ classes, element, onClick }) => {
  let whiteCell = false;
  if (element.color === "white") {
    whiteCell = true;
  }

  return (
    <div
      white-cell={whiteCell ? "true" : undefined}
      className={classes.flexColumnContainer}
      style={{ border: `2px solid ${element.color}` }}
      onClick={onClick}
    >
      <p style={{ color: element.color, fontSize: "30px" }}>
        {element.acronym}
      </p>
      <p
        style={{
          color: element.color,
          fontWeight: "700",
          textAlign: "center"
        }}
      >
        {element.fullName}
      </p>
    </div>
  );
}

export default withStyles(styles)(SecurityTableElement);

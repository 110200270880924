import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextareaAutosize from "react-textarea-autosize";
import ls from "local-storage";
import { Paper } from "@material-ui/core";

const styles = theme => ({
  root: {
    padding: 20,
    marginTop: 20
  },
  heading: {
    margin: 10,
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.primary,
    textTransform: "uppercase"
  },
  textContent: {
    margin: 20,
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  flexContainer: {
    display: "flex",
    jusifyContent: "flexStart",
    alignItems: "center",
    flexDirection: "column"
  }
});

const Account = ({ user, classes }) => {
  const [privateKey, setPrivateKey] = useState(ls.get("privateKey"));

  const onChange = () => {
    return evt => {
      ls.set("privateKey", evt.target.value);
      setPrivateKey(ls.get("privateKey"));
    };
  };

  let gravatar = `https://www.gravatar.com/avatar/${user.gravatar}`;
  return (
    <Paper className={classes.root}>
      <div>
        <div className={classes.flexContainer}>
          <img src={gravatar} aria-hidden="true" className="ui circular image" alt="gravatar" />
          <p className={classes.textContent}>Name: {user.name} </p>
          <p className={classes.textContent}>Login: {user.login}</p>
        </div>
        <p className={classes.heading}>Groups</p>
        {user.groups.map((grp, i) => {
          return (
            <p className={classes.textContent} key={i}>
              {grp}
            </p>
          );
        })}
        <hr />
        {user.publicKey ? (
          <div>
            <p className={classes.heading}>SSH Public Key:</p>
            <p className={classes.textContent}>
              <TextareaAutosize
                style={{ fontSize: 12, width: "100%", fontFamily: "monospace", border: 0 }}
                value={user.publicKey}
                disabled="disabled"
              />
            </p>
          </div>
        ) : (
          <div>
            <p className={classes.heading}>SSH Public Key:</p>
            <p className={classes.textContent}>
              Please register your SSH Public Key following this &nbsp;
              <a href="https://nuxeowiki.atlassian.net/wiki/x/BICbLw">guide</a>
            </p>
          </div>
        )}
        <hr />
        <div>
          <p className={classes.heading}>Password encrypted Private Key (not stored in server):</p>
          <p className={classes.textContent}>
            <TextareaAutosize
              style={{ fontSize: 12, width: "100%", fontFamily: "monospace" }}
              value={privateKey}
              onChange={onChange()}
              placeholder="(password protected raw private key)"
            />
          </p>
        </div>
      </div>
    </Paper>
  );
};

export default withStyles(styles)(Account);

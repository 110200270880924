import { LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import LoadingPanel from "../common/LoadingPanel";
import ObjectList from "../common/ObjectList";

const styles = theme => ({
  root: {
    height: 380
  },
  addButton: {
    position: "relative",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  }
});

const RunbooksList = props => {
  return (
    <ObjectList
      title="Runbooks"
      headers={[
        {
          id: "status",
          numeric: false,
          disablePadding: false,
          label: "Status"
        },
        {
          id: "_lastUpdate",
          numeric: true,
          disablePadding: false,
          label: "Date"
        },
        {
          id: "actor",
          numeric: false,
          disablePadding: false,
          label: "Actor"
        },
        {
          id: "category",
          numeric: false,
          disablePadding: false,
          label: "Category"
        },
        {
          id: "command",
          numeric: false,
          disablePadding: false,
          label: "Class"
        },
        {
          id: "args",
          numeric: false,
          disablePadding: false,
          getCellWidget: n => {
            return (n.args || []).map((arg, i) => {
              return <div key={i}>{arg}</div>;
            });
          },
          label: "Args"
        },
        {
          id: "publicIp",
          numeric: false,
          disablePadding: false,
          label: "ip"
        },
        {
          id: "task",
          numeric: false,
          disablePadding: false,
          label: "Task"
        },
        {
          id: "percent",
          numeric: true,
          disablePadding: false,
          label: "Progress",
          getCellWidget: n => {
            return <LinearProgress variant="determinate" value={n.percent} />;
          }
        },
        {
          id: "detail",
          numeric: true,
          disablePadding: false,
          label: "Details",
          getCellWidget: n => {
            let p = Math.floor(n.percent * 1000) / 1000;
            return (
              <div>
                {n.details} {p ? `(${p}%)` : ""}
              </div>
            );
          }
        }
      ]}
      storeData="$.runbooks.list"
      defaultOrderBy="_lastUpdate"
      defaultOrder="asc"
      onItemDoubleClicked={n => {
        props.history.push(`/security-automation/${n.uuid}`);
      }}
    />
  );
};

const Runbooks = (props) => {
  const async = useSelector(state => state.runbooks._async.SYNC_RUNBOOKS);
  return (
    <div>
      <LoadingPanel async={async}>
        <Route exact path="/security-automation" render={() => <RunbooksList {...props} />} />{" "}
      </LoadingPanel>
    </div>
  );
}


export default withStyles(styles)(Runbooks);

import React, { useState, useEffect } from "react";
import { Input } from "semantic-ui-react";
import { withStyles } from "@material-ui/core/styles";
import ObjectList from "../common/ObjectList";
import AddIcon from "@material-ui/icons/Add";
import { IconButton, Fab } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { deepCopy } from "../common/DeepCopy";

const styles = theme => ({
  validationContainer: {
    marginTop: 20
  },
  txtFld: {
    width: 450
  },
  mainContainer: {
    padding: 20
  },
  button: {
    marginLeft: 10
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: 400,
    alignItems: "baseline",
    justifyContent: "baseline"
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-evenly"
  },
  heading: {
    margin: 10,
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.primary
  },
  subHeading: {
    margin: 10,
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary
  },
  paddedContainer: {
    padding: 20
  },
  subContainer: {
    margin: 20
  }
});

function ActionReplace(actionFunction) {
  return {
    id: "delete",
    numeric: false,
    disablePadding: false,
    getCellWidget: n => {
      return (
        <IconButton
          onClick={() => {
            actionFunction(n);
          }}
        >
          <DeleteIcon />
        </IconButton>
      );
    }
  };
}

const ProxyRedirects = ({ currentProxy, classes, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [presentContent, setPresentContent] = useState(deepCopy(currentProxy.replaces));
  const [unitEntryFrom, setUnitEntryFrom] = useState("");
  const [unitEntryTo, setUnitEntryTo] = useState("");
  const [testedValue, setTestedValue] = useState("");
  const [testResult, setTestResult] = useState("");
  useEffect(() => {
    setPresentContent(deepCopy(currentProxy.replaces))
  }, [currentProxy])
  const toggleLoading = () => {
    setLoading(!loading);
  };

  const toggleError = () => {
    setError(!error);
  };

  const handleFileChosen = file => {
    toggleLoading();
    let fileReader = new FileReader();
    fileReader.onload = event => {
      let content = fileReader.result;
      let redirectsArray = content.split("\n");
      let oldRedirectsRules = deepCopy(presentContent);
      redirectsArray.forEach(redirect => {
        let splitRedirect = redirect.split(": ");
        let redirectToAdd = { from: splitRedirect[0], to: splitRedirect[1] };
        if (redirectToAdd.from !== "" || redirectToAdd.to !== undefined) {
          oldRedirectsRules.push(redirectToAdd);
        }
      });
      toggleLoading();

      if (error === true) {
        toggleError();
      }
      setPresentContent(oldRedirectsRules);
      if (onChange) {
        onChange({ value: oldRedirectsRules });
      }
    };
    fileReader.onError = err => {
      console.log(err);
      toggleError();
      toggleLoading();
    };
    if (file.type !== "text/plain") {
      console.log("Only txt files are admited");
      toggleError();
      toggleLoading();
      return;
    }
    fileReader.readAsText(file);
  };

  const handleEntry = (event, inputProps) => {
    let value = event.target.value;
    if (inputProps.label === "From") {
      setUnitEntryFrom(value);
    } else if (inputProps.label === "To") {
      setUnitEntryTo(value)
    }
  };

  const handleEntryValidated = () => {
    if (unitEntryFrom === "" && unitEntryTo === "") {
      return;
    }
    let oldRedirectsRules = deepCopy(presentContent);
    let redirectToAdd = {
      from: unitEntryFrom,
      to: unitEntryTo
    };
    oldRedirectsRules.push(redirectToAdd);
    setPresentContent(oldRedirectsRules);
    if (onChange) {
      onChange({ value: oldRedirectsRules });
    }
    setUnitEntryFrom("");
    setUnitEntryTo("");
  };

  const handleTestValue = event => {
    let testedValue = event.target.value;
    let testResult = testedValue;
    let rules = presentContent;
    rules.forEach(rule => {
      testResult = testResult.replace(new RegExp(rule.from), rule.to);
    });
    setTestedValue(testedValue);
    setTestResult(testResult);
  };

  const deleteReplace = replaceToBeDeleted => {
    let oldRedirectsRules = deepCopy(presentContent);
    let newRedirects = oldRedirectsRules.filter(
      item => item.from !== replaceToBeDeleted.from && item.to !== replaceToBeDeleted.to
    );
    setPresentContent(newRedirects);
    onChange({ value: newRedirects });
  };


  return (
    <div className={classes.mainContainer}>
      <ObjectList
        title="Redirects"
        noPaper
        headers={[
          {
            id: "from",
            numeric: false,
            disablePadding: false,
            label: "From"
          },
          {
            id: "to",
            numeric: false,
            disablePadding: false,
            label: "To"
          },
          ActionReplace(deleteReplace)
        ]}
        data={presentContent}
      />
      <div className={classes.paddedContainer}>
        <div className={classes.subContainer}>
          <p className={classes.subHeading}>Manually add a redirection rule</p>
          <Input
            label="From"
            value={unitEntryFrom}
            onChange={(e, inputProps) => handleEntry(e, inputProps)}
            className={classes.txtFld}
            id="newRedirect-from"
          />
          <Input
            label="To"
            value={unitEntryTo}
            onChange={(e, inputProps) => handleEntry(e, inputProps)}
            className={classes.txtFld}
            id="newRedirect-to"
          />
          <Fab size="small" color="primary" aria-label="add" className={classes.button}>
            <AddIcon onClick={handleEntryValidated} id="add-redirect-button" />
          </Fab>
        </div>
        <div className={classes.subContainer}>
          <p className={classes.subHeading}>Or upload a set of rules from a .txt file</p>
          <Input
            loading={loading}
            error={error}
            icon="file"
            label="Import file"
            type="file"
            accept=".txt"
            onChange={e => handleFileChosen(e.target.files[0])}
          />
        </div>

        <div className={classes.subContainer}>
          <p className={classes.subHeading}>Test implemented rules</p>
          <Input
            value={testedValue}
            label="Value to be tested"
            onChange={e => handleTestValue(e)}
            className={classes.txtFld}
          />
          <Input value={testResult || "no match"} label="Result" className={classes.txtFld} />
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(ProxyRedirects);

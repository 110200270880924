import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import { blue } from '@material-ui/core/colors';

import { FormControlLabel, Switch } from '@material-ui/core';

const styles = theme => ({
  containerBaselined: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    padding: 10,
    alignItems: "baseline"
  },
  awsAccounts: {
    minWidth: 350,
    maxWidth: 450,
    padding: 20,
    borderWidth: "thin",
    borderStyle: "dashed",
    borderRadius: 10,
    borderColor: "grey",
    margin: 10
  },
  switchBase: {
    color: "white",
    '&$checked': {
      color: blue[500],
    },
    '&$checked + $track': {
      backgroundColor: blue[500],
    },
  },
  checked: {},
  track: {}
});

const PermissionsPanel = (props) => {
  const { current, classes } = props;
  const permissions = useSelector(
    state => state.dashboard.permissions || {
      permissions: {},
      groups: {}
    }
  );

  const onChange = permission => {
    if (!props.onChange) {
      return null;
    }
    return evt => {
      props.onChange(permission, evt.target.checked);
    };
  };

  return (
    <div>
      {Object.keys(permissions.groups).map(g => {
        let group = permissions.groups[g];
        if (!group.permissions) {
          return undefined;
        }
        return (
          <div className={classes.containerBaselined} key={g}>
            <div className={classes.awsAccounts}>
              <h3>{group.label}</h3>
              <Form>
                {Object.keys(group.permissions).map(p => {
                  let perm = group.permissions[p];
                  return (
                    <div key={p}>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={onChange(p)}
                            checked={current[p] === undefined ? false : current[p]}
                            disabled={(current.inherited || []).indexOf(p) >= 0}
                            classes={{
                              switchBase: classes.switchBase,
                              track: classes.track,
                              checked: classes.checked
                            }}
                          />
                        }
                        label={perm.label}
                      />
                    </div>
                  );
                })}
              </Form>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default withStyles(styles)(PermissionsPanel);

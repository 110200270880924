import { withStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Header } from "semantic-ui-react";
import Breadcrumb from "../common/Breadcrumb";
import { Controller } from "../controllers";
import LoadingPanel from "../common/LoadingPanel";
import PanelActions from "../common/PanelActions";
import { blue } from "@material-ui/core/colors";
import { Button, FormControlLabel, Paper, Switch, TextField } from "@material-ui/core";
import { DomainPattern } from "../cloud/ProxySSLDetails";
import { deepCopy } from "../common/DeepCopy";
import ReactLoading from "react-loading";


const styles = theme => ({
  root: {
    padding: 20,
    marginTop: 20
  },
  flexSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  flexCenteredAligned: {
    display: "flex",
    alignItems: "center"
  },
  textField: {
    width: 400,
    margin: theme.spacing(1)
  },
  colorSwitchBase: {
    "&$colorChecked": {
      color: blue[500]
    }
  },
  colorChecked: {},
  awsAccounts: {
    padding: 15,
    maxWidth: 815,
    borderWidth: "thin",
    borderStyle: "dashed",
    borderRadius: 10,
    borderColor: "grey",
    margin: 10
  }
});

const ScreenCastDetails = (props) => {
  const { classes } = props;
  const async = useSelector(state => state.screencasts._async.GET_SCREENCAST || {});
  const asyncDelete = useSelector(state => state.screencasts._async.DELETE_SCREENCAST || {});
  const asyncCreate = useSelector(state => state.screencasts._async.ADD_SCREENCAST);
  const offices = useSelector(state => state.dashboard.UIConfiguration.offices || []);
  const [dirty, setDirty] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState("Delete");
  const [domainErrorText, setDomainErrorText] = useState("");
  const [timingErrorText] = useState("");
  const model = useSelector(state => state.screencasts.current);
  const [current, setCurrent] = useState(deepCopy(model));

  useEffect(() => {
    setCurrent(deepCopy(model))
  }, [model]);

  const deleteScreen = () => {
    return evt => {
      if (deleteWarning === "Delete") {
        setDeleteWarning("Confirm Delete !")
      } else {
        setDeleteWarning("Delete")
        Controller.get("screencasts").deleteScreencast(current, () => {
          props.history.push("/screencasts");
        });
      }
    };
  };

  const onCancel = () => {
    setCurrent(deepCopy(model));
    setDirty(false);
  }

  const onScreenDetailsChange = (evt, field) => {
    if (field === "page") {
      if (evt.target.value.match(DomainPattern)) {
        setDomainErrorText("");
      } else {
        setDomainErrorText("Invalid domain format");
      }
    }
    let modifiedCurrent = deepCopy(current);

    modifiedCurrent[field] = evt.target.value;
    setDirty(true);
    setCurrent(modifiedCurrent);
  };

  const onOfficeChange = office => {
    let modifiedCurrent = deepCopy(current);
    if (modifiedCurrent.offices.includes(office.name)) {
      let newOffices = modifiedCurrent.offices.filter(el => el !== office.name);
      modifiedCurrent.offices = newOffices;
    } else {
      let newOffices = [...current.offices, office.name];
      modifiedCurrent.offices = newOffices;
    }
    setDirty(true);
    setCurrent(modifiedCurrent);
  };

  const onSave = () => {
    if (current.uuid === "") {
      Controller.get("screencasts").addScreencast(current, () => {
        setDirty(false);
        props.history.push("/screencasts");
      });
    } else {
      Controller.get("screencasts").updateScreencast(current, () => {
        setDirty(false);
      });
    }
  };

  let title = current.uuid || "new";
  let incomplete = true;
  if (current.page !== "" && current.timing !== 0 && current.offices !== []) {
    incomplete = false;
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.flexSpaceBetween} id="screencast-detail-container">
        <Header as="h4">Screen cast Details</Header>
        <Button
          variant="outlined"
          color="secondary"
          id="delete-screencast-button"
          onClick={deleteScreen()}
          disabled={title === "new"}
        >
          {asyncDelete.syncing ?
            <div>
              <ReactLoading
                type="spinningBubbles"
                color="secondary"
                height={20}
                width={20}
              />
            </div> :
            deleteWarning
          }
        </Button>
      </div>
      <LoadingPanel async={async || asyncCreate}>
        <Breadcrumb items={[{ title: "SCREENS CAST", path: "/screencasts" }, { title }]} />
        <TextField
          label="Page"
          value={current.page || ""}
          variant="outlined"
          className={classes.textField}
          onChange={evt => onScreenDetailsChange(evt, "page")}
          error={domainErrorText.length === 0 ? false : true}
          helperText={domainErrorText}
          id="newscreencast-page"
        />
        <TextField
          label="Timing"
          value={current.timing || 0}
          variant="outlined"
          className={classes.textField}
          onChange={evt => onScreenDetailsChange(evt, "timing")}
          error={timingErrorText.length === 0 ? false : true}
          helperText={timingErrorText}
          id="newscreencast-timing"
        />
        <div className={classes.awsAccounts}>
          <Form>
            {offices.map((office, i) => {
              if (current !== {} && current.offices !== {}) {
                return (
                  <div key={i}>
                    <FormControlLabel
                      control={
                        <Switch
                          classes={{
                            switchBase: classes.colorSwitchBase,
                            checked: classes.colorChecked
                          }}
                          checked={(current.offices || []).includes(office.name)}
                          onChange={() => onOfficeChange(office)}
                          id={`newscreencast-offices-${office.name}`}
                        />
                      }
                      label={office.name}
                    />
                  </div>
                );
              } else return null;
            })}
          </Form>
        </div>
        <PanelActions
          onCancel={onCancel}
          onSave={onSave}
          dirty={dirty}
          disabled={incomplete}
          async={async}
        />
      </LoadingPanel>
    </Paper>
  );
}

export default withStyles(styles)(ScreenCastDetails);

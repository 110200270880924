import { matchPath } from "react-router";
import Cookies from "universal-cookie";
import { Controller, HTTPError } from "redux-lz-controller";
import { MFAController } from "./MFAController";
import { UserController } from "./UserController";
const cookies = new Cookies();

class NxSecController extends Controller {
  protected static _csrfToken: string;
  protected routes: any[] = [];
  protected static _timeoutInterval: any = undefined;

  constructor(id: string, defaultState: any = {}) {
    super(id, defaultState);
  }

  public static getCSRFToken() {
    return cookies.get("nxt");
  }

  public static getReducers() {
    // Check for CSRF token here
    //  - Check if a query param csrfToken exists - if so store it in csrfToken JS cookie
    let csrfToken = new URLSearchParams(window.top.location.search).get("csrfToken");
    if (csrfToken) {
      // Write in cookie
      cookies.set("nxt", csrfToken);
    }
    //  - Read the CSRF Token from cookie
    NxSecController._csrfToken = cookies.get("nxt");
    return Controller.getReducers();
  }

  protected init() {
    super.init();
    if (this.routes.length) {
      this.addListener("afterROUTE_CHANGED", this);
    }
    return true;
  }

  onAsyncActionError(name, requestName, dispatch, postFailures, err) {
    if (err.code === 401) {
      // Session timeout
      dispatch({ type: "SESSION_TIMEOUT" });
    }
    super.onAsyncActionError(name, requestName, dispatch, postFailures, err);
  }

  registerRoute(match: any, method) {
    this.routes.push({ match, method });
  }

  afterROUTE_CHANGED() {
    this.routes.forEach(route => {
      let match = matchPath(window.location.pathname, route.match);
      if (match) {
        route.method.bind(this, match.params).call();
      }
    });
  }

  // MFA found on this contect :
  // - triggers UI to choose MFA
  mfa(response, options) {
    let hash = response.headers.get("x-mfa-required"),
      body = JSON.parse(options.body),
      { action } = options;
    return response.json().then(({ factors }) => {
      // triggers MFA UI combobox
      Controller.get<MFAController>("mfa").show(hash, factors, { action, body });
      return null;
    });
  }

  async ajax(url: string, method: string = "GET", body: any = undefined, options: any = { headers: {} }) {
    options = {
      credentials: "include",
      ...options,
      //@ts-ignore
      method,
      body: options.raw ? body : JSON.stringify(body),
      headers: {
        "content-type":
          options.headers && options.headers["content-type"] ? options.headers["content-type"] : "application/json",
        "x-webda-method": method,
        "x-csrf-token": NxSecController._csrfToken,
        ...options.headers
      }
    };
    return super.ajax(url, method, body, options);
  }

  processResponse(response: Response, request: Request): any {
    if (NxSecController._timeoutInterval) {
      clearTimeout(NxSecController._timeoutInterval);
    }
    NxSecController._timeoutInterval = setTimeout(() => {
      NxSecController.dispatch({ type: "SESSION_TIMEOUT" });
    }, 15 * 60 * 1000);
    if (response.status >= 400) {
      throw new HTTPError(response.status);
    }

    // MFA : triggers only when x-mfa* header
    if (response.headers.get("x-mfa-required")) {
      return this.mfa(response, request); //.then(() => {}); // do not transmit result during
    }
    return super.processResponse(response, request);
  }

  hasPermission(right: string) {
    return Controller.get<UserController>("users").hasPermission(right);
  }
}

export { NxSecController as Controller };

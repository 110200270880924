import ObjectList from "./common/ObjectList";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import { Switch, Route } from "react-router-dom";
import { Button } from "@material-ui/core";

const styles = theme => ({
  root: {
    height: 380
  },
  addButton: {
    position: "relative",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  }
});

const EventList = () => (
  <ObjectList
    title="Event events"
    headers={[
      {
        id: "date",
        numeric: false,
        disablePadding: false,
        label: "Date"
      },
      {
        id: "user",
        numeric: false,
        disablePadding: false,
        label: "User"
      },
      {
        id: "event",
        numeric: false,
        disablePadding: false,
        label: "Event"
      },
      {
        id: "description",
        numeric: false,
        disablePadding: false,
        label: "Description"
      }
    ]}
    storeData="$.users.events"
    routeTo="/event/{uuid}"
  />
);

const Event = ({ classes, location, history }) => {
  return (
    <div>
      <div className={classes.addButton} disabled={location.pathname === "/event/new"}>
        <Button
          onClick={() => {
            history.push("/event/rules");
          }}
          variant="outlined"
          color="primary"
        >
          <AddIcon />
            Manage alarm rules
          </Button>
      </div>
      <Route exact path="/event" component={EventList} />
      <Switch>
        {/* <Route exact path="/event/new" component={NewProject} />
          <Route path="/event/:id" component={EventDetails} /> */}
      </Switch>
    </div>
  );
}

export default withStyles(styles)(Event);

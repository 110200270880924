import React from "react";
import { Button, Card, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Controller } from "../controllers";

const Home = () => {
  const permissions = Controller.get("users").getPermissions();
  return (
    <div>
      <Card.Group>
        {permissions.map((permission, i) => {
          const PermissionIcon = permission.icon;
          return (
            <Card style={{ padding: "20px", margin: "20px" }} key={i}>
              <Card.Content>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  <Image
                    style={{
                      position: "absolute",
                      left: "-20px",
                      top: "-20px"
                    }}
                  >
                    <PermissionIcon color="primary" style={{ fontSize: 50 }} />
                  </Image>
                  <Card.Header>{permission.title}</Card.Header>
                </div>
                <Card.Description>{permission.description}</Card.Description>
              </Card.Content>
              <Card.Content extra>
                <div className="ui two buttons">
                  <Link to={permission.route}>
                    <Button basic color="blue">
                      Access
                      </Button>
                  </Link>
                </div>
              </Card.Content>
            </Card>
          );
        })}
      </Card.Group>
    </div>
  );
}

export default Home;

import { Controller } from "./Controller";

class HealthController extends Controller {
  history: any;
  location: any;
  constructor() {
    super("health", {
      es: {
        indices: [],
        cluster: {
          health: {},
          stats: {
            nodes: { os: { mem: { used_percent: 0 } }, process: { cpu: { percent: 0 } } },
            indices: { docs: { count: 0, deleted: 0 }, store: { size_in_bytes: 0 } }
          }
        }
      },
      heatmap: {}
    });
    this.registerRoute({ path: "/health", strict: false, exact: true }, this.syncHealth);
  }

  async beginRebuild(data: any): Promise<void> {
    await this.ajax("/elasticsearch/rebuild", "POST", data);
  }

  syncHealthMetrics() {
    this.asyncAction("SYNC_HEALTH", async () => {
      let health = await this.ajax("/dashboard/health");
      let metrics = [];
      for (let x in health.metrics || {}) {
        metrics.push({ ...health.metrics[x], service: x });
      }
      return { metrics };
    });
  }

  syncHealth() {
    this.syncHealthMetrics();
    this.asyncAction("SYNC_ES_HEALTH", async (dispatch, getState) => {
      await Controller.get("users").waitInit();
      return { es: await this.ajax("/dashboard/elasticsearch_status") };
    });
    this.asyncAction("SYNC_TEAM_HEALTH", async () => {
      // REMOVE ME force=1
      // REMOVE ME force=1
      // REMOVE ME force=1
      let heatmap = await this.ajax("/heatmap?force=1");
      return { heatmap };
    });
  }

  getHeatmapCSV(callback = () => {}) {
    this.asyncAction("CSV_HEATMAP", async (dispatch, getState) => {
      let { url } = await this.ajax(`/heatmap/csv`, "GET");
      window.location = url;
      callback();
    });
  }

  onCSV_HEATMAP(state, action) {
    return {
      ...state,
      csvLoader: action.csvLoader
    };
  }
}
export { HealthController };

import { datadogLogs } from "@datadog/browser-logs";
import * as pako from "pako";
import { matchPath } from "react-router";
import { Controller } from "./Controller";
import { DashboardController } from "./DashboardController";

class CloudInventoryController extends Controller {
  history: any;
  location: any;
  _resourcesMap = {};
  constructor() {
    super("cloudInventory", { current: {} });
  }

  onROUTE_CHANGED(state, action) {
    matchPath(action.location.pathname, {
      path: "/cloud-inventory/:id",
      exact: true,
      strict: false
    });
    return { ...state };
  }

  afterSYNC_ORGANIZATION_SUCCESS() {
    this.setInitialized();
  }

  getAccountName(uid) {
    return Controller.get<DashboardController>("dashboard").getAccountName(uid);
  }

  generateResourceId(type, resource) {
    let infos = [type];
    if (type === "EC2Instances") {
      infos = [resource.InstanceId];
    } else if (type === "S3s" || type === "S3") {
      infos.push(resource.accountId);
      infos.push(resource.awsRegion);
      infos.push(resource.name);
    } else if (type === "SGs") {
      infos = [resource.GroupId];
    } else if (type === "EBS") {
      infos = [resource.VolumeId];
    } else if (type === "RDSs") {
      infos = [resource.DbiResourceId];
    } else if (type === "CloudFront") {
      infos = [resource.Id];
    } else if (type === "Route53") {
      infos.push(resource.accountId);
      infos.push(resource.Name);
    } else {
      infos = [resource.arn];
    }
    return infos.join("_");
  }

  complianceChecks(type, resource) {
    let result: any = {};
    /*
      Compliance: {
        Encryption: {
          total: 0,
          exceptions: []
        },
        Tagging: {
          total: 0,
          exceptions: []
        },
        UnencryptedTraffic: {
          total: 0,
          exceptions: []
        }
      }
     */
    if (resource.Tags) {
      let found = { category: false, subcategory: false, environment: false };
      resource.Tags.forEach(tag => {
        if (
          tag.Key === "billing-category" &&
          ["internal", "support", "customers", "presales", "qa", "build", "ps", "dev", "support"].indexOf(tag.Value) >=
            0
        ) {
          found.category = true;
        }
        if (tag.Key === "billing-subcategory") {
          found.subcategory = true;
        }
        if (tag.Key === "environment") {
          found.environment = true;
        }
      });
      result.Tagging = found.category && found.environment && found.subcategory;
    }
    if (type === "CloudFront") {
      result.EncryptedTraffic = resource.DefaultCacheBehavior.ViewerProtocolPolicy !== "allow-all";
    } else if (type === "S3s") {
      result.Encryption = resource.encryption !== "";
    } else if (type === "SGs") {
      result.EncryptedTraffic = true;
      if (resource.Rules && resource.Rules.INGRESS) {
        resource.Rules.INGRESS.forEach(rule => {
          if (rule.Status === "REVIEWED") {
            return;
          }
          if (
            rule.ToPort === "any" ||
            rule.ToPort === 80 ||
            rule.ToPort === 21 ||
            rule.ToPort === 25 ||
            rule.ToPort === 20
          ) {
            result.EncryptedTraffic = false;
          }
        });
      }
    } else if (type === "EFS") {
      result.Encryption = resource.Encrypted;
    } else if (type === "RDSs") {
      result.Encryption = resource.StorageEncrypted;
    } else if (type === "EBS") {
      result.Encryption = resource.Encrypted;
    } else if (type === "EC2Instances") {
    }
    return result;
  }

  completeResource(type, resource) {
    resource._uuid = this.generateResourceId(type, resource);
    resource._type = type;
    this.addToMapping(resource);
    resource.compliant = this.complianceChecks(type, resource);
    return resource;
  }

  addToMapping(resource) {
    if (this._resourcesMap[resource._uuid]) {
      console.warn("Resource doesn't have an unique ID", resource, this._resourcesMap[resource._uuid]);
    }
    this._resourcesMap[resource._uuid] = resource;
  }
  completeInventory(inventory, accountId) {
    let today = Date.now();
    let regions = Object.keys(inventory);

    if (regions.indexOf("global") >= 0) {
      inventory.global.Route53.zones.forEach(zone => {
        zone.awsRegion = "global";
        zone.account = this.getAccountName(accountId);
        zone.accountId = accountId;
        zone = this.completeResource("Route53", zone);
      });
      inventory.global.CloudFront.distributions.forEach(distribution => {
        distribution.awsRegion = "global";
        distribution.account = this.getAccountName(accountId);
        distribution.accountId = accountId;
        distribution = this.completeResource("CloudFront", distribution);
      });
    }
    regions.forEach(selected => {
      ["S3s", "SGs", "Others", "EFS", "RDSs", "EC2Instances", "EBS"].forEach(key => {
        if (!inventory[selected][key]) {
          return;
        }
        inventory[selected][key].forEach(item => {
          item.awsRegion = selected;
          item.account = this.getAccountName(accountId);
          item.accountId = accountId;
          item = this.completeResource(key, item);
          if (key === "SGs") {
            item.validatedRulesNb = 0;
            item.rulesNb = item.Rules.EGRESS.length + item.Rules.INGRESS.length;

            item.Rules.EGRESS.forEach(rule => {
              if (rule.Status === "REVIEWED") {
                item.validatedRulesNb++;
              }
            });
            item.Rules.INGRESS.forEach(rule => {
              if (rule.Status === "REVIEWED") {
                item.validatedRulesNb++;
              }
            });
          } else if (key === "EC2Instances") {
            let launchtime = new Date(item.LaunchTime).getTime();
            item.upTime = Math.floor((today - launchtime) / 86400000);
          }
        });
      });
    });
    return inventory;
  }

  getResource(uuid) {
    return this._resourcesMap[uuid];
  }

  async loadInventories(force = false, selectedDate?: string) {
    if (Object.keys(this._resourcesMap).length > 0 && !force) {
      return;
    }
    this.asyncAction("SYNC_INVENTORY", async (dispatch, getState) => {
      await this.waitInit();
      let result = {};
      let orga = Controller.get<DashboardController>("dashboard").getOrganization() || {};
      let loads = [];
      // TODO Should dig into this but might be false positive
      /*eslint no-loop-func: "off"*/
      let date = "";
      let target = "";
      let error = "";
      let httpMethod: any = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          "x-csrf-token": Controller._csrfToken
        },
        credentials: "include"
      };
      Object.keys(orga).forEach(i => {
        if (selectedDate) {
          this._resourcesMap = {};
          target = `/aws/inventory/${i}/${selectedDate}`;
        } else {
          target = `/aws/inventory/${i}`;
        }
        loads.push(
          fetch(Controller.endpoint + target, httpMethod)
            .then(async resp => {
              let info = await resp.json();
              let res = info.Location.match(/.*(\d{4})(\d{2})(\d{2})-(\d{2})\d{4}.json.gz.*/);
              if (res) {
                date = `${res[1]}-${res[2]}-${res[3]} ${res[4]}:00 UTC`;
              }
              let cacheId = `awsInventory-${info.Location.split("?")[0]}`;
              let cache = sessionStorage.getItem(cacheId);
              if (cache) {
                return JSON.parse(pako.inflate(cache, { to: "string" }));
              }
              resp = await fetch(info.Location, { method: "GET" });
              let regionInventory = JSON.parse(pako.ungzip(await resp.arrayBuffer(), { to: "string" }));
              sessionStorage.setItem(cacheId, pako.deflate(JSON.stringify(regionInventory), { to: "string" }));
              result[i] = this.completeInventory(regionInventory, i);
            })
            .catch(err => {
              datadogLogs.logger.error("Cannot load AWS inventory " + i);
              error = err;
            })
        );
      });
      await Promise.all(loads);
      return { inventory: result, inventoryDate: date, error: error };
    });
  }

  setCurrentRessource(resource) {
    Controller.dispatch({ type: "SET_CURRENT_RESOURCE", resource });
  }

  onSET_FILTER(state, action) {
    let info = {};
    info[`selected${action.filterType}`] = action.filterValue;
    return {
      ...state,
      ...info
    };
  }

  setFilter(filterType, filterValue) {
    Controller.dispatch({ type: "SET_FILTER", filterType, filterValue });
  }
}
export { CloudInventoryController };

import { Divider, Drawer, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect, Provider } from "react-redux";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "semantic-ui-css/semantic.min.css";
import Account from "./users/Account";
import AmazonMachineImages from "./cloud/AmazonMachineImages";
import "./App.css";
import NuxeoAppBar from "./common/AppBar";
import CloudInventoryComponent from "./cloud/CloudInventoryComponent";
import CloudProjects from "./cloud/CloudProjects";
import { Controller } from "./controllers";
import Costs from "./Costs";
import DeployBeta from "./DeployBeta";
import Docs from "./offices/Docs";
import EventsComponent from "./EventsComponent";
import GroupDetails from "./users/GroupDetails";
import HealthTabs from "./security/HealthTabs";
import Home from "./common/Home";
import LoadingPanel from "./common/LoadingPanel";
import Menu from "./common/Menu";
import MFA from "./common/MFA";
import ObjectList from "./common/ObjectList";
import ProxiesSSL from "./cloud/ProxiesSSL";
import ReleasesMD from "./common/ReleasesMD";
import ScreenCastsComponent from "./offices/ScreenCastsComponent";
import SecurityTable from "./security/SecurityTable";
import SwaggerComponent from "./Swagger";
import Tasks from "./security/Tasks";
import Tokens from "./security/Tokens";
import ComplianceReports from "./security/ComplianceReports";
import UnitTestReports from "./security/UnitTestReports";
import UsersManage from "./users/UsersManage";
import UsersOnboarding from "./users/UsersOnboarding";
import Customers from "./cloud/Customers";

toast.configure();
const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    overflowY: "scroll"
  },
  flexHorizontal: {
    display: "flex",
    flexDirection: "row"
  }
});

const Policies = () => (
  <ObjectList
    title="Policies"
    headers={[
      { id: "name", numeric: false, disablePadding: false, label: "Name" },
      {
        id: "description",
        numeric: false,
        disablePadding: false,
        label: "Details"
      },
      {
        id: "published",
        numeric: false,
        disablePadding: false,
        label: "Published Date"
      }
    ]}
    data={[
      {
        name: "Access Control Policy",
        description: "Test for plop",
        published: "May 23th"
      }
    ]}
  />
);

const Metrics = () => <div />;

const Services = () => (
  <ObjectList
    title="Services"
    headers={[
      { id: "name", numeric: false, disablePadding: false, label: "Service" },
      {
        id: "contact",
        numeric: false,
        disablePadding: false,
        label: "Contact"
      },
      {
        id: "description",
        numeric: false,
        disablePadding: false,
        label: "Business Justification"
      },
      {
        id: "referent",
        numeric: false,
        disablePadding: false,
        label: "Nuxeo referent"
      }
    ]}
    data={[
      {
        name: "Datadog",
        contact: "test@datadog.com",
        description: "Test for plop",
        referent: "rcattiau@nuxeo.com"
      }
    ]}
  />
);

class MiniDrawer extends React.Component {
  state = {
    open: false,
    requiredMFA: false,
    onboarding: false
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  componentDidMount() {
    Controller.dispatch({
      type: "ROUTE_CHANGED",
      location: this.props.location,
      history: this.props.history
    });
  }

  onRouteChanged() {
    Controller.dispatch({
      type: "ROUTE_CHANGED",
      location: this.props.location
    });
  }

  render() {
    const { classes, theme, user, syncing, okta, ...other } = this.props;

    if (syncing && !user) {
      return <LoadingPanel async={syncing} label="Logging in" />;
    }

    if (okta) {
      return <LoadingPanel async={{ syncing: true }} label="Redirecting to Okta" />;
    }

    return (
      <div className={classes.root}>
        <NuxeoAppBar
          user={user}
          onClick={this.handleDrawerOpen}
          className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
          iconClassName={classNames(classes.menuButton, this.state.open && classes.hide)}
        />
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose)
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <Menu user={user} />
        </Drawer>
        <UsersOnboarding onboarding={this.state.onboarding} />
        <MFA requiredMFA={this.state.requiredMFA} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Route exact path="/" render={() => <Home user={this.props.user} />} />
          <Route path="/cloudprojects" component={CloudProjects} />
          <Route path="/customers" component={Customers} />
          <Route path="/policies" component={Policies} />
          <Route path="/metrics" component={Metrics} />
          <Route path="/services" component={Services} />
          <Route path="/groups/:id" component={GroupDetails} />
          <Route path="/amazon-machine-images" component={AmazonMachineImages} />
          <Route path="/security-automation" component={Tasks} />
          <Route path="/security-table" component={SecurityTable} />
          <Route path="/docs" component={Docs} />
          <Route path="/costs" component={Costs} />
          <Route path="/tokens" component={Tokens} />
          <Route path="/health" component={HealthTabs} />
          <Route path="/cloud-inventory" component={CloudInventoryComponent} />
          <Route path="/swagger" component={SwaggerComponent} />
          <Route path="/proxies-ssl" component={ProxiesSSL} />
          <Route path="/events" component={EventsComponent} />
          <Route path="/tests" component={UnitTestReports} />
          <Route path="/compliance-reports" component={ComplianceReports} />
          <Route path="/screencasts" component={ScreenCastsComponent} />
          <Route path="/users" render={() => <UsersManage currentUser={this.props.user} {...other} />} />
          <Route path="/releases-notes" component={ReleasesMD} />
          <Route path="/deploy-beta" component={DeployBeta} />
          <Route path="/me" render={() => <Account user={this.props.user} />} />
        </main>
      </div>
    );
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const Content = withStyles(styles, { withTheme: true })(
  withRouter(
    connect((state, ownProps) => {
      return {
        user: state.users.me,
        syncing: state.users._async ? state.users._async.LOGIN : true,
        okta: state.users.redirectToOkta
      };
    })(MiniDrawer)
  )
);

const App = props => (
  <Provider store={props.store}>
    <Router>
      <Content />
    </Router>
  </Provider>
);

export default App;

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ChevronIcon from "@material-ui/icons/ChevronRight";
import { withRouter } from "react-router-dom";
import { Button } from '@material-ui/core';

const styles = theme => ({
  root: {
    height: 380,
    padding: 20
  },
  addButton: {
    position: "relative",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  },
  breadcrumb: {
    marginLeft: 20,
    display: "flex"
  },
  breadcrumbIcon: {
    marginRight: 20
  },
  breadcrumbChevron: {
    paddingTop: 10
  }
});

const Breadcrumb = ({ classes, items, history }) => {
  return (
    <div className={classes.breadcrumb}>
      {items.slice(0, -1).map((item, i) => {
        const key = "breadcrumb_" + i;
        return (
          <div key={key} style={{ display: "flex" }}>
            <Button
              onClick={() => {
                history.push(item.path);
              }}
            >
              {item.title}
            </Button>
            <div className={classes.breadcrumbChevron}>
              <ChevronIcon />
            </div>
          </div>
        );
      })}
      {items.slice(-1).map(item => {
        return (
          <Button disabled key="lastOne">
            {item.title}
          </Button>
        );
      })}
    </div>
  );
}

export default withRouter(withStyles(styles)(Breadcrumb));

import React from "react";
import ObjectList from "../common/ObjectList";
import AddIcon from "@material-ui/icons/Add";
import { Switch, Route } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ProxySSLDetails from "./ProxySSLDetails";
import { Button } from "@material-ui/core";

const styles = theme => ({
  root: {
    height: 380,
    padding: 20,
    marginTop: 20
  },
  addButton: {
    position: "relative",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  }
});

const ProxiesSSLList = () => {
  return (
    <div id="proxyList">
      <ObjectList
        title=""
        headers={[
          {
            id: "domain",
            numeric: false,
            disablePadding: false,
            label: "Domain"
          },
          {
            id: "type",
            numeric: false,
            disablePadding: false,
            label: "Type"
          },
          {
            id: "description",
            numeric: false,
            disablePadding: false,
            label: "Description"
          }
        ]}
        storeData="$.proxy.proxies"
        routeTo="/proxies-ssl/{uuid}"
      />
    </div>
  );
};

const ProxiesSSL = ({ classes, history, location }) => {
  return (
    <div className={classes.root}>
      <div className={classes.addButton}>
        <Button
          onClick={() => {
            history.push("/proxies-ssl/new");
          }}
          variant="outlined"
          color="primary"
          disabled={location.pathname === "/proxies-ssl/new"}
          id="newProxy-button"
        >
          <AddIcon />
            Register new proxy
          </Button>
      </div>
      <Route exact path="/proxies-ssl" component={ProxiesSSLList} />
      <Switch>
        <Route path="/proxies-ssl/:id" component={ProxySSLDetails} />
      </Switch>
    </div>
  );
}

export default withStyles(styles)(ProxiesSSL);

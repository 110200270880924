import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import ObjectList from "../common/ObjectList";
import AddIcon from "@material-ui/icons/Add";
import { IconButton, TextField, Fab } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { DomainPattern } from "./ProxySSLDetails";
import { deepCopy } from "../common/DeepCopy";

const styles = theme => ({
  mainContainer: {
    padding: 20
  },
  textField: {
    width: 450
  },
  heading: {
    margin: 10,
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.primary
  },
  subHeading: {
    margin: 10,
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary
  },
  paddedContainer: {
    padding: 40
  },
  button: {
    marginTop: 5,
    marginLeft: 5
  }
});

function ActionAlias(actionFunction) {
  return {
    id: "delete",
    numeric: false,
    disablePadding: false,
    getCellWidget: n => {
      return (
        <IconButton
          onClick={() => {
            actionFunction(n);
          }}
        >
          <DeleteIcon />
        </IconButton>
      );
    }
  };
}

const ProxyAliases = ({ currentProxy, classes, onChange }) => {
  const [currentContent, setCurrentContent] = useState(currentProxy.aliases);
  const [unitEntryAlias, setUnitEntryAlias] = useState("");
  const [unitEntryAliasErrorText, setUnitEntryAliasErrorText] = useState("");

  useEffect(() => {
    setCurrentContent(deepCopy(currentProxy.aliases))
  }, [currentProxy]);

  const handleEntry = (event, inputProps) => {
    let value = event.target.value;
    if (value.match(DomainPattern)) {
      setUnitEntryAliasErrorText("");
    } else {
      setUnitEntryAliasErrorText("Invalid domain format");
    }
    setUnitEntryAlias(value);
  };

  const handleEntryValidated = () => {
    if (!unitEntryAlias.match(DomainPattern)) {
      return;
    }
    let oldAliasesRules = deepCopy(currentContent);
    let aliasToAdd = unitEntryAlias;
    oldAliasesRules.push(aliasToAdd);
    setCurrentContent(oldAliasesRules);
    if (onChange) {
      onChange({ value: oldAliasesRules });
    }
    setUnitEntryAlias("");
    setUnitEntryAliasErrorText("");
  };
  const deleteAlias = aliasToBeDeleted => {
    let newAliases = deepCopy(currentContent).filter(item => item !== aliasToBeDeleted);
    setCurrentContent(newAliases);
    if (onChange) {
      onChange({ value: newAliases });
    }
  };

  return (
    <div className={classes.mainContainer}>
      <ObjectList
        title="Aliases"
        noPaper
        onItemClicked={n => {
          console.log(n);
        }}
        headers={[
          {
            id: "alias",
            numeric: false,
            disablePadding: false,
            label: "Alias",
            getCellValue: n => {
              return <p>{n}</p>;
            }
          },
          ActionAlias(deleteAlias)
        ]}
        data={currentContent}
      />
      <div className={classes.paddedContainer}>
        <p className={classes.subHeading}>Add an alias to the set</p>
        <TextField
          label="Alias"
          value={unitEntryAlias}
          variant="outlined"
          onChange={(e, inputProps) => handleEntry(e, inputProps)}
          className={classes.textField}
          error={unitEntryAliasErrorText.length === 0 ? false : true}
          helperText={unitEntryAliasErrorText}
          id="proxy-newAlias"
        />
        <Fab size="small" color="primary" aria-label="add" className={classes.button}>
          <AddIcon onClick={handleEntryValidated} id="add-alias-button" />
        </Fab>
      </div>
    </div>
  );
}

export default withStyles(styles)(ProxyAliases);

import { withStyles } from "@material-ui/core/styles";
import React from "react";
import Flip from "react-reveal/Flip";
import LoadingPanel from "../common/LoadingPanel";
import SecurityTableElement from "./SecurityTableElement";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

const styles = theme => ({
  root: {
    padding: 20,
    marginTop: 20
  },
  columnContainer: {
    display: "flex",
    flexDirection: "row",
    maxWidth: 950,
    flexWrap: "wrap"
  },
  centerContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "20px"
  }
});

const SecurityPeriodicTable = ({ classes, history }) => {
  const async = useSelector(state => state.dashboard._async.SYNC_UI_CONFIGURATION);
  const table = useSelector(state => state.dashboard.UIConfiguration.securityTable || []);
  const handleDetails = element => {
    // prevent crash for white cells
    if (!element.acronym) {
      return;
    }
    history.push(`/security-table/${element.acronym.toLowerCase()}`);
  };
  return (
    <div>
      <Typography variant="h6" id="tableTitle">
        Security Table
        </Typography>
      <LoadingPanel async={async}>
        <div className={classes.centerContainer}>
          <div className={classes.columnContainer}>
            {table.map((element, i) => {
              return (
                <Flip key={i} left>
                  <SecurityTableElement element={element} onClick={() => handleDetails(element)} />
                </Flip>
              );
            })}
          </div>
        </div>
      </LoadingPanel>
    </div>
  );
}

export default withStyles(styles)(SecurityPeriodicTable);

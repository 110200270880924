import {
  DataSearch,
  DateRange,
  MultiList,
  ReactiveBase,
  ReactiveList,
  SelectedFilters
} from "@appbaseio/reactivesearch";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { Controller } from "./controllers";
import { Button } from '@material-ui/core';

const styles = theme => ({
  root: {
    minHeight: 380,
    padding: 20,
    marginTop: 20
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    padding: 10,
    alignItems: "center"
  },
  containerBaselined: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    padding: 10,
    alignItems: "baseline"
  },
  dashedBorder: {
    padding: 20
  }
});

const tableRow = {
  flexDirection: "row",
  display: "flex",
  width: "100%"
};

const timeColumn = {
  width: "10%",
  maxWidth: "100px",
  padding: 10
};

const actionColumn = {
  width: "10%",
  maxWidth: "100px",
  padding: 10
};

const descriptionColumn = {
  width: "100%",
  padding: 10
};

const typeColumn = {
  width: "10%",
  maxWidth: "100px",
  padding: 10
};

const EventDetails = (props) => {

  function getUserCloudTrailDescription(evt) {
    let { eventName, accountName, awsRegion, sourceIPAddress, userAgent } = evt;
    return `${eventName} on ${accountName} in ${awsRegion} from ${sourceIPAddress} with ${userAgent}`;
  }

  const { event } = props;

  return (
    <div key={event.uuid} style={tableRow}>
      <div style={timeColumn}>{new Date(event.timestamp * 1000).toLocaleString()}</div>
      <div style={typeColumn}>{event._type}</div>
      <div style={descriptionColumn}>{getUserCloudTrailDescription(event)}</div>
      <div style={actionColumn}>D</div>
    </div>
  );
}

const EventsPanel = (props) => {
  const { classes, user } = props;

  let defaultQuery = () => {
    let q = {
      query: {
        bool: {
          must_not: [
            {
              match: {
                eventName: "CreateLogStream"
              }
            },
            {
              match: {
                eventName: "PutObject"
              }
            }
          ],
          must: [
            {
              match: {
                "userIdentity.arn": user
              }
            },
            {
              bool: {
                should: [],
                minimum_should_match: 1
              }
            }
          ]
        }
      }
    };
    // Add more
    ["Delete", "Create", "Put", "Purge", "Console", "Access"].forEach(eventSubtype => {
      q.query.bool.must[1].bool.should.push({
        bool: {
          should: [
            {
              match: {
                eventSubtype
              }
            }
          ],
          minimum_should_match: 1
        }
      });
    });
    return q;
  };

  const onBeforeSend = props => {
    return {
      ...props,
      credentials: "include"
    };
  };

  const loadingPanel = "loading ...";
  return (
    <div>
      <div className={classes.addButton}>
        <Button
          onClick={() => {
            props.history.push("/event/rules");
          }}
          variant="outlined"
          color="primary"
        >
          <AddIcon />
            Manage alarm rules
          </Button>
      </div>
      <ReactiveBase
        app="logstash-*"
        url={Controller.get("events").getURL()}
        headers={{
          "X-CSRF-Token": Controller.getCSRFToken()
        }}
        transformRequest={onBeforeSend}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "40%",
              paddingLeft: 10,
              paddingRight: 10
            }}
          >
            <DataSearch style={{ marginTop: 20 }} componentId="eventSearch" dataField={["*"]} />
            <DateRange
              style={{ marginTop: 20 }}
              componentId="eventDate"
              dataField="eventTime"
              title="Event Time Range"
            />
            <MultiList
              style={{ marginTop: 20 }}
              size={25}
              showCheckbox={true}
              showCount={true}
              showSearch={true}
              sortBy="count"
              componentId="eventName"
              dataField="eventName.keyword"
              title="Events Name"
              defaultQuery={defaultQuery}
              showMissing={false}
              react={{
                and: ["eventDate", "eventSearch", "accountName", "eventRegion"]
              }}
              loader="Loading ..."
            />
            <MultiList
              style={{ marginTop: 20 }}
              size={10}
              showCheckbox={true}
              showCount={true}
              showSearch={true}
              sortBy="count"
              componentId="accountName"
              dataField="accountName.keyword"
              title="Account Name"
              defaultQuery={defaultQuery}
              showMissing={false}
              react={{
                and: ["eventDate", "eventName", "eventSearch", "eventRegion"]
              }}
              loader={loadingPanel}
            />
            <MultiList
              style={{ marginTop: 20 }}
              size={10}
              showCheckbox={true}
              showCount={true}
              showSearch={true}
              sortBy="count"
              componentId="awsRegion"
              dataField="awsRegion.keyword"
              title="Region"
              defaultQuery={defaultQuery}
              showMissing={false}
              react={{
                and: ["eventDate", "eventName", "eventSearch", "accountName"]
              }}
              loader={loadingPanel}
            />
          </div>
          <div style={{ paddingLeft: 10, paddingRight: 10, scroll: "y" }}>
            <div>Current Filters</div>
            <SelectedFilters />
            <div>Result</div>
            <div style={tableRow}>
              <div style={timeColumn}>Time</div>
              <div style={typeColumn}>Type</div>
              <div style={descriptionColumn}>Description</div>
              <div style={actionColumn}>Details</div>
            </div>
            <ReactiveList
              dataField="eventTime"
              sortBy="desc"
              componentId="SearchResult"
              loader="Loading ..."
              size={100}
              react={{
                and: ["eventDate", "eventName", "eventSearch", "accountName", "eventRegion"]
              }}
              defaultQuery={defaultQuery}
              renderItem={res => <EventDetails event={res} />}
            />
          </div>
        </div>
      </ReactiveBase>
    </div>
  );
}

export default withStyles(styles)(EventsPanel);

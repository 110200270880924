import { withStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import React from "react";
import { connect } from "react-redux";
import { DateTimeInput } from "semantic-ui-calendar-react";
import { Card } from "semantic-ui-react";
import { Controller } from "./controllers";
import ReactUserSelector from "./common/ReactUserSelector";
import { Button } from "@material-ui/core";

const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    width: "80%",
    maxHeight: 435
  },
  progress: {
    margin: 20
  },
  delegation: {
    width: "30%"
  }
});

function ListOfActions(props) {
  const { user, delegateValue, setState } = props;

  // Target date to offboard
  const [date, setDate] = React.useState("");
  let onDateTimeInputChange = (event, { name, value }) => {
    setDate(value);
  };

  // Users selected to receive emails of the offboarded user
  let onDelegatesInputChange = value => {
    setState({ delegateValue: value });
  };

  return (
    Controller.get("users").hasPermission("offboardEmployee") && (
      <Card key="offboarding">
        <Card.Content>
          <Card.Header>
            <h1>Offboarding</h1>
          </Card.Header>
          <br />
          Effective target date :
          <DateTimeInput
            name="dateTime"
            placeholder="Date Time"
            value={date}
            onChange={onDateTimeInputChange}
            startMode="day"
            minDate={new Date()}
            initialDate={new Date()}
            disableMinute
            dateTimeFormat="YYYY-MM-DD HH:00:00 Z"
          />
          <br />
          Delegate emails to :
          <ReactUserSelector
            onUpdate={onDelegatesInputChange}
            value={delegateValue}
            id="offboardee-delegate"
            isMulti
            excludeSystemUsers
            excludeGroups
            className="basic-multi-select"
            classNamePrefix="select"
            //onChange={onDelegatesInputChange}
          />
          <br />
          <Button
            disabled={!date}
            onClick={() => {
              Controller.get("users").offboardEmployee(user, {
                date,
                delegates: delegateValue.map(o => o.name)
              });
            }}
            variant="outlined"
            color="primary"
          >
            Validate offboarding
            <CancelIcon />
          </Button>
        </Card.Content>
      </Card>
    )
  );
}

class ActionButtons extends React.Component {
  constructor() {
    super();
    this.state = { delegateValue: [] };
  }

  async componentDidMount() {
    const { user, users } = this.props;
    let u = users.find(u => u.name === user.login);
    if (u && u.supervisor) {
      this.setState({
        delegateValue: [{ type: "user", name: u.supervisorLogin }]
      });
    }
  }

  render() {
    const { user, users } = this.props;
    return (
      <ListOfActions
        user={user}
        users={users}
        delegateValue={this.state.delegateValue}
        setState={this.setState.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(
  connect((state, ownProps) => {
    return {
      users: state.users.users
    };
  })(ActionButtons)
);

import React from "react";
import Runbooks from "./Runbooks";
import RunbookDetails from "./RunbookDetails";
import { Switch, Route } from "react-router-dom";

const RunbooksComponent = (props) => {
  return (
    <div>
      <Route exact path="/security-automation" component={Runbooks} />
      <Switch>
        <Route
          path="/security-automation/:id(.+)"
          render={props => <RunbookDetails {...props} />}
        />
      </Switch>
    </div>
  );
}

export default RunbooksComponent;

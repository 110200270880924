import React from "react";
import ReactDOM from "react-dom";
import * as Redux from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as ReduxThunk from "redux-thunk";
import App from "./App";
import { Controller, ControllerInitializer } from "./controllers/";
import "./index.css";
import registerServiceWorker from "./registerServiceWorker";

ControllerInitializer.initialize();

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["notifications"]
};
let rootReducer = Controller.getReducers();
rootReducer = persistReducer(persistConfig, rootReducer);
// Configure routes like normal
let composed;
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  composed = Redux.compose(
    Redux.applyMiddleware(ReduxThunk.default),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  composed = Redux.compose(Redux.applyMiddleware(ReduxThunk.default));
}
let store = Redux.createStore(rootReducer, composed);
Controller.setStore(store);
let match = /https:\/\/(.*)\.dashboard\.offices\.nuxeo\.com/g.exec(window.location.href);
if (match) {
  // For PR previews
  let matchDev = /https:\/\/([\da-f]+)?\.?dev\.dashboard\.offices\.nuxeo\.com/g.exec(window.location.href);
  if (matchDev) {
    let branch = matchDev[1] ? `pr-${matchDev[1]}` : "master";
    Controller.setEndpoint(`https://dev.api.offices.nuxeo.com/${branch}`);
  } else {
    Controller.setEndpoint(`https://${match[1]}.api.offices.nuxeo.com`);
  }
} else if (window.location.href.indexOf("localhost") >= 0) {
  Controller.setEndpoint("http://localhost:18080");
} else {
  Controller.setEndpoint("https://api.offices.nuxeo.com");
}
Controller.get("users").fetch();
ReactDOM.render(<App store={Controller.getStore()} />, document.getElementById("root"));
registerServiceWorker();

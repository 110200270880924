import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Controller } from "../controllers";

import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import HelpdeskIcon from "@material-ui/icons/PhonelinkOff";

const theme = createMuiTheme({
  overrides: {
    MuiListItemIcon: {
      root: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "24px"
      }
    },
    MuiListItem: {
      root: {
        //backgroundColor: "white",
        color: "rgba(0, 0, 0, 0.54)",
        "&$selected": {
          backgroundColor: "#3f51b5",
          color: "white"
        }
      }
    }
  }
});

const MenuItem = ({ selected, onClick, submenu, style, title, iconComponent, history, route, icon }) => {

  let iconItem;
  if (icon) {
    iconItem = React.createElement(icon);
  } else if (iconComponent) {
    iconItem = iconComponent;
  }

  let normal = (
    <div wd="menuItem">
      <ListItem
        style={{
          paddingLeft: submenu ? 25 : 20,
          ...style
        }}
        button
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            history.push(route);
          }
        }}
        selected={selected}
      >
        <ListItemIcon style={selected ? { color: "white" } : {}}>{iconItem}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    </div>
  );

  if (selected && submenu) {
    return <MuiThemeProvider theme={theme}>{normal}</MuiThemeProvider>;
  }

  return normal;
}


MenuItem.propTypes = {
  route: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  iconComponent: PropTypes.element
};

const RouteMenuItem = withRouter(MenuItem);

const Menu = ({ user, location }) => {
  const [submenu, setSubmenu] = useState("");

  if (!user) {
    return <List />;
  }

  const menus = Controller.get("users").getMenu();
  const menusItems = [];

  for (let i in menus) {
    let menuSelected = false;
    let selectedItem;
    menus[i].items.forEach((perm, id) => {
      if (location.pathname.startsWith(perm.route)) {
        menuSelected = true;
        selectedItem = id;
      }
    });
    let style = {};
    if (menuSelected) {
      style = { fontWeight: "bold", borderTop: "1px solid #3f51b5" };
    }
    menusItems.push(
      <MenuItem
        key={i}
        title={menus[i].label}
        icon={menus[i].icon}
        style={style}
        onClick={() => {
          setSubmenu(i);
        }}
      />
    );
    if (menuSelected || submenu === i) {
      let submenuItems = [];
      menus[i].items.forEach((permission, id) => {
        submenuItems.push(
          <RouteMenuItem
            title={permission.title}
            route={permission.route}
            icon={permission.icon}
            key={`submenu-item-${id}`}
            submenu={true}
            selected={selectedItem === id}
          />
        );
      });
      menusItems.push(
        <div
          key={`submenu-${i}`}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            backgroundColor: "#3f51b510",
            borderBottom: "1px solid #3f51b5"
          }}
        >
          {submenuItems}
        </div>
      );
    }
  }

  return (
    <List
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
    >
      <div>{menusItems}</div>
      <div style={{ paddingTop: "30px" }}>
        <Typography style={{ paddingLeft: "calc(50% - 16px)" }} variant="caption" align="center">
          Links
          </Typography>
        <RouteMenuItem
          style={{ position: "absolute", bottom: "80px" }}
          title="Kibana"
          route="/kibana"
          iconComponent={<img style={{ width: "24px", height: "24px" }} alt="Kibana" src="/logos/kibana.svg" />}
        />
        <RouteMenuItem
          style={{ position: "absolute", bottom: "20px" }}
          title="IT Helpdesk"
          route="/helpdesk"
          icon={HelpdeskIcon}
        />
      </div>
    </List>
  );
}

export default withRouter(Menu);

import { Paper, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import ESStatusPanel from "./ESStatusPanel";
import Health from "./Health";
import HealthTeam from "./HealthTeam";

export default function CenteredTabs() {
  const [value, setValue] = useState("es");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let panel;
  if (value === "es") {
    panel = <ESStatusPanel />;
  } else if (value === "api") {
    panel = <Health />;
  } else if (value === "okta") {
    panel = <HealthTeam />;
  }
  return (
    <Paper style={{ marginTop: "20px" }}>
      <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
        <Tab label="Overall" value="api" id="overall-tab" />
        <Tab label="ElasticSearch" value="es" id="es-tab" />
        <Tab label="Okta" value="okta" id="okta-tab" />
      </Tabs>
      {panel}
    </Paper>
  );
}

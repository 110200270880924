import { withStyles } from "@material-ui/core/styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
import dateFormat from "dateformat";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoadingPanel from "./LoadingPanel";
import { Tooltip } from "@material-ui/core";

const styles = {
  notificationButton: {
    backgroundColor: "transparent",
    margin: 20,
    "&:hover": {
      color: "white"
    }
  },
  tooltipText: {
    fontSize: "1.5em",
    margin: 10
  },
  customWidth: {
    maxWidth: 500
  }
};

const WhatsNew = ({ classes }) => {
  const releases = useSelector(state => state.dashboard.dashboardReleases || []);
  const async = useSelector(state => state.dashboard._async.SYNC_RELEASES || {});

  return (
    <div>
      <Tooltip
        disableFocusListener
        disableTouchListener
        placement="top-start"
        title={releases
          .filter(a => a.status !== "DEPLOYING")
          .sort((a, b) => (a.date < b.date ? 1 : -1))
          .slice(0, 3)
          .map((release, i) => {
            return (
              <LoadingPanel async={async} key={i}>
                <div className={classes.tooltipText}>
                  {dateFormat(new Date(release.date), "yyyy-mm-dd")}: dashboard {release.tag}
                </div>
              </LoadingPanel>
            );
          })}
        classes={{ tooltip: classes.customWidth }}
      >
        <Link to="/releases-notes">
          <NotificationsIcon className={classes.notificationButton} />
        </Link>
      </Tooltip>
    </div>
  );
}

export default withStyles(styles)(WhatsNew);
import React from "react";
import { useSelector } from "react-redux";
import LoadingPanel from "../common/LoadingPanel";
import ObjectList from "../common/ObjectList";

const Metrics = () => {

  let perimeters = {
    S3Queue: "Incoming datadog log files",
    TasksQueue: "Tasks",
    SlackQueue: "Slack messages",
    LaunchQueue: "Running tasks with fargate",
    LaunchLowPriorityQueue: "Running tasks with fargate 'big gun' power cpu and memory"
  };

  let headers = [
    { id: "service", label: "Service" },
    { id: "size", label: "Current size" },
    {
      id: "perimeter",
      label: "Perimeter",
      getCellWidget: n => {
        return <div>{perimeters[n.service]}</div>;
      }
    },
    {
      id: "ts",
      label: "Last check",
      getCellWidget: n => {
        return <div>{new Date(n.ts).toLocaleString()}</div>;
      }
    }
  ];

  return (
    <ObjectList
      title="Monitored services"
      headers={headers}
      storeData="$.health.metrics"
      defaultOrderBy="Service"
      defaultOrder="asc"
    />
  );
}

const Health = () => {
  const async = useSelector(state => state.health._async.SYNC_HEALTH || {});
  const health = useSelector(state => state.health || []);
  return (
    <LoadingPanel async={async}>
      {(health && <Metrics />) || <div>No metrics found.</div>}
    </LoadingPanel>
  );
}

export default Health;

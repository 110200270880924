import { Button, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { PanTool } from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import { Controller } from "../controllers";
import LoadingPanel from "../common/LoadingPanel";
import ObjectList from "../common/ObjectList";
import CachedIcon from "@material-ui/icons/Cached";

const styles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 20
  }
});

const TasksScheduled = ({ classes }) => {

  const async = useSelector(state => state.runbooks._async.SYNC_SCHEDULED_TASKS || {});

  const onRefresh = e => {
    Controller.get("runbooks").refreshScheduledTasks();
  };

  function cancel(n) {
    Controller.get("runbooks").cancelScheduledTask(n);
  }

  let headers = [
    { id: "name", label: "Task name" },
    { id: "category", label: "Category" },
    { id: "command", label: "Class" },
    {
      id: "method",
      label: "Method",
      getCellWidget: n => {
        return <div>{n.args[0]}</div>;
      }
    },
    {
      id: "args",
      label: "Arguments",
      getCellWidget: n => {
        return <div>{(n.args || []).slice(1).join(", ")}</div>;
      }
    },
    {
      id: "date",
      label: "Target date",
      getCellWidget: n => {
        return <div>{new Date(n.date).toLocaleString()}</div>;
      }
    },
    {
      id: "Actions",
      numeric: false,
      disablePadding: false,
      getCellWidget: n => {
        return (
          <div key="actions">
            <Tooltip title="Cancel">
              <Button
                onClick={() => {
                  cancel(n);
                }}
              >
                <PanTool color="error" />
              </Button>
            </Tooltip>
          </div>
        );
      },
      label: "Actions"
    }
  ];
  const cronHeaders = [
    { id: "category", label: "Category" },
    { id: "command", label: "Command" },
    { id: "arguments", label: "Arguments" },
    {
      id: "cron",
      label: "Recurrence",
      getCellWidget: n => {
        return (
          <div>
            {n.cron}
            <br />
            {n.cronPhrase}
          </div>
        );
      }
    }
  ];
  return (
    <LoadingPanel async={async}>
      <div className={classes.root}>
        <Button color="primary" onClick={onRefresh}>
          <CachedIcon />
            Refresh
          </Button>
      </div>
      <ObjectList
        title="Scheduled tasks"
        headers={headers}
        storeData="$.runbooks.scheduled"
        defaultOrderBy="date"
        defaultOrder="asc"
      />
      <ObjectList
        title="Recurring tasks"
        headers={cronHeaders}
        storeData="$.runbooks.crontab"
        defaultOrderBy="date"
        defaultOrder="asc"
      />
    </LoadingPanel>
  );
}

export default withStyles(styles)(TasksScheduled);

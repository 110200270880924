import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';

const styles = theme => ({
  errorContainer: {
    padding: 30
  },
  errorTypo: {
    color: "#999",
    marginTop: 20
  }
});

const ErrorPanel = ({ error, classes }) => {
  return (
    <div className={classes.errorContainer}>
      {error.code === 404 ? (
        <Typography className={classes.errorTypo}>
          Seems the target ressource does not exist
        </Typography>
      ) : null}
      {!error.code ? (
        <Typography className={classes.errorTypo}>
          An unknown error occured
        </Typography>
      ) : (
          <Typography className={classes.errorTypo}>
            An error occured : {error.code}
          </Typography>
        )}
    </div>
  );
}

export default withStyles(styles)(ErrorPanel);

import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { Controller } from "./controllers";

const SwaggerComponent = () => {
  let uri = Controller.endpoint + "/swagger.json";
  return (
    <div>
      Welcome to Swagger
      <SwaggerUI
        url={uri}
        requestInterceptor={request => {
          request.credentials = "include";
          return request;
        }}
      />
    </div>
  );
}

export default SwaggerComponent;

import { withStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import { Controller } from "../controllers";
import LoadingPanel from "../common/LoadingPanel";
import SearchIcon from "@material-ui/icons/Search";
import memoize from "memoize-one";

import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  IconButton,
  Paper,
  Switch,
  Typography,
  TextField
} from "@material-ui/core";

const styles = theme => ({
  root: {
    padding: 20,
    marginTop: 20
  },
  container: {
    padding: 20
  },
  markDownContent: {
    padding: "30px"
  },
  mainButton: {
    position: "relative",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  }
});

const Doc = ({ classes, doc, id, onSelect }) => {
  let handleDownload = () => {
    Controller.get("dashboard").downloadDoc(doc);
  };
  let content = doc.content; //.replace(/<br ?\/>/g, "\n");
  return (
    <ExpansionPanel key={id}>
      <ExpansionPanelSummary style={{ padding: 0 }} id={id}>
        <div style={{ display: "none" }}>
          <Switch
            classes={{
              switchBase: classes.colorSwitchBase,
              checked: classes.colorChecked,
              bar: classes.colorBar
            }}
            onChange={onSelect}
            checked={doc.selected}
          />
        </div>
        <div style={{ flexGrow: 1, paddingLeft: "20px" }}>
          <h3>{doc.title}</h3>
          {doc.Id} - {doc.Date}
        </div>
        <div>
          <IconButton onClick={handleDownload}>
            <GetAppIcon />
          </IconButton>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.container} id={`${id}_content`}>
        <ReactMarkdown source={content} className={classes.markDownContent} escapeHtml={false} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

const Docs = ({ classes }) => {
  const [filter, setFilter] = useState("");
  const async = useSelector(state => state.dashboard._async.SYNC_DOCS || {});
  const docs = useSelector(state => state.dashboard.docs || {});

  const filterFct = memoize((docs, filterText) =>
    docs.filter(doc => {
      if (filterText.length === 0) {
        return true;
      }
      return doc.title.indexOf(filterText) >= 0 || doc.content.indexOf(filterText) >= 0;
    })
  );

  let docsArray = [];
  for (let i in docs) {
    for (let j in docs[i]) {
      docs[i][j].id = `${i}_${j}`;
      docsArray.push(docs[i][j]);
    }
  }

  let onSelectAll = () => { };

  let onChange = doc => {
    return () => {
      doc.selected = !doc.selected;
    };
  };

  let onFilter = evt => {
    setFilter(evt.target.value);
  };

  const filteredDocs = filterFct(docsArray || [], filter);

  return (
    <div>
      <div className={classes.mainButton} style={{ display: "none" }}>
        <Button
          onClick={() => {
            alert("Would download packages with all docs selected");
          }}
          variant="outlined"
          color="primary"
          id="newproject-button"
        >
          <GetAppIcon />
            Download Package
          </Button>
        <Switch
          classes={{
            switchBase: classes.colorSwitchBase,
            checked: classes.colorChecked,
            bar: classes.colorBar
          }}
          onChange={onSelectAll}
          checked={false}
        />
      </div>
      <Paper className={classes.root}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" style={{ marginBottom: "20px" }}>
            Security Published Documents
            </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField id="search-document-input" label="Search" value={filter} onChange={onFilter} />
            <SearchIcon />
          </div>
        </div>
        <LoadingPanel async={async}>
          {filteredDocs
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(doc => (
              <Doc onSelect={onChange(doc)} doc={doc} id={doc.id} classes={classes} key={doc.id} />
            ))}
        </LoadingPanel>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(Docs);

import { Controller } from "./Controller";
import { UserController } from "./UserController";

class ScreenCastingController extends Controller {
  constructor() {
    super("screencasts", { screens: [], current: {} });
    this.registerRoute({ path: "/screencasts/:id", strict: false, exact: true }, this._fetchScreenRoute);
    this.registerRoute({ path: "/screencasts", strict: false, exact: true }, this.syncScreencasts);
  }
  indexObjectToArray(idxObj) {
    let array = [];
    for (let i in idxObj) {
      if (typeof idxObj[i] !== "object") continue;
      array.push({ ...idxObj[i], uuid: i });
    }
    return array;
  }
  async syncScreencasts() {
    await Controller.get("users").waitInit();
    if (Controller.get<UserController>("users").hasPermission("screencast")) {
      this.asyncAction("SYNC_SCREENCASTS", async (dispatch, getState) => {
        let screencasts = this.indexObjectToArray(await this.ajax("/screencasts/index"));
        return {
          screens: screencasts
        };
      });
    }
  }
  _fetchScreenRoute(params) {
    this.get(params.id);
  }

  get(uuid) {
    this.asyncAction("GET_SCREENCAST", async (dispatch, getState) => {
      await Controller.get("users").waitInit();
      if (uuid === "new") {
        return {
          current: {
            uuid: "",
            page: "",
            timing: 0,
            offices: []
          }
        };
      } else {
        let current = await this.ajax(`/screencasts/${uuid}`, "GET");
        return {
          current
        };
      }
    });
  }

  addScreencast(screencast, callback) {
    this.asyncAction(
      "ADD_SCREENCAST",
      async (dispatch, getState) => {
        let screens = this.getLocalState().screens.slice(0);
        screens.push(
          await this.ajax("/screencasts", "POST", {
            ...screencast
          })
        );
        return { screens };
      },
      callback
    );
  }

  updateScreencast(screencast, callback) {
    this.asyncAction(
      "UPDATE_SCREENCAST",
      async (dispatch, getState) => {
        let screens = this.getLocalState().screens.slice(0);
        screens.push(
          await this.ajax(`/screencasts/${screencast.uuid}`, "PATCH", {
            ...screencast
          })
        );
        return { screens };
      },
      callback
    );
  }

  deleteScreencast(screencast, callback) {
    this.asyncAction(
      "DELETE_SCREENCAST",
      async (dispatch, getState) => {
        let screens = this.getLocalState().screens.slice(0);
        await this.ajax(`/screencasts/${screencast.uuid}`, "DELETE");
        screens = screens.filter(screen => screen.uuid !== screencast.uuid);
        return { screens };
      },
      callback
    );
  }
}

export { ScreenCastingController };

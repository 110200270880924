import { Controller } from "./Controller";

class CloudProjectsController extends Controller {
  history: any;
  location: any;
  constructor() {
    super("cloudprojects", { projects: [], current: {} });
    this.registerRoute({ path: "/customers", strict: false, exact: true }, this._fetchCustomers);
    this.registerRoute({ path: "/cloudprojects/:id", strict: false, exact: true }, this._fetchProjectRoute);
    this.registerRoute(
      { path: "/cloudprojects/:id/:env/container/:containerID", strict: false, exact: true },
      this._fetchProjectRoute
    );
  }

  async handleSyncAction(action) {
    if (action.type === "UPDATE") {
      await this.ajax("/tasks/" + action.id, "PATCH", action.params);
      return;
    }
  }

  getURL(url) {
    return Controller.endpoint + "/cloudprojects/" + url;
  }

  getLogs(project, env, index, state: any = undefined, format: string = "CSV", callback = () => {}) {
    let { customColumns, eventSearch, timeRange, exportLimit, logformat } = state;
    this.asyncAction("CSV_CLOUDPROJECT", async (dispatch, getState) => {
      // search query
      let must = [];
      if (eventSearch) {
        must.push([{ query_string: { query: eventSearch } }]);
      }

      // Time range
      if (timeRange.indexOf(",") > -1) {
        let [gte, lte] = timeRange.split(",");
        let range = {
          range: {
            timestamp: {
              gte: gte ? new Date(gte.replace(/ /g, "T") + "Z").getTime() : 0,
              lte: lte ? new Date(lte.replace(/ /g, "T") + "Z").getTime() : Date.now()
            }
          }
        };
        must.push(range);
      }

      let bd = { bool: { must_not: [{ match: { type: "bd" } }, { match: { type: "op" } }] } };
      must.push(bd);

      let body = {
        query: { bool: { must } },
        size: Number(exportLimit),
        _source: { includes: ["*"], excludes: [] },
        from: 0,
        sort: [{ timestamp: { order: "desc" } }]
      };

      let {
        url
      } = await this.ajax(
        `/cloudprojects/${project}/${env}/logsExport/${index}/_msearch?format=${format}&customColumns=${escape(
          JSON.stringify(customColumns)
        )}&logformat=${escape(logformat)}`,
        "POST",
        `{"preference":"SearchResult"}\n${JSON.stringify(body)}\n`,
        { raw: true, headers: { "content-type": "application/x-ndjson" } }
      );
      window.location = url;
      callback();
    });
  }

  onCSV_CLOUDPROJECT(state, action) {
    return {
      ...state,
      csvLoader: action.csvLoader
    };
  }
  init() {
    super.init();
    return false;
  }

  create(project) {
    this.asyncAction("CREATE_CLOUDPROJECT", async (dispatch, getState) => {
      let result: any = await this.ajax("/cloudprojects", "POST", project);
      // Should reroute clean
      let projects = this.getLocalState().projects.slice(0);
      projects.push(result);
      this.history.push("/cloudprojects/" + project.uuid);
      return { current: result, projects };
    });
  }

  update(uuid, updates, callback) {
    this.asyncAction(
      "UPDATE_CLOUDPROJECT",
      async (dispatch, getState) => {
        let project = await this.ajax("/cloudprojects/" + uuid + "/update", "PATCH", updates);
        let projects = this.getLocalState().projects.map(proj => {
          if (proj.uuid === uuid) {
            return project;
          } else {
            return proj;
          }
        });
        return { current: project, projects };
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  }

  get(uuid) {
    this.asyncAction("GET_CLOUDPROJECT", async (dispatch, getState) => {
      await Controller.get("users").waitInit();
      let project: any = await this.ajax(`/cloudprojects/${uuid}`);
      return { current: project };
    });
    this.asyncAction("GET_CLOUDPROJECT_COST", async (dispatch, getState) => {
      await Controller.get("users").waitInit();
      if (!this.hasPermission("cloudProjectsCosts")) {
        return;
      }
      let projectCosts = await this.ajax(`/cloudprojects/${uuid}/costs`);
      return { currentCost: projectCosts };
    });
    this.asyncAction("GET_CLOUDPROJECT_SERVERS", async (dispatch, getState) => {
      await Controller.get("users").waitInit();
      let projectServers = await this.ajax(`/cloudprojects/${uuid}/servers`);
      return { currentServers: projectServers };
    });
  }

  onROUTE_CHANGED(state, action) {
    this.location = action.location;
    if (action.history) {
      this.history = action.history;
    }
    return state;
  }

  _fetchCustomers() {
    this.asyncAction(
      "GET_CUSTOMERS",
      async (dispatch, getState) => {
        let services: any = await this.ajax("/dashboard/tagsFromStackId");
        let map = {};
        let versions = {};
        let regions = {};
        for (let i in services) {
          if (typeof services[i] !== "object") continue;
          if (services[i]["billing-category"] !== "customers") continue;
          services[i].customer = services[i]["billing-subcategory"];
          if (!map[services[i].customer]) {
            map[services[i].customer] = {};
          }
          services[i]["env"] = services[i]["env"] || services[i]["stack-identifier"];
          versions[services[i]["infrastructure-version"]] = versions[services[i]["infrastructure-version"]] || 0;
          versions[services[i]["infrastructure-version"]]++;
          regions[services[i].region] = regions[services[i].region] || 0;
          regions[services[i].region]++;
          map[services[i].customer].customer = services[i].customer;
          map[services[i].customer].region = services[i]["region"];
          map[services[i].customer][services[i].environment] =
            services[i]["infrastructure-version"] + " " + services[i]["env"];
          map[services[i].customer][services[i].environment + "Details"] =
            map[services[i].customer][services[i].environment + "Details"] || [];
          map[services[i].customer][services[i].environment + "Details"].push(services[i]);
        }

        return {
          customers: Object.keys(map).map(c => map[c]),
          customersRegions: Object.keys(regions).map(r => ({ id: r, label: r, value: regions[r] })),
          customersVersions: Object.keys(versions).map(r => ({ id: r, label: r, value: versions[r] }))
        };
      },
      () => {
        this.setInitialized();
      }
    );
  }

  _fetchProjectRoute(params) {
    if (params.id !== "new") {
      this.get(params.id);
    }
  }

  afterLOGIN_SUCCESS() {
    this.asyncAction(
      "GET_DEFAULT_SERVICES",
      async (dispatch, getState) => {
        let services: any = await this.ajax("/dashboard/server_services");
        return { defaultServices: services };
      },
      () => {
        this.setInitialized();
      }
    );
    this.asyncAction("SYNC_PROJECTS", async (dispatch, getState) => {
      let projects: any = await this.ajax("/cloudprojects/index");
      projects.forEach(project => {
        project.link = "/cloudprojects/" + project.uuid;
      });
      await this.waitInit();
      return { projects };
    });
  }

  getDefaultConfig(service, type, override) {
    let defaultServices = this.getLocalState().defaultServices;
    let defaultService = defaultServices.services[service] || {};
    let defaultEnv = {};
    if (defaultServices.envs[type]) {
      defaultEnv = defaultServices.envs[type].services[service];
    }
    let result = {
      ...defaultService,
      ...defaultEnv
    };
    result.strict = result.strict || {};
    if (this.hasPermission("cloudProjectsOverride") && override) {
      result.strict = {};
    }
    return result;
  }

  deleteContainer(cloudProject, env, container, callback) {
    this.asyncAction(
      "DELETE_CONTAINER",
      async (dispatch, getState) => {
        let containers = { ...cloudProject.envs[env].services.docker.containers };
        delete containers[container];
        cloudProject.envs[env].services.docker.containers = containers;
        await this.ajax("/cloudprojects/" + cloudProject.uuid + "/update", "PATCH", cloudProject);
        return { current: cloudProject };
      },
      callback
    );
  }
}
export { CloudProjectsController };

import React from "react";
import ObjectList from "../common/ObjectList";
import { Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ResponsivePie } from "@nivo/pie";
import { useSelector } from "react-redux";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  }
});

export default withStyles(styles)(function ({ classes }) {
  const regions = useSelector(state => state.cloudprojects.customersRegions || []);
  const versions = useSelector(state => state.cloudprojects.customersVersions || []);
  return (
    <Paper className={classes.root}>
      <div
        style={{
          width: "100%",
          height: 200,
          flex: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around"
        }}
      >
        <div style={{ width: 200, height: 200 }}>
          <div>Per Regions</div>
          <ResponsivePie
            data={regions}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            width={200}
            height={200}
            colors={{ scheme: "nivo" }}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            enableRadialLabels={false}
          />
        </div>
        <div style={{ width: 200, height: 200 }}>
          <div>Per Versions</div>
          <ResponsivePie
            data={versions}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            width={200}
            height={200}
            colors={{ scheme: "nivo" }}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            enableRadialLabels={false}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
      </div>
      <ObjectList
        noPaper
        title="Customers"
        orderBy="customer"
        order="desc"
        headers={[
          {
            id: "customer",
            numeric: false,
            disablePadding: false,
            label: "Name"
          },
          {
            id: "region",
            numeric: false,
            disablePadding: false,
            label: "Region"
          },
          {
            id: "preprod",
            numeric: false,
            disablePadding: false,
            label: "Preprod"
          },
          {
            id: "prod",
            numeric: false,
            disablePadding: false,
            label: "Production"
          },
          {
            id: "dev",
            numeric: false,
            disablePadding: false,
            label: "Development"
          }
        ]}
        storeData="$.cloudprojects.customers"
      />
    </Paper>
  );
});

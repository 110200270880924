import { Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import LoadingPanel from "../common/LoadingPanel";
import ObjectList from "../common/ObjectList";
import { useSelector } from "react-redux";
import AddNewButton from "../common/AddNewButton";
import { Dropdown } from "semantic-ui-react";

const styles = theme => ({
  root: {
    padding: 20,
    marginTop: 20
  },
  formControl: {
    margin: 10,
    minWidth: 120
  },
  flexHorizontal: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    justifyContent: "space-between",
    flexWrap: "wrap",
    padding: 20
  },
  addButton: {
    position: "relative",
    flexDirection: "row-reverse",
    display: "flex"
  }
});

const Screencasts = props => {
  const { classes } = props;
  const selectedOffices = useState("");
  const [filteredList, setFilteredList] = useState(undefined);
  const offices = useSelector(state => state.dashboard.UIConfiguration.offices || []);
  const screens = useSelector(state => state.screencasts.screens || []);
  const async = useSelector(state => state.screencasts._async.SYNC_SCREENCAST);

  let handleSelectedOffices = (evt, { value }) => {
    setFilteredList(value === "" ? screens : screens.filter(screen => screen.offices.includes(value)));
    selectedOffices[1](value);
  };

  let onDetails = () => {
    return evt => {
      props.history.push(`/screencasts/${evt.uuid}`);
    };
  };

  return (
    <div id="screencast-list-container">
      <LoadingPanel async={async}>
        <AddNewButton {...props} label="ADD SCREENCAST" />
        <Paper className={classes.root}>
          <div className={classes.flexHorizontal}>
            <Typography variant="h6">Screenly Screens</Typography>
            <Dropdown
              className={classes.dropdown}
              placeholder="Filter office"
              selection
              options={offices.map(office => {
                return {
                  text: office.name,
                  value: office.name
                };
              })}
              onChange={handleSelectedOffices}
              clearable
            />
          </div>
          <ObjectList
            title=""
            noToolBar={true}
            noPaper
            headers={[
              {
                id: "uuid",
                numeric: false,
                disablePadding: false,
                label: "Uuid"
              },
              {
                id: "page",
                numeric: false,
                disablePadding: false,
                label: "Page"
              },
              {
                id: "timing",
                numeric: false,
                disablePadding: false,
                label: "Timing"
              },
              {
                id: "offices",
                numeric: false,
                disablePadding: false,
                label: "Offices",
                getCellValue: n => {
                  return n.offices.map((office, i) => {
                    return <div key={i}>{office}</div>;
                  });
                }
              }
            ]}
            onItemDoubleClicked={onDetails()}
            data={filteredList || screens}
          />
        </Paper>
      </LoadingPanel>
    </div>
  );
};

export default withStyles(styles)(Screencasts);

import { withStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import SearchIcon from "@material-ui/icons/Search";
import classNames from "classnames";
import * as jsonpath from "jsonpath";
import memoize from "memoize-one";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from "@material-ui/core";

/*
 * Comparison function to sort two rows A and B on the field orderBy
 * @param {Object} a row A
 * @param {Object} b row B
 * @param {Object} orderBy Field to use to compare
 * @return Number representing the difference of value
 */
function desc(a, b, orderBy) {
  if (typeof a[orderBy] === "string" && typeof b[orderBy] === "string") {
    return a[orderBy].localeCompare(b[orderBy]);
  }
  if ((b[orderBy] || 0) < (a[orderBy] || 0)) {
    return -1;
  }
  if ((b[orderBy] || 0) > (a[orderBy] || 0)) {
    return 1;
  }
  return 0;
}

function getSorting(order, orderBy) {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class ObjectHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, headers } = this.props;
    return (
      <TableHead>
        <TableRow>
          {headers.map(row => {
            return (
              <TableCell
                key={row.id}
                align={row.numeric ? "center" : "inherit"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip title="Sort" placement={row.numeric ? "bottom-end" : "bottom-start"} enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

ObjectHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto",
    width: "100%"
  }
});

let EnhancedTableToolbar = props => {
  const { filter, classes, onFilter, title } = props;

  return (
    <Toolbar className={classNames(classes.root)}>
      <div className={classes.title}>
        <Grid container direction="row" alignItems="center" justify="space-between">
          <Grid item>
            <Typography variant="h6" id="tableTitle">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <div>
              <Grid container spacing={8} alignItems="flex-end">
                <Grid item>
                  <TextField id="input-with-icon-grid" label="Search" value={filter} onChange={onFilter} />
                </Grid>
                <Grid item>
                  <SearchIcon />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions} />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: "auto"
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
});

class ObjectTable extends React.Component {
  state = {
    order: this.props.order || "asc",
    orderBy: this.props.orderBy || "",
    selected: [],
    filter: "",
    data: this.props.data || [],
    page: 0,
    rowsPerPage: 100
  };
  filter = memoize((list, filterText) =>
    list.filter(obj => {
      for (let k in obj) {
        if (typeof obj[k] === "string") {
          if (obj[k].toLowerCase().indexOf(filterText) >= 0) {
            return true;
          }
        }
      }
      return false;
    })
  );

  handleRequestSort = (event, property, filterField, filterOrder) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    if (filterField && filterField !== "") {
      order = filterOrder;
    }

    this.setState({ order, orderBy });
  };

  handleRequestSortbyProps = (property, filterOrder) => {
    if (property !== "" && property !== undefined && filterOrder !== "" && filterOrder !== undefined) {
      const orderBy = property;
      let order = filterOrder;
      this.setState({ order, orderBy });
    }
  };

  handleFilter = event => {
    let newValue = event.target.value.toLowerCase();
    this.setState(prevState => {
      let update = {};
      update.data = this.props.data.filter(obj => {
        for (let k in obj) {
          if (typeof obj[k] === "string") {
            if (obj[k].toLowerCase().indexOf(newValue) >= 0) {
              return true;
            }
          }
        }
        return false;
      });
      if (update.data.length === 0) {
        return {};
      }
      update.filter = newValue;
      return update;
    });
  };

  handleDoubleClick = (event, n) => {
    if (this.props.onItemDoubleClicked) {
      this.props.onItemDoubleClicked(n);
      return;
    }
  };

  handleClick = (event, n) => {
    if (this.props.onItemClicked) {
      this.props.onItemClicked(n);
      return;
    }
    let id = n.uuid;
    let link = n.link;
    if (link) {
      this.props.history.push(link);
    } else if (this.props.routeTo) {
      this.props.history.push(this.props.routeTo.replace(/\{uuid\}/g, id));
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  componentDidMount() {
    this.handleRequestSortbyProps(this.props.defaultOrderBy, this.props.defaultOrder);
  }

  getCellValue = (n, col) => {
    if (typeof col.getCellValue === "function") {
      return col.getCellValue(n, col);
    }
    if (col.jsonpath) {
      let res = jsonpath.query(n, col.jsonpath);
      if (res.length === 1) {
        return res[0];
      }
    }
    if (typeof n[col.id] === "object") {
      let objectToStringify = JSON.stringify(n[col.id]);
      return objectToStringify;
    }
    return n[col.id];
  };

  getCellWidget = (n, col, index) => {
    if (typeof col.getCellWidget === "function") {
      return (
        <TableCell key={index} scope="row">
          {col.getCellWidget(n, col, index)}
        </TableCell>
      );
    }
    return (
      <TableCell key={index} component="th" scope="row">
        {this.getCellValue(n, col)}
      </TableCell>
    );
  };

  render() {
    const { classes, headers, data, noToolBar } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    let toolbar = null;
    let filter = this.state.filter;
    if (!noToolBar) {
      toolbar = (
        <EnhancedTableToolbar
          title={this.props.title}
          numSelected={selected.length}
          filter={filter}
          onFilter={this.handleFilter}
          noToolBar={noToolBar}
        />
      );
    } else {
      filter = this.props.filter || "";
    }
    var filteredData = this.filter(data || [], filter);
    let listComponent = (
      <div>
        {toolbar}
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby={this.props.title}>
            <ObjectHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={filteredData.length}
              headers={headers}
            />
            <TableBody>
              {filteredData
                .sort(getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, id) => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      className={classes.row}
                      hover
                      onClick={event => {
                        this.handleClick(event, n);
                      }}
                      onDoubleClick={event => {
                        this.handleDoubleClick(event, n);
                      }}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={id}
                      selected={isSelected}
                    >
                      {this.props.headers.map((col, index) => {
                        if (index === 0) {
                          return this.getCellWidget(n, col, index);
                        }
                        return this.getCellWidget(n, col, index);
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </div>
    );
    if (this.props.noPaper) {
      return listComponent;
    }
    return <Paper className={classes.root}>{listComponent}</Paper>;
  }
}

ObjectTable.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  headers: PropTypes.array.isRequired,
  data: PropTypes.array,
  storeData: PropTypes.string,
  researchValue: PropTypes.string,
  onItemClicked: PropTypes.func,
  onItemDoubleClicked: PropTypes.func,
  noPaper: PropTypes.bool,
  orderBy: PropTypes.string,
  order: PropTypes.string
};

export default withStyles(styles)(
  withRouter(
    connect((state, ownProps) => {
      if (ownProps.data) {
        return { data: ownProps.data };
      }
      if (ownProps.storeData) {
        let res = jsonpath.query(state, ownProps.storeData);
        if (res.length === 1) {
          return { data: res[0] };
        }
      }
      return {};
    })(ObjectTable)
  )
);

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Breadcrumb from "../common/Breadcrumb";
import ObjectList from "../common/ObjectList";
import { Paper } from "@material-ui/core";
import LoadingPanel from "../common/LoadingPanel";
import { useSelector } from "react-redux";
import { Card } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Controller } from "../controllers";

const styles = theme => ({
  root: {
    padding: 20
  },
  heading: {
    marginLeft: 100
  },
  textContent: {
    padding: 5,
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  header: {
    display: "flex",
    alignItems: "center"
  }
});

const ComplianceReportsDetails = ({ match, classes }) => {
  let title = match.params.id || "";
  const current = useSelector(state => state.compliance.currentReport);
  const async = useSelector(state => state.compliance._async.GET_COMPLIANCE_REPORT);
  return (
    <div id="compliance-report-details">
      <LoadingPanel async={async}>
        <Paper className={classes.root} id="complianceReportDetails">
          <Breadcrumb items={[{ title: "COMPLIANCE REPORTS", path: "/compliance-reports" }, { title }]} />
          <Card fluid color={current.isCompliant === true ? "green" : "red"} key="complianceReportDetails">
            <Card.Content className={classes.header}>
              <h1>Report uuid: {current.uuid}</h1>
              <IconButton onClick={() => Controller.get("dashboard").downloadReport(current.uuid)}>
                <GetAppIcon />
              </IconButton>
            </Card.Content>
            <Card.Content>
              <p className={classes.textContent}>Last changed: {new Date(current.lastChanged).toLocaleString()}</p>
              <p className={classes.textContent}>Last check: {new Date(current.lastCheck).toLocaleString()}</p>
              <p className={classes.textContent}>Last generated: {new Date(current.lastGenerated).toLocaleString()}</p>
              <p className={classes.textContent}>
                Last nonCompliant: {new Date(current.lastNonCompliant).toLocaleString()}
              </p>
            </Card.Content>
          </Card>
          <ObjectList
            title=""
            noPaper
            headers={[
              {
                id: "Norm",
                numeric: false,
                disablePadding: false,
                label: "Norm"
              },
              {
                id: "ControlId",
                numeric: false,
                disablePadding: false,
                label: "Control Id"
              },
              {
                id: "ControlReference",
                numeric: false,
                disablePadding: false,
                label: "Control Reference"
              }
            ]}
            data={current.references || []}
          />
        </Paper>
      </LoadingPanel>
    </div>
  );
};

export default withStyles(styles)(ComplianceReportsDetails);

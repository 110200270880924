import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import Breadcrumb from "../common/Breadcrumb";
import { Compliances } from "./CloudProjectDetails";
import { Controller } from "../controllers";
import PanelActions from "../common/PanelActions";
import ReactUserSelector from "../common/ReactUserSelector";
import { blue } from "@material-ui/core/colors";

import { FormControlLabel, MenuItem, Paper, Switch, TextField } from "@material-ui/core";

const styles = theme => ({
  root: {
    padding: 20,
    marginTop: 20
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  description: {
    width: "100%"
  },
  addEnv: {
    margin: 20,
    width: "300px"
  },
  colorSwitchBase: {
    "&$colorChecked": {
      color: blue[500],
      "& + $colorBar": {
        backgroundColor: blue[500]
      }
    }
  },
  colorChecked: {},
  colorBar: {},
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
});

const ranges = [
  {
    value: "internal",
    label: "internal",
    subcategoryLabel: "Enter your project name"
  },
  {
    value: "customers",
    label: "customers",
    subcategoryLabel: "Enter the customer name"
  },
  {
    value: "presales",
    label: "preSales",
    subcategoryLabel: "Enter the customer name"
  },
  {
    value: "qa",
    label: "QA",
    subcategoryLabel: "Enter the project name"
  },
  {
    value: "build",
    label: "Build",
    subcategoryLabel: "Enter the project name"
  },
  {
    value: "ps",
    label: "Professional Service",
    subcategoryLabel: "Enter the project name"
  },
  {
    value: "dev",
    label: "Development",
    subcategoryLabel: "Enter the project name"
  },
  {
    value: "support",
    label: "Support",
    subcategoryLabel: "Enter the project name"
  }
];

class CloudProjectNew extends React.Component {
  state = {
    project: {
      compliance: this.getComplianceObject()
    }
  };

  handleChange = (name, lower) => event => {
    let value = event.target.value;
    if (lower) {
      value = value.toLowerCase();
    }
    this.setState(prevState => {
      return {
        project: {
          ...prevState.project,
          [name]: value
        }
      };
    });
  };

  onCancel = () => {
    this.props.history.goBack();
  };

  onSave = () => {
    let newProject = this.state.project;
    newProject.uuid = newProject.billingCategory + "_" + newProject.billingSubcategory;
    Controller.get("cloudprojects").create(newProject);
  };

  onUserUpdate = field => {
    return value => {
      this.setState(prevState => {
        let res = { ...prevState, dirty: true };
        res.project[field] = value;
        return res;
      });
    };
  };

  getComplianceObject() {
    let result = {};
    Compliances.forEach(info => {
      result[info.key] = info.mandatory;
    });
    return result;
  }

  onComplianceUserUpdate = field => {
    return value => {
      let complianceValue = value.target.checked;
      this.setState(prevState => {
        let res = { ...prevState, dirty: true };
        res.project.compliance[field] = complianceValue;
        return res;
      });
    };
  };

  render() {
    const { classes, async } = this.props;
    const { project } = this.state;
    let subcategoryHelper = "";
    ranges.forEach(item => {
      if (item.value === project.billingCategory) {
        subcategoryHelper = item.subcategoryLabel;
      }
    });
    let incomplete = !(
      project.billingCategory &&
      project.billingSubcategory &&
      project.owners &&
      project.owners.length > 0
    );
    return (
      <Paper className={classes.root}>
        <Breadcrumb items={[{ title: "Cloud Projects", path: "/cloudprojects" }, { title: "New Project" }]} />
        <div>
          <TextField
            select
            label="Category"
            helperText="Please select a Category"
            required
            onChange={this.handleChange("billingCategory")}
            className={classNames(classes.margin, classes.textField)}
            value={project.billingCategory || ""}
            id="newproject-category"
          >
            {ranges.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            required
            label="Subcategory"
            helperText={subcategoryHelper}
            id="newproject-subcategory"
            onChange={this.handleChange("billingSubcategory", true)}
            className={classNames(classes.margin, classes.textField)}
            value={project.billingSubcategory || ""}
          />
          <TextField
            id="newproject-description"
            label="Description"
            multiline
            rows="4"
            value={project.description}
            onChange={this.handleChange("description")}
            className={(classes.textField, classes.description)}
            margin="normal"
          />
          <p className={classes.secondaryHeading}>Compliance scope</p>
          {Compliances.map((info, i) => (
            <div key={i}>
              <FormControlLabel
                control={
                  <Switch
                    classes={{
                      switchBase: classes.colorSwitchBase,
                      checked: classes.colorChecked,
                      bar: classes.colorBar
                    }}
                    onChange={this.onComplianceUserUpdate(info.key)}
                    checked={project.compliance[info.key]}
                    value={info.key}
                    disabled={info.mandatory}
                  />
                }
                label={info.label}
              />
            </div>
          ))}
          <ReactUserSelector
            label="Project Owners"
            onUpdate={this.onUserUpdate("owners")}
            value={project.owners}
            id="newproject-owners"
          />
          <PanelActions onCancel={this.onCancel} onSave={this.onSave} disabled={incomplete} async={async} create />
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(
  connect((state, ownProps) => {
    return {
      async: state.cloudprojects._async.CREATE_CLOUDPROJECT || {}
    };
  })(CloudProjectNew)
);

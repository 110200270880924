import { Paper, Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import dateFormat from "dateformat";
import React from "react";
import { connect } from "react-redux";
import { Card, Tab } from "semantic-ui-react";
import Breadcrumb from "../common/Breadcrumb";
import { Controller } from "../controllers";
import EventsPanel from "../EventsPanel";
import LoadingPanel from "../common/LoadingPanel";
import ManagementPanel from "../ManagementPanel";
import PanelActions from "../common/PanelActions";
import PermissionsPanel from "./PermissionsPanel";
import "./UserDetails.css";

const styles = theme => ({
  root: {
    minHeight: 380,
    padding: 20,
    marginTop: 20
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    padding: 10,
    alignItems: "center"
  },
  padded: {
    paddingLeft: 20
  },
  paddedContainer: {
    padding: 20
  },
  heading: {
    margin: 10,
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.primary,
    textTransform: "uppercase"
  },
  textContent: {
    margin: 20,
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
});

class UserDetails extends React.Component {
  state = {
    drafts: {
      _dirty: false,
      permissions: {}
    }
  };

  getAvatar = value => {
    if (value && value !== undefined) {
      return `http://www.gravatar.com/avatar/${value}`;
    }
  };
  static getDerivedStateFromProps(props, state) {
    if (!props.current) {
      return null;
    }
    if (state.user !== props.current.uuid) {
      return {
        user: props.current.uuid,
        drafts: {
          permissions: Object.assign({}, props.current.permissions),
          _dirty: false
        }
      };
    }
    return null;
  }

  onPermissionsChange = (permission, value) => {
    this.setState(prevState => {
      let drafts = { ...prevState.drafts };
      let permissions = Object.assign({}, drafts.permissions);
      permissions[permission] = value;
      drafts.permissions = permissions;
      drafts._dirty = true;
      return { drafts };
    });
  };

  onCancel = () => {
    this.setState((prevState, props) => {
      let drafts = { ...prevState.drafts };
      drafts.permissions = Object.assign({}, props.current.permissions);
      drafts._dirty = false;
      return { drafts };
    });
  };

  onSave = () => {
    let result = {};
    // Only keep permissions that are local
    for (let i in this.state.drafts.permissions) {
      if (i === "inherited") {
        continue;
      }
      if (this.state.drafts.permissions[i] && this.state.drafts.permissions.inherited.indexOf(i) < 0) {
        result[i] = true;
      }
    }
    Controller.get("users").updateUser({
      localPermissions: result
    });
  };

  render() {
    const { classes, asyncPut, asyncGet, current } = this.props;
    const { drafts } = this.state;
    if (!current) {
      return <div />;
    }
    let user = current;
    let profile = {};
    if (user._okta) {
      profile = user._okta.profile;
    }
    return (
      <Paper className={classes.root} id="userDetails">
        <Breadcrumb items={[{ title: "Users", path: "/users" }, { title: user.login }]} />
        <LoadingPanel async={asyncGet}>
          <Card key={user.name}>
            <Card.Content>
              <div className={classes.container}>
                <Avatar src={this.getAvatar(user.gravatar)} />
                <div className={classes.padded}>
                  <Card.Header>{profile.displayName}</Card.Header>
                  <Card.Meta>{profile.email}</Card.Meta>
                </div>
              </div>
            </Card.Content>
          </Card>
          <Tab
            onChange={this.onChange}
            menu={{ color: "blue", secondary: true, pointing: true }}
            panes={[
              {
                menuItem: "Actions",
                render: () => (
                  <Tab.Pane attached={false}>
                    <EventsPanel events={user.events} user={user.login} noPaper={true} />
                  </Tab.Pane>
                )
              },
              /*{
              menuItem: "Computers",
              render: () => <Tab.Pane attached={false}>Computers</Tab.Pane>
            },
            */
              {
                menuItem: "Access",
                render: () => {
                  return (
                    <Tab.Pane>
                      <PermissionsPanel onChange={this.onPermissionsChange} current={drafts.permissions} />
                    </Tab.Pane>
                  );
                }
              },
              {
                menuItem: "Information",
                render: () => (
                  <Tab.Pane attached={false}>
                    <div className={classes.paddedContainer}>
                      <p className={classes.heading}>Activity</p>
                      <p className={classes.textContent}>
                        Created: {dateFormat(new Date(user._okta.created), "yyyy-mm-dd HH:MM Z") || "nd"}
                      </p>
                      <p className={classes.textContent}>
                        Last login: {dateFormat(new Date(user._okta.lastLogin), "yyyy-mm-dd HH:MM Z") || "nd"}
                      </p>
                      <p className={classes.textContent}>Status: {user._okta.status || "nd"}</p>
                      <p className={classes.textContent}>Groups: {user.groups.join(", ") || "nd"}</p>

                      <p className={classes.heading}>Contact informations</p>
                      <p className={classes.textContent}>Primary Phone: {user._okta.profile.primaryPhone || "nd"}</p>
                      <p className={classes.textContent}>Mobile Phone: {user._okta.profile.mobilePhone || "nd"}</p>
                      <p className={classes.textContent}>Email: {user._okta.profile.email || "nd"}</p>
                      <p className={classes.textContent}>Second Email: {user._okta.profile.secondEmail || "nd"}</p>
                      <p className={classes.textContent}>SSH Public Key: {user._okta.profile.publicKey || "nd"}</p>
                      <p className={classes.textContent}>Github login: {user._okta.profile.githubLogin || "nd"}</p>
                    </div>
                  </Tab.Pane>
                )
              },
              {
                menuItem: "Management",
                render: props => {
                  return (
                    <Tab.Pane>
                      <ManagementPanel onChange={this.onPermissionsChange} current={drafts.permissions} user={user} />
                    </Tab.Pane>
                  );
                }
              }
            ]}
          />
          <PanelActions onCancel={this.onCancel} onSave={this.onSave} dirty={drafts._dirty} async={asyncPut} />
        </LoadingPanel>
      </Paper>
    );
  }
}
export default withStyles(styles)(
  connect((state, ownProps) => {
    return {
      asyncPut: state.users._async.UPDATE_USER || {},
      asyncGet: state.users._async.GET_USER || {},
      current: state.users.current
    };
  })(UserDetails)
);

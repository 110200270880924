import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CachedIcon from "@material-ui/icons/Cached";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { toast } from "react-toastify";
import { Controller } from "../controllers";
import LoadingPanel from "../common/LoadingPanel";
import ObjectList from "../common/ObjectList";
import UserDetails from "./UserDetails";
import NewUser from "./UsersManageNew";
import UsersOnboarding from "./UsersOnboarding";
import { Button } from "@material-ui/core";

const styles = theme => ({
  addButton: {
    position: "relative",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  }
});

const UsersAndGroupsLists = ({ onClick, onGroupClick }) => {
  return (
    <div>
      <ObjectList
        title="Users Management"
        headers={[
          {
            id: "displayName",
            numeric: false,
            disablePadding: false,
            label: "Name"
          },
          {
            id: "name",
            numeric: false,
            disablePadding: false,
            label: "Login"
          },
          {
            id: "email",
            numeric: false,
            disablePadding: false,
            label: "Email"
          }
        ]}
        storeData="$.users.users"
        onItemClicked={onClick}
      />
      <ObjectList
        title="Groups Management"
        headers={[
          {
            id: "name",
            numeric: false,
            disablePadding: false,
            label: "Name"
          },
          {
            id: "users",
            numeric: false,
            disablePadding: false,
            label: "Users",
            getCellValue: n => n.users.join(",")
          }
        ]}
        storeData="$.users.groups"
        onItemClicked={onGroupClick}
      />
    </div>
  );
}

const UserDetailsPanel = ({ async, match }) => {
  let user = match.params.user || "";
  return (
    <LoadingPanel async={async}>
      <UserDetails user={user} />
    </LoadingPanel>
  );
}

const UsersManage = ({ classes, history, ...other }) => {
  const [oktaSyncing, setOktaSyncing] = useState(false);
  const async = useSelector(state => state.users._async.USERS_LIST || {});
  const onClick = item => {
    routeToUser(item.name);
  };

  const onGroupClick = item => {
    history.push(`/groups/${item.name}`);
  };

  const routeToUser = user => {
    history.push(`/users/${user}`);
  }

  let onboardingBox = false;
  let button;
  if (Controller.get("users").hasPermission("usersCreate")) {
    button = (
      <div className={classes.addButton}>
        <Button
          onClick={() => {
            setOktaSyncing(true);
            Controller.get("users").sync(() => {
              setOktaSyncing(false);
              toast.info("Okta users sync has been queued", {
                position: toast.POSITION.BOTTOM_CENTER
              });
            });
          }}
          variant="outlined"
          color="primary"
          style={{ marginLeft: "20px" }}
          disabled={oktaSyncing}
        >
          <CachedIcon />
            Sync
          </Button>

        <Button
          onClick={() => {
            history.push("/users/new");
          }}
          variant="outlined"
          color="primary"
          disabled={true}
          style={{ marginLeft: "20px" }}
        >
          <AddIcon />
            Add a System user
          </Button>

        <Button
          onClick={() => {
            Controller.get("users").showOnboarding();
          }}
          variant="outlined"
          color="primary"
        >
          <AddIcon />
            Onboarding
          </Button>
      </div>
    );
  }
  return (
    <div>
      {onboardingBox && <UsersOnboarding />}
      {button}
      <Route
        exact
        path="/users"
        render={() => (
          <LoadingPanel async={async}>
            <UsersAndGroupsLists onClick={onClick} onGroupClick={onGroupClick} async={async} />
          </LoadingPanel>
        )}
      />
      <Switch>
        <Route exact path="/users/new" component={NewUser} />
        <Route exact path="/users/:user" render={props => <UserDetailsPanel async={props.async} {...other} />} />
      </Switch>
    </div>
  );
}

export default withStyles(styles)(UsersManage);

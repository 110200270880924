import { DataSearch, ReactiveBase, ReactiveComponent, ReactiveList, SelectedFilters } from "@appbaseio/reactivesearch";
import { Button, withStyles, CircularProgress, DialogContentText, Tooltip } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { JSONPath } from "jsonpath-plus";
import React from "react";
import ReactJson from "react-json-view";
import { connect } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import Cookies from "universal-cookie";
import { DateTimeInput } from "semantic-ui-calendar-react";
import { Dropdown, Icon, Menu, Segment, Input } from "semantic-ui-react";
import { Controller } from "./controllers";
import dateFormat from "dateformat";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { DEFAULT_COLUMNS, DEFAULT_LOGFORMAT, LOGS_PREPROCESSOR } from "nxsec-shared";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, IconButton } from "@material-ui/core";
import { sprintf } from "sprintf-js";

const cookies = new Cookies();
const styles = theme => ({
  monospace: {
    input: { fontFamily: "monospace" }
  }
});

const rawColumn = {
  width: "100%",
  overflowX: "hidden",
  whiteSpace: "nowrap",
  padding: 5
};

const dateTimeInputStyle = {
  fontSize: "small",
  width: "170px"
};

// Evaluate JSONPath
let evalJSONPath = (jsonpath, json) => {
  if (jsonpath.substr(0, 1) !== ".") {
    // handle "dot or not-dot" prefix
    jsonpath = `.${jsonpath}`;
  }
  let result = "";
  try {
    result = JSONPath({ path: "$" + jsonpath, json });
  } catch (e) { }
  return result;
};

// TODO : width JSON stuck
class JSONPathComponent extends React.Component {
  render() {
    const { event, jsonpath } = this.props;
    let [path /*, label*/] = jsonpath.split(" ");
    let result = evalJSONPath(path, event);
    if (result[0] && !result[1]) {
      result = result.shift();
    }
    let css = {
      width: "100%",
      overflowX: "hidden",
      whiteSpace: "nowrap",
      maxWidth: "400px",
      textOverflow: "ellipsis"
    };
    if (typeof result !== "string") {
      if (Array.isArray(result) && result.length === 0) {
        result = "";
      } else {
        css = rawColumn;
        result = <ReactJson src={result} collapsed={true} />;
      }
    }
    return <div style={css}>{result}</div>;
  }
}

function GenerateDownloadMenu({ project, env, index, info }) {
  const [loading, setLoading] = React.useState(false);
  if (loading) {
    return <CircularProgress size={24} bottom={0} left={0} style={{ margin: 20 }} />;
  }
  return (
    <Dropdown item icon="download" direction="left" simple>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            setLoading(true);
            Controller.get("cloudprojects").getLogs(project, env, index, info, "CSV", () => {
              setLoading(false);
            });
          }}
        >
          CSV
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={() => {
            setLoading(true);
            Controller.get("cloudprojects").getLogs(project, env, index, info, "Nuxeo", () => {
              setLoading(false);
            });
          }}
        >
          Nuxeo Logs
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function GenerateDownloadButton({ title, download }) {
  const [loading, setLoading] = React.useState(false);
  if (loading) {
    return (
      <div align="center">
        <DialogContentText>Generating {title}...</DialogContentText>
        <CircularProgress bottom={0} left={0} style={{ margin: 20 }} />
      </div>
    );
  }
  return (
    <Button
      onClick={() => {
        setLoading(true);
        download(() => {
          setLoading(false);
        });
      }}
    >
      {title} <GetAppIcon />
    </Button>
  );
}

const CollapseColumnStyle = {
  width: 40,
  padding: 0
};
function LogDetails({ event, customColumns, index, sprintfPreviewMode, logformat }) {
  const [open, setOpen] = React.useState(false);
  let style = {
    backgroundColor: "#ffffff"
  };
  if (index % 2) {
    style.backgroundColor = "#dcdcdc";
  }
  let parseLogFormat = (...args) => {
    try {
      return sprintf(...args);
    } catch (e) {
      console.error(e.message);
    }
  };
  return (
    <React.Fragment>
      <TableRow style={style}>
        <TableCell style={CollapseColumnStyle}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{new Date(event.timestamp).toUTCString()}</TableCell>

        {(sprintfPreviewMode && (
          <TableCell style={{ fontWeight: "bold", wordBreak: "break-all", fontFamily: "monospace,monospace" }}>
            {parseLogFormat(logformat, LOGS_PREPROCESSOR(event))}
          </TableCell>
        )) ||
          customColumns.map((jsonpath, k) => (
            <TableCell key={k}>
              <JSONPathComponent jsonpath={jsonpath} event={event} key={jsonpath + "_" + event.uuid} />
            </TableCell>
          ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <ReactJson src={event} collapsed={false} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CustomColumn({ path, onRemove, hovered }) {
  return (
    <TableCell style={{ fontWeight: "bold" }}>
      {path.split(" ")[1]}{" "}
      <i
        className="trash icon right"
        onClick={onRemove}
        style={{ cursor: "pointer", color: hovered ? "black" : "transparent" }}
      ></i>
    </TableCell>
  );
}

function LogDetailsTable({ events, customColumns, onRemove, sprintfPreviewMode, logformat }) {
  const [hover, setHover] = React.useState(false);
  if (events.loading) {
    return (
      <div style={{ width: "100%", height: 300, alignItems: "center", display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }
  let info = "";
  if (events.rawData) {
    info = `${events.rawData.hits.total.value} hits in ${events.rawData.took} ms`;
  }
  return (
    <div>
      <div width="100%" align="center" style={{ fontSize: 10, fontColor: "#ccc" }}>
        {info}
      </div>
      <TableContainer>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <TableRow>
              <TableCell style={CollapseColumnStyle}></TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Time</TableCell>
              {(sprintfPreviewMode && <TableCell style={{ fontWeight: "bold" }}>Preview syntax</TableCell>) ||
                customColumns.map((jsonpath, k) => (
                  <CustomColumn path={jsonpath} key={k} hovered={hover} onRemove={() => onRemove(k)} />
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {events.data.map((event, index) => {
              return (
                <LogDetails
                  key={index}
                  index={index}
                  event={event}
                  customColumns={customColumns}
                  sprintfPreviewMode={sprintfPreviewMode}
                  logformat={logformat}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function DateTime({ setQuery, updateTimeRange }) {
  const [gteTime, setGteTime] = React.useState("");
  const [lteTime, setLteTime] = React.useState(dateFormat(Date.now(), "yyyy-mm-dd HH:MM:00"));
  let updateChange = (lteTime, gteTime) => {
    if (gteTime && lteTime) {
      let v = `${gteTime},${lteTime}`;
      let query = {
        range: {
          timestamp: {
            gte: new Date(gteTime.replace(/ /g, "T") + "Z").getTime(),
            lte: new Date(lteTime.replace(/ /g, "T") + "Z").getTime()
          }
        }
      };
      updateTimeRange(v);
      setQuery({ query, value: v });
    } else {
      updateTimeRange(null);
      setQuery({});
    }
  };
  return (
    <React.Fragment>
      <label>UTC timerange: </label>
      <DateTimeInput
        style={dateTimeInputStyle}
        name="gteTime"
        clearable={true}
        animation="off"
        placeholder="From Time"
        value={gteTime}
        onChange={(e, { value }) => {
          setGteTime(value);
          updateChange(lteTime, value);
        }}
        startMode="day"
        maxDate={new Date()}
        dateTimeFormat="YYYY-MM-DD HH:mm:00"
      />
      {"   "}
      <DateTimeInput
        style={dateTimeInputStyle}
        name="lteTime"
        clearable={true}
        animation="off"
        placeholder="From Time"
        value={lteTime}
        onChange={(e, { value }) => {
          setLteTime(value);
          updateChange(value, gteTime);
        }}
        startMode="day"
        maxDate={new Date()}
        dateTimeFormat="YYYY-MM-DD HH:mm:00"
      />
    </React.Fragment>
  );
}

class LogsPanel extends React.Component {
  state = {
    eventSearch: "",
    timeRange: "",
    exportLimit: 10000,
    customColumns: cookies.get("csvCustomColumns") || DEFAULT_COLUMNS,
    logformat: cookies.get("logformat") || DEFAULT_LOGFORMAT,
    sprintfPreviewMode: false,
    firstRow: {},
    newFieldPath: "",
    newFieldTitle: ""
  };
  updateTimeRange(timeRange) {
    this.setState({ timeRange });
  }
  onChange(field) {
    return (event, { value: v } = {}) => {
      if (event === undefined && !v) return;
      let value = v;
      if (!value && event) {
        if (event.target) value = event.target.value;
        else value = event;
      }
      let getVal = v => v.name || v.value || v;
      this.setState(prevState => {
        let res = { ...prevState };
        res[field] = getVal(value);
        return res;
      });
    };
  }
  render() {
    let { project, env } = this.props;
    let defaultQuery = () => {
      let q = { query: { bool: { must_not: [{ match: { type: "bd" } }, { match: { type: "op" } }] } } };
      return q;
    };
    let onBeforeSend = props => {
      props.body = props.body.replace(`[{"query_string":{"query":""}}],`, ``); // fix when manual clear of search box
      return {
        ...props,
        credentials: "include"
      };
    };
    let onResponse = async body => {
      let { hits: { hits: [{ _source: firstRow } = {}] = [] } = {} } = body;
      this.setState({ firstRow });
      return body;
    };
    let index = `logs-${env}-*`;
    return (
      <div>
        <ReactiveBase
          app={index}
          url={Controller.get("cloudprojects").getURL(`${project}/${env}/logs`)}
          headers={{ "X-CSRF-Token": Controller.getCSRFToken() }}
          transformRequest={onBeforeSend}
          transformResponse={onResponse}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}
          >
            <div>
              <Menu attached="top" id="logsToolbar">
                <Dropdown item icon="wrench" simple>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      Add a new column
                      <div
                        className={"ui form"}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "400px"
                        }}
                      >
                        <div className={"two fields"}>
                          <div className={"field"}>
                            <label>JSON path :</label>
                            <input
                              type="text"
                              name="newFieldPath"
                              value={this.state.newFieldPath}
                              onChange={this.onChange("newFieldPath").bind(this)}
                              placeholder=".field.subfield"
                            />
                          </div>
                          <div className={"field"}>
                            <label>Title :</label>
                            <input
                              type="text"
                              name="newFieldTitle"
                              value={this.state.newFieldTitle}
                              onChange={this.onChange("newFieldTitle").bind(this)}
                              placeholder="Title"
                            />
                          </div>
                        </div>
                        <button
                          className={"ui button"}
                          onClick={e => {
                            let values = this.state.customColumns || [];
                            values.push(`${this.state.newFieldPath} ${this.state.newFieldTitle}`);
                            this.setState({ customColumns: [...new Set(values)] }); // live remove doublons
                            cookies.set("csvCustomColumns", values);
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item>
                      <Icon name="dropdown" />
                      Custom columns (RAW edit)
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          You can edit your custom columns in this textarea:
                          <br />
                          <pre>JSONpath title</pre>
                          <TextareaAutosize
                            style={{ fontSize: 9, width: "300px", height: "300px" }}
                            value={this.state.customColumns.join("\n")}
                            onChange={e => {
                              let values = e.target.value.split("\n");
                              if (!values.length) {
                                values = DEFAULT_COLUMNS;
                              }
                              this.setState({ customColumns: values });
                              cookies.set("csvCustomColumns", values);
                            }}
                            placeholder="(one jsonpath per line)"
                          />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Menu.Menu position="left">
                  <div className="ui right aligned category search item">
                    <ReactiveComponent componentId="timeRange" showFilter>
                      {data => (
                        <DateTime updateTimeRange={this.updateTimeRange.bind(this)} dataField="timeRange" {...data} />
                      )}
                    </ReactiveComponent>
                  </div>
                </Menu.Menu>

                <Menu.Menu position="left">
                  <div className="ui right aligned category search item">
                    <Tooltip title="Nuxeo Logs">
                      <img
                        alt=""
                        src="/favicon.ico"
                        alt=""
                        style={{ width: "32px", filter: this.state.sprintfPreviewMode ? "" : "grayscale(1)" }}
                        onClick={e => {
                          let sourceNuxeo = "source:nuxeo";
                          let sprintfPreviewMode = !this.state.sprintfPreviewMode;
                          let eventSearch = this.state.eventSearch;
                          if (sprintfPreviewMode) {
                            if (eventSearch.indexOf(sourceNuxeo) === -1) {
                              if (eventSearch) {
                                eventSearch = `${eventSearch} AND ${sourceNuxeo}`;
                              } else {
                                eventSearch = sourceNuxeo;
                              }
                            }
                          }
                          this.setState({ sprintfPreviewMode, eventSearch });
                        }}
                      />
                    </Tooltip>
                  </div>
                </Menu.Menu>

                <Menu.Menu position="right">
                  <div className="ui right aligned category search item" style={{ width: "100%" }}>
                    <DataSearch
                      style={{ width: "100%", margin: 0 }}
                      componentId="eventSearch"
                      customQuery={value => {
                        return { query: { query_string: { query: value } } };
                      }}
                      dataField={["*"]}
                      searchOperators={true}
                      autosuggest={false}
                      value={this.state.eventSearch}
                      onKeyPress={(evt, triggerQuery) => {
                        if (evt.key === "Enter") {
                          triggerQuery();
                        }
                      }}
                      onChange={eventSearch => {
                        this.setState({ eventSearch });
                      }}
                    />
                  </div>
                </Menu.Menu>

                <GenerateDownloadMenu project={project} env={env} index={index} info={this.state} />
              </Menu>

              {this.state.sprintfPreviewMode && (
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                  <Input
                    id="sprintf-syntax"
                    label={
                      <div>
                        <a href="https://www.npmjs.com/package/sprintf-js" style={{ cursor: "pointer" }}>
                          Preview NuxeoLogs export sprintf syntax:
                        </a>{" "}
                      </div>
                    }
                    value={this.state.logformat}
                    onChange={e => {
                      let logformat = e.target.value || DEFAULT_LOGFORMAT;
                      this.setState({ logformat });
                      cookies.set("logformat", logformat);
                    }}
                    size="small"
                    className="monospace"
                    style={{ width: "100%" }}
                  />
                </div>
              )}

              <Segment style={{ paddingTop: 0, marginTop: 0, border: 0 }}>
                <SelectedFilters />
                <ReactiveList
                  showResultStats={false}
                  dataField="timestamp"
                  sortBy="desc"
                  componentId="SearchResult"
                  size={100}
                  pagination={true}
                  paginationAt="bottom"
                  react={{ and: ["logDate", "eventSearch", "timeRange"] }}
                  defaultQuery={defaultQuery}
                  render={res => (
                    <LogDetailsTable
                      customColumns={this.state.customColumns}
                      events={res}
                      sprintfPreviewMode={this.state.sprintfPreviewMode}
                      logformat={this.state.logformat}
                      onRemove={e => {
                        let values = this.state.customColumns || [];
                        values.splice(e, 1);
                        if (!values.length) {
                          values = DEFAULT_COLUMNS;
                        }
                        this.setState({ customColumns: [...new Set(values)] }); // live remove doublons
                        cookies.set("csvCustomColumns", values);
                      }}
                    />
                  )}
                />
              </Segment>
            </div>
          </div>
        </ReactiveBase>
      </div>
    );
  }
}

export default withStyles(styles)(
  connect((state, ownProps) => {
    return {
      csvLoader: state.cloudprojects.csvLoader || false,
      permissions: state.dashboard.permissions || {
        permissions: {},
        groups: {}
      }
    };
  })(LogsPanel)
);

import * as jsonpath from "jsonpath";
import utf8 from "utf8";
import { Controller } from "./Controller";

class ComputersController extends Controller {
  constructor() {
    super("computers", { report: {}, current: {}, list: [], map: {} });
    this.registerRoute({ path: "/computers/:id", strict: false, exact: true }, this._fetchComputerRoute);
    this.registerRoute({ path: "/computers", strict: false, exact: true }, this.syncComputers);
  }

  update(uuid, updates, callback) {
    this.asyncAction("UPDATE_COMPUTER", async (dispatch, getState) => {
      await this.ajax("/computers/" + uuid, "PATCH", updates);
      let list = this.getLocalState().list.slice(0);
      list.forEach(cmp => {
        if (cmp.uuid === uuid) {
          for (let i in updates) {
            cmp[i] = updates[i];
          }
        }
      });
      if (callback) {
        callback();
      }
      return { list: list };
    });
  }

  _fetchComputerRoute(params) {
    this.get(params.id);
  }

  get(uuid) {
    this.asyncAction("GET_COMPUTER", async (dispatch, getState) => {
      await Controller.get("users").waitInit();
      let res: any = await this.ajax("/computers/" + uuid);
      let report = res.report;
      report.SSN = jsonpath.query(report, "$.BIOS[0].SSN[0]")[0];
      report.Model = jsonpath.query(report, "$.BIOS[0].SMODEL[0]")[0];
      report.Manufacturer = jsonpath.query(report, "$.BIOS[0].SMANUFACTURER[0]")[0];
      report.CPU = jsonpath.query(report, "$.CPUS[0].NAME[0]")[0];
      report.Firewall = jsonpath.query(report, "$.FIREWALL[0].STATUS")[0];
      report.FusionVersion = jsonpath.query(report, "$.VERSIONCLIENT[0]")[0];
      report.Softwares = report.SOFTWARES.map(rep => {
        let version;
        let name;
        let publisher;
        let category;
        let installDate;
        if (rep.VERSION && rep.VERSION[0]) {
          version = rep.VERSION[0];
        }
        if (rep.NAME && rep.NAME[0]) {
          name = rep.NAME[0];
        }
        if (rep.PUBLISHER && rep.PUBLISHER[0]) {
          publisher = rep.PUBLISHER[0];
        }
        if (rep.INSTALLDATE && rep.INSTALLDATE[0]) {
          let dateArray = rep.INSTALLDATE[0].split("/");
          installDate = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
        }
        if (rep.SYSTEM_CATEGORY && rep.SYSTEM_CATEGORY[0]) {
          category = rep.SYSTEM_CATEGORY[0];
        }
        return {
          Version: version,
          Name: name,
          Publisher: publisher,
          InstallDate: installDate,
          Category: category
        };
      });
      //FIREWALL: FIREWALL[0].STATUS
      //LOCAL_USERS:
      // VERSIONCLIENT
      // Softwares
      //tasksRes[i].link = '/computers/' + tasksRes[i].uuid;
      return { report, current: res.computer };
    });
  }

  syncComputers() {
    this.asyncAction("SYNC_COMPUTERS", async (dispatch, getState) => {
      let computersRes = await this.ajax("/computers");
      let computers = [];
      let map = {};
      for (let i in computersRes) {
        if (typeof computersRes[i] !== "object") continue;
        if (["uuid", "_lastUpdate", "_creationDate"].indexOf(i) > -1) continue;
        let computer: any = computersRes[i];
        map[i] = computersRes[i];
        try {
          computer.link = "/computers/" + computer.uuid;
        } catch (err) {
          console.log(err);
        }
        computer.name = utf8.decode(computer.name || "");
        computers.push(computer);
      }
      return { list: computers, map };
    });
  }
}

export { ComputersController };

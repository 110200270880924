import { withStyles, Paper, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Breadcrumb from "../common/Breadcrumb";
import { Controller } from "../controllers";
import PanelActions from "../common/PanelActions";

const styles = theme => ({
  root: {
    padding: 20,
    marginTop: 20
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column"
  },
  textField: {
    marginLeft: 40,
    marginRight: 40,
    width: 300
  }
});

const UsersManageNew = ({ classes, history }) => {

  const [user, setUser] = useState({ type: "user" });
  const async = useSelector(state => state.users._async.CREATE_USER || {});

  const onSave = () => {
    let newUser = this.state.user;
    Controller.get("users").create(newUser);
  };

  const onCancel = () => {
    setUser({ type: "user" });
    history.push("/users");
  }

  const handleChange = (name, lower) => event => {
    let value = event.target.value;
    if (lower) {
      value = value.toLowerCase();
    }
    setUser((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  let incomplete = !(user.name && user.displayName && user.sshKey);

  return (
    <Paper className={classes.root}>
      <Breadcrumb items={[{ title: "Users Management", path: "/users" }, { title: "New User" }]} />
      <div className={classes.container}>
        <TextField
          label="Enter the name"
          rows="4"
          value={user.name}
          onChange={handleChange("name")}
          className={classes.textField}
          margin="normal"
        />
        <TextField
          label="Enter the display name"
          rows="4"
          value={user.displayName}
          onChange={handleChange("displayName")}
          className={classes.textField}
          margin="normal"
        />
        <TextField
          label="Public ssh key"
          rows="4"
          value={user.sshKey}
          onChange={handleChange("displayName")}
          className={classes.textField}
          margin="normal"
        />
        <PanelActions onCancel={onCancel} onSave={onSave} disabled={incomplete} async={async} create />
      </div>
    </Paper>
  );
}

export default withStyles(styles)(UsersManageNew);

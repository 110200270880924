import { withStyles } from "@material-ui/core/styles";
import React from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
// might consider moving to https://github.com/dennismorello/react-awesome-reveal
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import Breadcrumb from "../common/Breadcrumb";
import LoadingPanel from "../common/LoadingPanel";
import SecurityTableElement from "./SecurityTableElement";

const styles = theme => ({
  root: {
    padding: 10,
    marginTop: 10
  },
  centerContainer: {
    display: "flex",
    justifyContent: "center",
    padding: 10
  },
  markDownContent: {
    padding: "30px"
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10
  }
});

const SecurityTableDetail = ({ classes, history }) => {

  const async = useSelector(state => state.dashboard._async.SYNC_UI_CONFIGURATION);
  const table = useSelector(state => state.dashboard.UIConfiguration.securityTable || []);

  let getAcronymFromLocation = history.location.pathname.split("/")[2];
  let element =
    table.filter(
      tableItem => tableItem.acronym !== undefined && tableItem.acronym.toLowerCase() === getAcronymFromLocation
    )[0] || {};
  let title = element.fullName || "";

  return (
    <div className={classes.root}>
      <LoadingPanel async={async}>
        <Breadcrumb items={[{ title: "Security table", path: "/security-table" }, { title }]} />
        <Flip>
          <div className={classes.centerContainer}>
            <SecurityTableElement element={element} />
          </div>
        </Flip>
        <Fade className={classes.flexContainer}>
          <ReactMarkdown className={classes.markDownContent} source={element.mdContent} />
        </Fade>
      </LoadingPanel>
    </div>
  );
}

export default withStyles(styles)(SecurityTableDetail);

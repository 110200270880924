import React from "react";
import { Switch, Route } from "react-router-dom";
import ScreenCasts from "./ScreenCasts";
import ScreenCastDetails from "./ScreenCastDetails";

const ScreenCastsComponent = () => {
  return (
    <div>
      <Route exact path="/screencasts" component={ScreenCasts} />
      <Switch>
        <Route path="/screencasts/:id" component={ScreenCastDetails} />
      </Switch>
    </div>
  );
}


export default ScreenCastsComponent;

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Breadcrumb from "../common/Breadcrumb";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { Header } from "semantic-ui-react";
import PanelActions from "../common/PanelActions";
import Button from "@material-ui/core/Button";
import { Controller } from "../controllers";
import LoadingPanel from "../common/LoadingPanel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import blue from "@material-ui/core/colors/blue";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Divider from "@material-ui/core/Divider";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

const styles = theme => ({
  root: {
    padding: 20
  },
  flexSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  textField: {
    width: 400,
    margin: theme.spacing(1)
  },
  volumesTextFieldsContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  volumeContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderWidth: "thin",
    borderStyle: "dashed",
    borderRadius: 10,
    borderColor: "grey",
    padding: 20,
    margin: 10,
    marginBottom: 20
  },
  volumesContainerChild: {
    width: 300,
    margin: theme.spacing(1)
  },
  commandTextfield: {
    margin: theme.spacing(1),
    marginRight: 30
  },
  colorSwitchBase: {
    "&$colorChecked": {
      color: blue[500],
      "& + $colorBar": {
        backgroundColor: blue[500]
      }
    }
  },
  colorChecked: {},
  colorBar: {},
  addButton: {
    margin: 10
  },
  divider: {
    marginTop: 20
  }
});

function CloudProjectDockerVolume({ volume, classes, onChange, onDelete }) {
  const onContainerDetailsVolumeChange = (evt, field) => {
    let modified = { ...volume };
    modified[field] = evt.target.value;
    onChange(modified);
  };

  return (
    <div className={classes.volumeContainer}>
      <div className={classes.volumesTextFieldsContainer}>
        <TextField
          label="Volume"
          value={volume.name || ""}
          className={classes.volumesContainerChild}
          onChange={evt => onContainerDetailsVolumeChange(evt, "name")}
          id="newdockerContainer-volumeName"
        />
        <TextField
          label="Bind"
          value={volume.bind || ""}
          className={classes.volumesContainerChild}
          onChange={evt => onContainerDetailsVolumeChange(evt, "bind")}
          id="newdockerContainer-volumeBind"
        />
        <FormControlLabel
          control={
            <Switch
              classes={{
                switchBase: classes.colorSwitchBase,
                checked: classes.colorChecked
              }}
              checked={volume.mode === "ro"}
              onChange={evt =>
                onContainerDetailsVolumeChange({ target: { value: volume.mode === "ro" ? "rw" : "ro" } }, "mode")
              }
              id="vol-readonly"
            />
          }
          label="Read-Only"
        />
      </div>
      <Button color="secondary" id="delete-containerPort-button" onClick={onDelete} disabled={false}>
        Delete Volume
        <DeleteIcon fontSize="small" />
      </Button>
    </div>
  );
}

function CloudProjectDockerPort({ port, classes, onChange, onDelete }) {
  const onContainerDetailsPortsChange = (evt, port, field) => {
    let modified = { ...port };
    modified[field] = evt.target.value;
    onChange(modified);
  };

  return (
    <div className={classes.volumeContainer}>
      <div className={classes.volumesTextFieldsContainer}>
        <TextField
          label="Local"
          value={port.local || ""}
          className={classes.volumesContainerChild}
          onChange={evt => onContainerDetailsPortsChange(evt, port, "local")}
          id="newdockerContainer-portName"
          size="small"
          type="number"
        />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="protocol-native-simple">Protocol</InputLabel>
          <Select
            native
            value={port.protocol}
            onChange={evt => onContainerDetailsPortsChange(evt, port, "protocol")}
            inputProps={{
              name: "protocol",
              id: "protocol-native-simple"
            }}
          >
            <option aria-label="tcp" value="tcp">
              tcp
            </option>
            <option aria-label="udp" value="udp">
              udp
            </option>
          </Select>
        </FormControl>
        <TextField
          label="Host"
          value={port.host || ""}
          size="small"
          className={classes.volumesContainerChild}
          onChange={evt => onContainerDetailsPortsChange(evt, port, "host")}
          id="newdockerContainer-portHost"
          type="number"
        />
      </div>
      <Button
        color="secondary"
        id="delete-containerPort-button"
        onClick={onDelete}
        disabled={false}
        className={classes.deletePort}
      >
        Delete port
        <DeleteIcon fontSize="small" />
      </Button>
    </div>
  );
}

class CloudProjectDockerContainerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dirty: false,
      deleteWarning: "Delete container",
      currentContainer: props.currentContainer,
      ports: this.portsMapToArray(props.currentContainer.ports),
      volumes: this.volumesMapToArray(props.currentContainer.volumes)
    };
  }

  portsMapToArray(ports = undefined) {
    if (!ports) {
      return [];
    }
    let res = [];
    for (let i in ports) {
      let [local, protocol = "tcp"] = i.split("/");
      res.push({
        local,
        protocol,
        host: ports[i]
      });
    }
    return res;
  }

  volumesMapToArray(volumes = undefined) {
    if (!volumes) {
      return [];
    }
    return Object.keys(volumes)
      .sort()
      .map(p => ({ ...volumes[p], name: p }));
  }

  updateCurrentContainer = newContainer => {
    this.setState(prevState => ({
      dirty: true,
      currentContainer: newContainer,
      volumes: this.volumesMapToArray(newContainer.volumes),
      ports: this.portsMapToArray(newContainer.ports)
    }));
  };

  deleteContainer = (cloudProjectId, env, container) => {
    return evt => {
      if (this.state.deleteWarning === "Delete container") {
        this.setState(prevState => ({
          deleteWarning: "Confirm Delete !"
        }));
      } else {
        Controller.get("cloudprojects").deleteContainer(this.props.cloudproject, env, container, () => {
          this.props.history.push(`/cloudprojects/${cloudProjectId}`);
        });
      }
    };
  };

  onContainerDetailsChange = (evt, field) => {
    let modifiedCurrentContainer = { ...this.state.currentContainer };
    if (field === "privileged") {
      modifiedCurrentContainer[field] = !modifiedCurrentContainer[field];
    } else {
      modifiedCurrentContainer[field] = evt.target.value;
    }
    this.updateCurrentContainer(modifiedCurrentContainer);
  };

  addNewVolume = () => {
    this.setState(oldState => {
      return {
        dirty: true,
        volumes: [
          ...oldState.volumes,
          {
            name: "",
            bind: "",
            mode: "ro",
            _new: true
          }
        ]
      };
    });
  };

  addNewPort = () => {
    this.setState(oldState => {
      return {
        dirty: true,
        ports: [
          ...oldState.ports,
          {
            local: 0,
            host: 0,
            protocol: "tcp",
            _new: true
          }
        ]
      };
    });
  };

  onCancel = (cloudproject, env, container) => {
    return evt => {
      // case creating new container
      if (container === "new") {
        this.updateCurrentContainer({
          image: "",
          privileged: false,
          volumes: {},
          ports: {}
        });
      } else {
        this.updateCurrentContainer(cloudproject.envs[env].services.docker.containers[container]);
      }
    };
  };

  onSave = (cloudproject, env) => {
    return evt => {
      // handle both cases creating or updating cloudproject
      let dockerContainer = JSON.parse(JSON.stringify(cloudproject.envs[env].services.docker || {}));
      dockerContainer.containers = dockerContainer.containers || {};
      dockerContainer.enabled = true;
      let updates = this.state.currentContainer;
      if (updates.image !== this.props.currentContainer.image && this.props.currentContainer.image) {
        delete dockerContainer.containers[this.props.currentContainer.image];
      }
      updates.ports = {};
      this.state.ports.forEach(p => {
        updates.ports[`${p.local}/${p.protocol || "tcp"}`] = parseInt(p.host);
      });
      this.state.volumes.forEach(p => {
        if (!p.name) {
          return;
        }
        updates.volumes[p.name] = { bind: p.bind, mode: p.mode };
      });
      dockerContainer.containers[updates.image] = updates;
      delete dockerContainer.containers[updates.image].name;
      cloudproject.envs[env].services.docker = dockerContainer;

      // updating object

      Controller.get("cloudprojects").update(cloudproject.uuid, cloudproject, () => {
        this.setState(prevState => ({
          dirty: false
        }));
        if (this.props.key !== updates.image) {
          this.props.history.push(`/cloudprojects/${this.props.cloudProjectId}/${env}/container/${updates.image}`);
        }
      });
    };
  };

  render() {
    const { dirty, currentContainer, volumes, ports } = this.state;
    const { cloudProjectId, env, container, classes, async, asyncUpdate, cloudproject } = this.props;
    let incomplete = currentContainer.image === "";
    const conflict =
      cloudproject.envs[env].services.docker.containers[currentContainer.image] &&
      (this.props.currentContainer.image === undefined || this.props.currentContainer.image !== currentContainer.image);

    return (
      <Paper className={classes.root}>
        <div className={classes.flexSpaceBetween} id="docker-detail-container">
          <Header as="h4">{container} container</Header>
          <Button
            variant="outlined"
            color="secondary"
            id="delete-cloudprojectDockerContainer-button"
            onClick={this.deleteContainer(cloudProjectId, env, container)}
            disabled={false}
          >
            {this.state.deleteWarning}
          </Button>
        </div>
        <LoadingPanel async={async && asyncUpdate}>
          <Breadcrumb
            items={[
              { title: "Cloud Projects", path: `/cloudprojects/` },
              {
                title: `${cloudProjectId}`,
                path: `/cloudprojects/${cloudProjectId}`
              },
              {
                title: `${env}_docker_container_${container}`
              }
            ]}
          />
          <TextField
            label="Image"
            error={conflict}
            helperText={conflict ? "A container is already defined with this image" : undefined}
            value={currentContainer.image || ""}
            variant="outlined"
            className={classes.textField}
            onChange={evt => this.onContainerDetailsChange(evt, "image")}
            id="newdockerContainer-image"
          />
          <FormControlLabel
            control={
              <Switch
                classes={{
                  switchBase: classes.colorSwitchBase,
                  checked: classes.colorChecked
                }}
                checked={currentContainer.privileged || false}
                onChange={evt => this.onContainerDetailsChange(evt, "privileged")}
                id="newdockerContainer-privileged"
              />
            }
            label="Privileged"
          />

          <TextField
            label="Command"
            value={currentContainer.command || ""}
            variant="outlined"
            onChange={evt => this.onContainerDetailsChange(evt, "command")}
            className={classes.commandTextfield}
            id="newdockerContainer-command"
            fullWidth
          />
          <Divider className={classes.divider} />
          <Header as="h4">Volumes configuration</Header>

          {volumes.length !== 0 ? (
            volumes.map((volume, i) => (
              <CloudProjectDockerVolume
                classes={classes}
                volume={volume}
                onDelete={() => {
                  this.setState(oldState => {
                    return { dirty: true, volumes: oldState.volumes.slice(0).filter((v, id) => id !== i) };
                  });
                }}
                onChange={p => {
                  this.setState(oldState => {
                    let volumes = oldState.volumes.slice(0);
                    p._new = false;
                    volumes[i] = p;
                    return { volumes, dirty: true };
                  });
                }}
                key={i}
              />
            ))
          ) : (
            <div className={classes.volumeContainer}>No volume is set for the moment</div>
          )}

          <Button
            color="primary"
            id="add-cloudprojectContainerVolume-button"
            onClick={this.addNewVolume}
            disabled={volumes.filter(v => v._new).length > 0}
            className={classes.addButton}
          >
            Add new volume
            <AddIcon />
          </Button>
          <Divider className={classes.divider} />
          <Header as="h4">Ports configuration</Header>
          {ports.length !== 0 ? (
            ports.map((port, i) => (
              <CloudProjectDockerPort
                key={i}
                classes={classes}
                port={port}
                onDelete={() => {
                  this.setState(oldState => {
                    return { dirty: true, ports: oldState.ports.slice(0).filter((v, id) => id !== i) };
                  });
                }}
                onChange={p => {
                  this.setState(oldState => {
                    let ports = oldState.ports.slice(0);
                    p._new = false;
                    ports[i] = p;
                    return { ports, dirty: true };
                  });
                }}
              />
            ))
          ) : (
            <div className={classes.volumeContainer}>No port is set for the moment</div>
          )}

          <Button
            color="primary"
            id="add-cloudprojectContainerPort-button"
            onClick={this.addNewPort}
            disabled={ports.filter(v => v._new).length > 0}
            className={classes.addButton}
          >
            Add new port
            <AddIcon />
          </Button>

          <PanelActions
            onCancel={this.onCancel(cloudproject, env, container)}
            onSave={this.onSave(cloudproject, env, currentContainer)}
            dirty={dirty}
            disabled={incomplete}
            async={async}
          />
        </LoadingPanel>
      </Paper>
    );
  }
}

export default withStyles(styles)(
  connect((state, ownProps) => {
    let env = {};
    let container = "";
    let cloudProjectId = "";
    let currentContainer = {};
    if (ownProps.match) {
      cloudProjectId = ownProps.match.params.id;
      env = ownProps.match.params.env;
      container = ownProps.match.params.containerID;
    }
    let cloudproject = state.cloudprojects.current || {};
    cloudproject.envs = cloudproject.envs || {};
    cloudproject.envs[env] = cloudproject.envs[env] || {};
    cloudproject.envs[env].services = cloudproject.envs[env].services || {};
    cloudproject.envs[env].services.docker = cloudproject.envs[env].services.docker || {};
    cloudproject.envs[env].services.docker.containers = cloudproject.envs[env].services.docker.containers || {};
    currentContainer = cloudproject.envs[env].services.docker.containers[container] || {};
    let key = currentContainer.image || "new";
    currentContainer.volumes = currentContainer.volumes || {};
    currentContainer.ports = currentContainer.ports || {};
    currentContainer.command = currentContainer.command || "";

    return {
      key,
      cloudproject,
      cloudProjectId,
      env,
      container,
      currentContainer,
      async: state.cloudprojects._async.GET_CLOUDPROJECT,
      asyncUpdate: state.cloudprojects._async.UPDATE_CLOUDPROJECT
    };
  })(CloudProjectDockerContainerDetails)
);

import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { Header } from "semantic-ui-react";
import Breadcrumb from "../common/Breadcrumb";
import LoadingPanel from "../common/LoadingPanel";
import { Paper, TextField } from "@material-ui/core";

const styles = theme => ({
  root: {
    minHeight: 380,
    padding: 20,
    marginTop: 20
  },
  addButton: {
    position: "relative",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  }
});

const RunbookDetails = ({ classes, match }) => {
  const async = useSelector(state => state.runbooks._async.GET_RUNBOOK || state.runbooks._async.SYNC_RUNBOOKS);
  const currentRunbook = useSelector(state => state.runbooks.current);
  let title = match.params.id || "";
  return (
    <div>
      <Paper className={classes.root}>
        <Header as="h4">RunbookDetails</Header>
        <LoadingPanel async={async}>
          <Breadcrumb items={[{ title: "Runbooks", path: "/security-automation" }, { title }]} />
          <TextField label="Uuid" value={currentRunbook.uuid} variant="outlined" style={{ margin: 15 }} />
          <TextField label="Actor" value={currentRunbook.actor} variant="outlined" style={{ margin: 15 }} />
          <TextField label="Category" value={currentRunbook.category} variant="outlined" style={{ margin: 15 }} />
          <TextField label="Command" value={currentRunbook.command} variant="outlined" style={{ margin: 15 }} />
          <TextField label="Status" value={currentRunbook.status} variant="outlined" style={{ margin: 15 }} />
          <TextField label="Public Ip" value={currentRunbook.publicIp} variant="outlined" style={{ margin: 15 }} />
        </LoadingPanel>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(RunbookDetails);

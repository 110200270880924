import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Switch, Route } from "react-router-dom";
import SecurityTableDetail from "./SecurityTableDetail";
import SecurityPeriodicTable from "./SecurityPeriodicTable";
import { Paper } from '@material-ui/core';

const styles = theme => ({
  root: {
    padding: 20,
    marginTop: 20
  }
});

const SecurityTable = ({ classes }) => {
  return (
    <Paper className={classes.root}>
      <Route
        exact
        path="/security-table/"
        component={SecurityPeriodicTable}
      ></Route>
      <Switch>
        <Route path="/security-table/:id" component={SecurityTableDetail} />
      </Switch>
    </Paper>
  );
}


export default withStyles(styles)(SecurityTable);

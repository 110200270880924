import React from "react";
import ObjectList from "../common/ObjectList";
import { withStyles } from "@material-ui/core/styles";
import { Tab } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from '@material-ui/icons/Warning';
import { Typography } from "@material-ui/core";
import { Controller } from "../controllers";

const styles = theme => ({
    root: {
        height: 380,
        padding: 20,
        marginTop: 20
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        padding: 0
    },
    libelles: {
        padding: 20
    }
});

const isNorm = (norm, r) => {
    for (let i in r.references) {
        if (r.references[i].Norm === norm) {
            return true;
        }
    }
    return false;
};

const ComplianceReportsList = ({ classes, complianceReportsData }) => {
    let panes = [];
    let panesHeader = ["All", "HiTrust", "PCI/DSS", "SOC2"];
    panesHeader.forEach(panesHeader => {
        panes.push(
            {
                menuItem: panesHeader,
                render: () => (
                    <Tab.Pane>
                        <div id={`${panesHeader}-Reports-Panel`}>
                            <ObjectList
                                title=""
                                headers={[
                                    {
                                        id: "uuid",
                                        numeric: false,
                                        disablePadding: false,
                                        label: "Report Name"
                                    },
                                    {
                                        id: "lastCheck",
                                        numeric: false,
                                        disablePadding: false,
                                        label: "Last Check"
                                    },
                                    {
                                        id: "isCompliant",
                                        numeric: false,
                                        disablePadding: false,
                                        label: "Status",
                                        getCellWidget: (n, col, index) => {
                                            if (n.isCompliant === true) {
                                                return (
                                                    <CheckCircleIcon color="primary" />
                                                )
                                            } else {
                                                return (
                                                    <WarningIcon color="error" />
                                                )
                                            }
                                        }
                                    },
                                    {
                                        id: "Download",
                                        numeric: false,
                                        disablePadding: false,
                                        label: "Download",
                                        getCellWidget: (n, col, index) => {
                                            return (
                                                <IconButton onClick={() => Controller.get("dashboard").downloadReport(n.uuid)}>
                                                    <GetAppIcon />
                                                </IconButton>
                                            )
                                        }
                                    }
                                ]}
                                data={panesHeader === "All" ?
                                    complianceReportsData :
                                    complianceReportsData.filter(
                                        complianceReport => isNorm(panesHeader, complianceReport)
                                    )
                                }
                                routeTo="/compliance-reports/{uuid}"
                            />
                        </div>
                    </Tab.Pane>
                )
            }
        )
    });
    return (
        <div className={classes.root} id="complianceReportsList">
            <Typography>Compliance Reports</Typography>
            <Tab
                id="tab"
                menu={{ color: "blue", secondary: true, pointing: true }}
                panes={panes}
            />
        </div>
    )
}

export default withStyles(styles)(
    (ComplianceReportsList)
);
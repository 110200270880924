import ObjectList from "../common/ObjectList";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import NewProject from "./CloudProjectNew";
import CloudProjectDetails from "./CloudProjectDetails";
import { Switch, Route } from "react-router-dom";
import { Button } from "@material-ui/core";
import CloudProjectDockerContainerDetails from "./CloudProjectDockerContainerDetails";

const styles = theme => ({
  root: {
    height: 380
  },
  addButton: {
    position: "relative",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  }
});

const CloudProjectsList = () => (
  <ObjectList
    title="Cloud Projects"
    headers={[
      {
        id: "billingCategory",
        numeric: false,
        disablePadding: false,
        label: "Category"
      },
      {
        id: "billingSubcategory",
        numeric: false,
        disablePadding: false,
        label: "Subcategory"
      },
      {
        id: "description",
        numeric: false,
        disablePadding: false,
        label: "Description"
      }
    ]}
    storeData="$.cloudprojects.projects"
    routeTo="/cloudprojects/{uuid}"
  />
);

class CloudProjects extends React.Component {
  render() {
    const { classes, location } = this.props;
    return (
      <div>
        <div
          style={
            location.pathname !== "/cloudprojects/new" && !location.pathname.includes("/container/")
              ? {}
              : { display: "none" }
          }
          className={classes.addButton}
        >
          <Button
            onClick={() => {
              this.props.history.push("/cloudprojects/new");
            }}
            variant="outlined"
            color="primary"
            id="newproject-button"
          >
            <AddIcon />
            Register new project
          </Button>
        </div>
        <Route exact path="/cloudprojects" component={CloudProjectsList} />
        <Switch>
          <Route exact path="/cloudprojects/new" component={NewProject} />
          <Route exact path="/cloudprojects/:id" component={CloudProjectDetails} />
          <Route
            exact
            path="/cloudprojects/:id/:env/container/:containerID"
            component={CloudProjectDockerContainerDetails}
          />
        </Switch>
      </div>
    );
  }
}

export default withStyles(styles)(CloudProjects);

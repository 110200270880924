import React from "react";
import ObjectList from "../common/ObjectList";
import { IconButton } from "@material-ui/core";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ErrorIcon from "@material-ui/icons/Error";

export function ActionColumn(type, onDetails) {
  return {
    id: "actions",
    numeric: false,
    disablePadding: false,
    getCellWidget: (n, col, index) => {
      let warnIcon;
      if (n.nonCompliant) {
        warnIcon = <ErrorIcon color="error" />;
      }
      return (
        <IconButton
          onClick={() => {
            onDetails(n, type);
          }}
        >
          {warnIcon}
          <ZoomInIcon />
        </IconButton>
      );
    },
    label: "Actions"
  };
}
export const EC2InstancesList = (data, onDetails) => (
  <ObjectList
    title=""
    noToolBar={true}
    noPaper
    headers={[
      {
        id: "Name",
        numeric: false,
        disablePadding: false,
        label: "Name"
      },
      {
        id: "InstanceId",
        numeric: false,
        disablePadding: false,
        label: "Instance ID"
      },
      {
        id: "InstanceType",
        numeric: false,
        disablePadding: false,
        label: "Type"
      },
      {
        id: "upTime",
        numeric: false,
        disablePadding: false,
        label: "Up Time"
      },
      {
        id: "account",
        numeric: false,
        disablePadding: false,
        label: "Account"
      },
      {
        id: "awsRegion",
        numeric: false,
        disablePadding: false,
        label: "Region"
      },
      ActionColumn("ec2", onDetails)
    ]}
    onItemDoubleClicked={n => {
      onDetails(n, "ec2");
    }}
    data={data}
  />
);

export const S3List = (data, onDetails) => (
  <ObjectList
    title=""
    noPaper
    noToolBar={true}
    headers={[
      {
        id: "account",
        numeric: false,
        disablePadding: false,
        label: "Account"
      },
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Name"
      },
      {
        id: "size",
        numeric: false,
        disablePadding: false,
        label: "Size",
        getCellValue: o => {
          if (!o.size) return "";
          var i = Math.floor(Math.log(o.size) / Math.log(1024)),
            sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
          return (o.size / Math.pow(1024, i)).toFixed(2) * 1 + " " + sizes[i];
        }
      },
      {
        id: "nb",
        numeric: false,
        disablePadding: false,
        label: "Objects",
        getCellValue: o => {
          if (!o.nb) return "";
          return new Intl.NumberFormat("en-US").format(o.nb);
        }
      },
      {
        id: "encryption",
        numeric: false,
        disablePadding: false,
        label: "Encryption"
      },
      {
        id: "arn",
        numeric: false,
        disablePadding: false,
        label: "Arn"
      },
      {
        id: "awsRegion",
        numeric: false,
        disablePadding: false,
        label: "Region"
      },
      ActionColumn("s3", onDetails)
    ]}
    onItemDoubleClicked={n => {
      onDetails(n, "s3");
    }}
    data={data}
  />
);

export const RDSInstancesList = (data, onDetails) => (
  <ObjectList
    title=""
    noToolBar={true}
    noPaper
    headers={[
      {
        id: "DBInstanceIdentifier",
        numeric: false,
        disablePadding: false,
        label: "DBInstanceIdentifier"
      },
      {
        id: "DBInstanceClass",
        numeric: false,
        disablePadding: false,
        label: "Type"
      },
      {
        id: "Engine",
        numeric: false,
        disablePadding: false,
        label: "Engine"
      },
      {
        id: "account",
        numeric: false,
        disablePadding: false,
        label: "Account"
      },
      {
        id: "awsRegion",
        numeric: false,
        disablePadding: false,
        label: "Region"
      },
      ActionColumn("rds", onDetails)
    ]}
    onItemDoubleClicked={n => {
      onDetails(n, "rds");
    }}
    data={data}
  />
);

export const EBSList = (data, onDetails) => (
  <ObjectList
    title=""
    noToolBar={true}
    noPaper
    headers={[
      {
        id: "VolumeId",
        numeric: false,
        disablePadding: false,
        label: "VolumeId"
      },
      {
        id: "Size",
        numeric: false,
        disablePadding: false,
        label: "Size"
      },
      {
        id: "encryption",
        numeric: false,
        disablePadding: false,
        label: "Encryption"
      },
      {
        id: "account",
        numeric: false,
        disablePadding: false,
        label: "Account"
      },
      {
        id: "awsRegion",
        numeric: false,
        disablePadding: false,
        label: "Region"
      },
      ActionColumn("ebs", onDetails)
    ]}
    onItemDoubleClicked={n => {
      onDetails(n, "ebs");
    }}
    data={data}
  />
);

export const EFSList = (data, onDetails) => (
  <ObjectList
    title=""
    noToolBar={true}
    noPaper
    headers={[
      {
        id: "Name",
        numeric: false,
        disablePadding: false,
        label: "Name"
      },
      {
        id: "arn",
        numeric: false,
        disablePadding: false,
        label: "Arn"
      },
      {
        id: "account",
        numeric: false,
        disablePadding: false,
        label: "Account"
      },
      {
        id: "encryption",
        label: "Encryption"
      },
      {
        id: "awsRegion",
        numeric: false,
        disablePadding: false,
        label: "Region"
      },
      ActionColumn("efs", onDetails)
    ]}
    onItemDoubleClicked={n => {
      onDetails(n, "efs");
    }}
    data={data}
  />
);

export const SGsList = (data, onDetails) => (
  <ObjectList
    title=""
    noPaper
    noToolBar={true}
    headers={[
      {
        id: "GroupName",
        numeric: false,
        disablePadding: false,
        label: "GroupName"
      },
      {
        id: "Description",
        numeric: false,
        disablePadding: false,
        label: "Description"
      },
      {
        id: "VpcId",
        numeric: false,
        disablePadding: false,
        label: "VpcId"
      },
      {
        id: "rulesNb",
        numeric: false,
        disablePadding: false,
        label: "Rules"
      },
      {
        id: "validatedRulesNb",
        numeric: false,
        disablePadding: false,
        label: "Validated"
      },
      {
        id: "account",
        numeric: false,
        disablePadding: false,
        label: "Account"
      },
      {
        id: "awsRegion",
        numeric: false,
        disablePadding: false,
        label: "Region"
      },
      ActionColumn("sg", onDetails)
    ]}
    onItemDoubleClicked={n => {
      onDetails(n, "sg");
    }}
    data={data}
  />
);

export const OthersList = (data, onDetails) => (
  <ObjectList
    title=""
    noToolBar={true}
    noPaper
    headers={[
      {
        id: "Name",
        numeric: false,
        disablePadding: false,
        label: "Name"
      },
      {
        id: "arn",
        numeric: false,
        disablePadding: false,
        label: "Arn"
      },
      {
        id: "account",
        numeric: false,
        disablePadding: false,
        label: "Account"
      },
      {
        id: "awsRegion",
        numeric: true,
        disablePadding: false,
        label: "AwsRegion"
      },
      ActionColumn("others", onDetails)
    ]}
    onItemDoubleClicked={n => {
      onDetails(n, "others");
    }}
    data={data}
  />
);

export const Route53List = (data, onDetails) => (
  <ObjectList
    title=""
    noToolBar={true}
    noPaper
    headers={[
      {
        id: "Name",
        numeric: false,
        disablePadding: false,
        label: "Domain"
      },
      {
        id: "ResourceRecordSetCount",
        numeric: false,
        disablePadding: false,
        label: "Entries"
      },
      {
        id: "account",
        numeric: false,
        disablePadding: false,
        label: "Account"
      },
      ActionColumn("r53", onDetails)
    ]}
    onItemDoubleClicked={n => {
      onDetails(n, "r53");
    }}
    data={data}
  />
);

export const CloudFrontList = (data, onDetails) => (
  <ObjectList
    title=""
    noToolBar={true}
    noPaper
    headers={[
      {
        id: "DomainName",
        numeric: false,
        disablePadding: false,
        label: "Domain"
      },
      {
        id: "Aliases",
        numeric: false,
        disablePadding: false,
        getCellValue: (n, col, index) => {
          if (n.Aliases.Quantity === 0) {
            return null;
          } else {
            return n.Aliases.Items[0];
          }
        },
        label: "Aliases"
      },
      {
        id: "Status",
        numeric: false,
        disablePadding: false,
        label: "Status"
      },
      {
        id: "account",
        numeric: false,
        disablePadding: false,
        label: "Account"
      },
      ActionColumn("cf", onDetails)
    ]}
    onItemDoubleClicked={n => {
      onDetails(n, "cf");
    }}
    data={data}
  />
);
